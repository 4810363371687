export const accountRoleOptions = [
  {
    value: "SUPER",
    label: "SUPER",
  },
  {
    value: "EDITOR",
    label: "EDITOR",
  },
  {
    value: "VIEWER",
    label: "VIEWER",
  },
];

export const items = [
  {
    type: "coin",
    name: "Xu",
  },
  {
    type: "point",
    name: "Điểm",
  },
  {
    type: "ticket",
    name: "Vé",
  },
];

export const ticketsFuncs = [
  {
    value: "read",
    label: "Đọc truyện",
  },
  {
    value: "listen",
    label: "Nghe audio",
  },
  {
    value: "download",
    label: "Tải xuống",
  },
  {
    value: "remove_ads",
    label: "Bỏ quảng cáo",
  },
];

export const bookTypes = [
  {
    value: "comic",
    label: "Truyện tranh",
  },
  {
    value: "novel",
    label: "Tiểu thuyết",
  },
  {
    value: "audio",
    label: "Truyện Audio",
  },
  {
    value: "shortNovel",
    label: "Truyện ngắn",
  },
];

export const audioVoices = [
  {
    value: "linhsanace",
    label: "Linh San Ace",
    demo: "/audio/linhsanace_demo.mp3",
  },
  {
    value: "banmaiace",
    label: "Ban Mai Ace",
    demo: "/audio/banmaiace_demo.mp3",
  },
  {
    value: "minhquangace",
    label: "Minh Quang Ace",
    demo: "/audio/minhquangace_demo.mp3",
  },
];

// "manage-comic", "manage-short-novel", "manage-novel"
export const partnerPermissions = [
  {
    value: "manage-comic",
    label: "Quản lý truyện tranh",
  },
  {
    value: "manage-short-novel",
    label: "Quản lý truyện ngắn",
  },
  {
    value: "manage-novel",
    label: "Quản lý tiểu thuyết",
  },
];

export const editorJobStatus = [
  {
    value: "PENDING",
    label: "Chờ",
    color: "orange",
  },
  {
    value: "IN_PROCESS",
    label: "Đang làm",
    color: "blue",
  },
  {
    value: "DONE",
    label: "Hoàn thành",
    color: "green",
  },
  {
    value: "REJECTED",
    label: "Từ chối",
    color: "red",
  },
  {
    value: "APPROVED",
    label: "Đã duyệt",
    color: "green",
  },
  {
    value: "CANCEL",
    label: "Hủy",
    color: "red",
  },
];

// "edit-comic", "post-short-novel"
export const partnerWagesTypes = [
  {
    value: "edit-comic",
    label: "Edit truyện tranh",
  },
  {
    value: "post-short-novel",
    label: "Đăng truyện ngắn",
  },
];

// "MANAGE_COMIC", "MANAGE_SHORT_NOVEL", "MANAGE_NOVEL"
export const contributorPermissions = [
  {
    value: "MANAGE_COMIC",
    label: "Đăng truyện tranh",
  },
  {
    value: "MANAGE_SHORT_NOVEL",
    label: "Đăng truyện ngắn",
  },
  {
    value: "MANAGE_NOVEL",
    label: "Đăng tiểu thuyết",
  },
];

export const contributorStatus = [
  {
    value: "ACTIVE",
    label: "Hoạt động",
    color: "green",
  },
  {
    value: "INACTIVE",
    label: "Không hoạt động",
    color: "red",
  },
];

// IAP PRODUCT
export const iapProductPurchaseTypes = [
  {
    label: "Consume",
    value: "CONSUME",
  },
  {
    label: "Subscription",
    value: "SUBSCRIPTION",
  },
];

export const iapProductTypes = [
  {
    label: "Gói xu",
    value: "COIN_PACK",
  },
];

export const iapProductDefinePrices = [
  {
    label: "0.99 USD",
    value: 0.99,
  },
  {
    label: "1.99 USD",
    value: 1.99,
  },
  {
    label: "2.99 USD",
    value: 2.99,
  },
  {
    label: "3.99 USD",
    value: 3.99,
  },
  {
    label: "4.99 USD",
    value: 4.99,
  },
  {
    label: "5.99 USD",
    value: 5.99,
  },
  {
    label: "6.99 USD",
    value: 6.99,
  },
  {
    label: "7.99 USD",
    value: 7.99,
  },
  {
    label: "8.99 USD",
    value: 8.99,
  },
  {
    label: "9.99 USD",
    value: 9.99,
  },
  {
    label: "19.99 USD",
    value: 19.99,
  },
];

export const suggestSexOptions = [
  {
    label: "Nam",
    value: "male",
  },
  {
    label: "Nữ",
    value: "female",
  },
  {
    label: "Tất cả",
    value: "both",
  },
];

export const invoiceStatusOptions = [
  {
    value: "PENDING",
    label: "Đang xử lý",
    color: "yellow",
  },
  {
    value: "PAID",
    label: "Đã thanh toán",
    color: "green",
  },
  {
    value: "FAILED",
    label: "Lỗi",
    color: "red",
  },
];

export const chapterReportStatusOptions = [
  {
    value: "PENDING",
    label: "Đang xử lý",
    color: "yellow",
  },
  {
    value: "RESOLVED",
    label: "Đã xử lý",
    color: "green",
  },
];

// "CONTENT", "DUPLICATE", "OTHER"
export const chapterReportIssueOptions = [
  {
    value: "CONTENT",
    label: "Nội dung sai/thiếu",
  },
  {
    value: "DUPLICATE",
    label: "Trùng lặp",
  },
  {
    value: "OTHER",
    label: "Khác",
    color: "#1890ff",
  },
];

export const wheelMoneyReceiptStatusOptions = [
  {
    value: "pending",
    label: "Đang xử lý",
    color: "yellow",
  },
  {
    value: "done",
    label: "Đã thanh toán",
    color: "green",
  },
  {
    value: "error",
    label: "Lỗi",
    color: "red",
  },
];
