import { DateField, TagField, useTable } from "@refinedev/antd";
import { Space, Table, Image, Typography, Card, Avatar, Checkbox } from "antd";
import { editorJobStatus } from "../../constants";
import { IBook } from "interfaces";
import React from "react";
import { EditJobActionButton } from "./EditJobsActionButton";

const { Text, Title } = Typography;

export default function EditorJobsTable() {
  const { tableProps } = useTable({
    dataProviderName: "defaultV2",
    resource: "editor-jobs/groups",
  });

  return (
    <Table
      {...tableProps}
      rowKey="_id"
      expandable={{
        expandedRowRender: renderExpanded,
      }}
    >
      <Table.Column
        title="Truyện"
        dataIndex="book"
        render={(value: IBook) => {
          return (
            <Space>
              <Image
                src={value.cover[0].url}
                style={{
                  width: "48px",
                  aspectRatio: "1/1.5",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <p>{value.name}</p>
            </Space>
          );
        }}
      />
      <Table.Column title="Hoàn thành" dataIndex="doneCount" />
      <Table.Column title="Đang làm" dataIndex="processCount" />
      <Table.Column title="Đang chờ" dataIndex="pendingCount" />
      <Table.Column title="Tổng" dataIndex="totalCount" />
    </Table>
  );
}

const renderExpanded = (data: any) => {
  return <EditorJobGroupTableExpaned bookId={data._id} />;
};

const EditorJobGroupTableExpaned: React.FC<{ bookId: string }> = ({
  bookId,
}) => {
  const { tableProps, tableQueryResult } = useTable({
    dataProviderName: "defaultV2",
    resource: "editor-jobs",
    permanentFilter: [
      {
        field: "bookId",
        operator: "eq",
        value: bookId,
      },
      {
        field: "status",
        operator: "in",
        value: ["PENDING", "IN_PROCESS"],
      },
    ],
  });

  return (
    <>
      <Title level={5}>Danh sách chương</Title>
      <Card>
        <Table {...tableProps} rowKey="_id">
          <Table.Column title="Chapter" dataIndex="chapterNumber" />
          <Table.Column
            title="Trạng thái"
            dataIndex="status"
            render={(value) => {
              const status = editorJobStatus.find(
                (status: any) => status.value === value
              );
              return <TagField value={status?.label} color={status?.color} />;
            }}
          />
          <Table.Column
            title="Editor"
            dataIndex="editor"
            render={(value) => {
              if (value == null) return <div>____</div>;

              return (
                <Space>
                  <Avatar src={value.avatarUrl} size="small" />
                  <Text>{value.name}</Text>
                </Space>
              );
            }}
          />
          <Table.Column
            title="Ngày tạo"
            dataIndex="createdAt"
            render={(value) => {
              return <DateField value={value} format="HH:mm DD/MM/YYYY" />;
            }}
          />
          <Table.Column
            title="Ngày nhận"
            dataIndex="startedAt"
            render={(value) => {
              if (!value) return <div>___</div>;

              return <DateField value={value} format="HH:mm DD/MM/YYYY" />;
            }}
          />
          <Table.Column title="Thời gian quy định" dataIndex="expiredDays" />
          <Table.Column
            title="Quan trọng"
            dataIndex="isImportant"
            render={(value) => {
              return <Checkbox checked={value} />;
            }}
          />
          <Table.Column
            title="Hành động"
            dataIndex="actions"
            render={(value, record: any) => {
              return (
                <EditJobActionButton
                  id={record._id}
                  onRefresh={() => {
                    tableQueryResult.refetch();
                  }}
                />
              );
            }}
          />
        </Table>
      </Card>
    </>
  );
};
