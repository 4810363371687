import { Create, getValueFromEvent, useForm } from "@refinedev/antd";
import { Form, Input, Upload, Typography } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";

const { Text } = Typography;

export const AuthorCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    dataProviderName: "defaultV2",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Avatar"
          name="avatar"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
        >
          <Upload
            name="images"
            listType="picture-card"
            action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
            maxCount={1}
            multiple={false}
            accept=".png,.jpeg,.jpg"
          >
            <Text>Drag & drop image to upload</Text>{" "}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
              message: "Tên không được bỏ trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Thông tin tóm tắt" name="description">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Create>
  );
};
