import React from "react";

import {
  DeleteButton,
  EditButton,
  ImageField,
  SaveButton,
  TextField,
  useEditableTable,
} from "@refinedev/antd";

import { Button, Form, Input, InputNumber, Space, Table, Typography } from "antd";
import { IBanner } from "../../interfaces";

const { Text } = Typography;

const BannerTable: React.FC<{ type: string }> = ({ type }) => {
  const {
    tableProps,
    formProps,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    isEditing,
    setId,
  } = useEditableTable<IBanner>({
    permanentFilter: [
      {
        field: "type",
        operator: "eq",
        value: type,
      },
    ],
  });

  return (
    <Form {...formProps}>
      <Table
        {...tableProps}
        rowKey="_id"
        onRow={(record) => ({
          onClick: (event: any) => {
            if (event.target.nodeName === "TD") {
              setId && setId(record._id);
            }
          },
        })}
      >
        <Table.Column<IBanner>
          dataIndex="image"
          title="Ảnh"
          key="image"
          render={(value, record) => {
            return (
              <ImageField value={value[0].url} style={{ maxWidth: "180px" }} />
            );
          }}
        />
        <Table.Column<IBanner>
          dataIndex="title"
          title="Tiêu đề"
          key="title"
          render={(value, record) => {
            if (isEditing(record._id)) {
              return (
                <Form.Item name="title" style={{ margin: 0 }}>
                  <Input />
                </Form.Item>
              );
            }

            return <TextField value={value} />;
          }}
        />
        <Table.Column<IBanner>
          dataIndex="order"
          title="Thứ tự"
          key="order"
          render={(value, record) => {
            if (isEditing(record._id)) {
              return (
                <Form.Item name="order" style={{ margin: 0 }}>
                  <InputNumber min={0} />
                </Form.Item>
              );
            }

            return <TextField value={value} />;
          }}
        />
        <Table.Column<IBanner>
          dataIndex="linkTo"
          title="Link"
          key="linkTo"
          render={(value, record) => {
            if (isEditing(record._id)) {
              return (
                <Form.Item name="linkTo" style={{ margin: 0 }}>
                  <Input />
                </Form.Item>
              );
            }

            return (
              <a href={value} target="_blank" rel="noreferrer">
                {value}
              </a>
            );
          }}
        />
        <Table.Column<IBanner>
          dataIndex="actions"
          title="Hành động"
          render={(value, record) => {
            if (isEditing(record._id)) {
              return (
                <Space>
                  <SaveButton {...saveButtonProps} size="small" />
                  <Button {...cancelButtonProps} size="small">
                    Cancel
                  </Button>
                </Space>
              );
            }

            return (
              <Space>
                <EditButton
                  {...editButtonProps(record._id)}
                  size="small"
                  hideText
                />
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Space>
            );
          }}
        />
      </Table>
    </Form>
  );
};

export default BannerTable;
