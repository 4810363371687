import React from "react";
import { Col, Empty, Row, Spin } from "antd";
import { useOne } from "@refinedev/core";
import { ISticker } from "../../interfaces";

type StickerTabContentProps = {
  stickerId: string;
  onSendSticker: Function;
};

export const StickerTabContent: React.FC<StickerTabContentProps> = ({
  stickerId,
  onSendSticker,
}) => {
  const { data, isLoading } = useOne<ISticker>({
    resource: "stickers",
    id: stickerId,
  });

  const queryResult = data?.data;

  const handleClick = (url: string) => {
    onSendSticker(url);
  };

  const renderGird = () => {
    return (
      <Row gutter={[8, 8]}>
        {queryResult?.images.map((item, index) => (
          <Col span={6} key={index}>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => handleClick(item.url)}
            >
              <img
                src={item.url}
                width="80px"
                style={{
                  borderRadius: "10px",
                }}
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const renderLoading = () => {
    return <Spin />;
  };

  const renderEmpty = () => {
    return <Empty />;
  };

  const renderContent = () => {
    if (isLoading) return renderLoading();

    if (!queryResult) return renderEmpty();

    return renderGird();
  };

  return (
    <div
      style={{
        maxWidth: "400px",
        height: "320px",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {renderContent()}
    </div>
  );
};
