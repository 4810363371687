import React from "react";
import {
  DateField,
  getDefaultSortOrder,
  TagField,
  useSelect,
  useTable,
} from "@refinedev/antd";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";

import EditorJobReviewActions from "./EditorJobReviewActions";
import ModalEditJobReview from "./ModalEditJobReview";
import { FilterFilled } from "@ant-design/icons";
import { IBook, IEditorJob } from "../../interfaces";
import { CrudFilters, useCreate } from "@refinedev/core";
import { BiUpload } from "react-icons/bi";
import { TbReplace } from "react-icons/tb";
import { ModalReplaceChapterContent } from "../modal/ModalReplaceChapterContent";

const { Text, Title } = Typography;

type EditorJobsReviewTableProps = {
  status: "DONE" | "APPROVED" | "REJECTED" | "PUBLISHED";
};

export default function EditorJobsReviewTable({
  status,
}: EditorJobsReviewTableProps) {
  const [reviewId, setReviewId] = React.useState<string | null>(null);
  const [replaceId, setReplaceId] = React.useState<string | null>(null);
  const [bookSelectProps, setBookSelectProps] = React.useState<IBook[]>([]);

  const { tableProps, tableQueryResult, searchFormProps, sorters } = useTable({
    dataProviderName: "defaultV2",
    resource: "editor-jobs",
    sorters: {
      initial: [
        {
          field: "chapter",
          order: "asc",
        },
      ],
    },
    permanentFilter: [
      {
        field: "haveImage",
        operator: "eq",
        value: true,
      },
      {
        field: "status",
        operator: "eq",
        value: status,
      },
    ],
    onSearch: (value: any) => {
      let filter: CrudFilters = [];
      const { bookId, editorId } = value;

      filter = [
        {
          field: "bookId",
          operator: "eq",
          value: bookId,
        },
        {
          field: "editorId",
          operator: "eq",
          value: editorId,
        },
      ];

      return filter;
    },
  });

  const { selectProps: groupBookSelectProps } = useSelect({
    dataProviderName: "defaultV2",
    resource: "editor-jobs/groups",
    queryOptions: {
      onSuccess: (data) => {
        const { data: results } = data;
        const books = results.map((item) => item.book);
        setBookSelectProps(books);
      },
    },
    onSearch: (value) => {
      return [
        {
          field: "q",
          operator: "eq",
          value,
        },
      ];
    },
  });

  const { selectProps: partnerSelectProps } = useSelect({
    dataProviderName: "defaultV2",
    resource: "partners",
    optionLabel: "name",
    optionValue: "_id",
  });

  const { mutate: mutatePublish, isLoading: isLoadingPublish } = useCreate({});

  const onPublish = (id: string) => {
    mutatePublish(
      {
        resource: `editor-jobs/${id}/publish`,
        dataProviderName: "defaultV2",
        values: {},
        successNotification: (data) => {
          return {
            message: "Thành công",
            description: `Đã xuất bản thành công`,
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          tableQueryResult.refetch();
        },
      }
    );
  };

  const onClosedModal = () => {
    setReviewId(null);
    tableQueryResult.refetch();
  };

  return (
    <>
      <Card
        style={{
          marginBottom: 16,
        }}
        title="Bộ lọc"
      >
        <Form layout="vertical" {...searchFormProps}>
          <Row wrap gutter={[16, 16]} align="bottom">
            <Col>
              <Form.Item label="Truyện" name="bookId">
                <Select
                  style={{
                    width: "280px",
                  }}
                  {...groupBookSelectProps}
                  options={bookSelectProps.map((item) => ({
                    label: item.name,
                    value: item._id,
                  }))}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Editor" name="editorId">
                <Select
                  style={{
                    width: "280px",
                  }}
                  {...partnerSelectProps}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<FilterFilled />}
                >
                  Lọc
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          title="Truyện"
          dataIndex="book"
          render={(value) => {
            if (value == null) return <div>____</div>;

            return (
              <Space>
                <Image
                  src={value.cover[0].url}
                  width={40}
                  style={{
                    aspectRatio: "1/1.5",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <Text>{value.name}</Text>
              </Space>
            );
          }}
        />
        <Table.Column
          title="Chapter"
          dataIndex="chapterNumber"
          sorter
          defaultSortOrder={getDefaultSortOrder("chapter", sorters)}
        />
        <Table.Column
          title="Editor"
          dataIndex="editor"
          render={(value) => {
            if (value == null) return <div>____</div>;

            return (
              <Space>
                <Avatar src={value.avatarUrl} size="small" />
                <Text>{value.name}</Text>
              </Space>
            );
          }}
        />
        {/*<Table.Column*/}
        {/*  title="Trạng thái"*/}
        {/*  dataIndex="status"*/}
        {/*  render={(value) => {*/}
        {/*    const status = editorJobStatus.find(*/}
        {/*      (status: any) => status.value === value*/}
        {/*    );*/}
        {/*    return <TagField value={status?.label} color={status?.color} />;*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Table.Column*/}
        {/*  title="Ngày tạo"*/}
        {/*  dataIndex="createdAt"*/}
        {/*  render={(value) => {*/}
        {/*    return <DateField value={value} format="HH:mm DD/MM/YYYY" />;*/}
        {/*  }}*/}
        {/*/>*/}
        <Table.Column
          title="Ngày nhận"
          dataIndex="startAt"
          render={(value) => {
            return <DateField value={value} format="HH:mm DD/MM/YYYY" />;
          }}
        />
        <Table.Column
          title="Ngày hoàn thành"
          dataIndex="completeAt"
          render={(value) => {
            return <DateField value={value} format="HH:mm DD/MM/YYYY" />;
          }}
        />
        <Table.Column<IEditorJob>
          title="Hành động"
          dataIndex="actions"
          render={(value, record: any) => {
            if (record.status === "APPROVED" && record.isNewApprove) {
              return (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => onPublish(record._id)}
                    loading={isLoadingPublish}
                  >
                    <Space align="baseline">
                      <BiUpload
                        style={{
                          paddingBottom: 0,
                          marginBottom: 0,
                        }}
                      />
                      Phát hành
                    </Space>
                  </Button>
                  <Button onClick={() => setReplaceId(record._id)}>
                    <Space align="baseline">
                      <TbReplace
                        style={{
                          paddingBottom: 0,
                          marginBottom: 0,
                        }}
                      />
                      Thay thế
                    </Space>
                  </Button>
                </Space>
              );
            }

            if (record.status !== "DONE") {
              return <div />;
            }

            return (
              <EditorJobReviewActions
                id={record._id}
                onReview={() => setReviewId(record._id)}
              />
            );
          }}
        />
      </Table>

      <ModalEditJobReview reviewId={reviewId} onClosed={onClosedModal} />
      <ModalReplaceChapterContent
        editorJobId={replaceId}
        onClose={() => {
          setReplaceId(null);
          tableQueryResult.refetch();
        }}
      />
    </>
  );
}
