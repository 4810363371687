import React, { FC } from "react";
import { IResourceComponentsProps, useOne, useShow } from "@refinedev/core";
import { DateField, Show, TagField } from "@refinedev/antd";
import { Avatar, Button, Descriptions, Space, Typography, Image } from "antd";
import { PaymentInfoDesc } from "../../components/contributor-invoice/PaymentInfoDesc";
import { IContributorInvoice } from "../../interfaces";
import { formatVND } from "../../utils";
import { invoiceStatusOptions } from "../../constants";

const { Text, Title } = Typography;

export const ContributorInvoiceShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IContributorInvoice>({
    resource: "contributor-invoices",
    dataProviderName: "defaultV2",
  });

  const [previewImage, setPreviewImage] = React.useState<boolean>(false);
  const record = queryResult?.data?.data;

  const getStatus = (status: string) => {
    return invoiceStatusOptions.find((item) => item.value === status);
  };

  return (
    <Show title="Thông tin chi tiết" isLoading={queryResult.isLoading}>
      <Descriptions bordered>
        <Descriptions.Item label="Nhóm dịch" span={16}>
          <Space>
            <Avatar size="default" src={record?.contributor?.avatar ?? ""} />
            <Text>{record?.contributor?.name}</Text>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Tổng xu">
          {record?.amount ?? 0} xu
        </Descriptions.Item>
        <Descriptions.Item label="Quy đổi" span={2}>
          {formatVND((record?.amount ?? 0) * 50)} đ
        </Descriptions.Item>

        <Descriptions.Item label="Xu mở khoá">
          {record?.detail?.soldChapter ?? 0} xu
        </Descriptions.Item>
        <Descriptions.Item label="Xu donate" span={2}>
          {record?.detail?.gift ?? 0} xu
        </Descriptions.Item>

        <Descriptions.Item label="Số dư trước">
          {record?.startBalance ?? 0} xu
        </Descriptions.Item>
        <Descriptions.Item label="Số dư sau" span={2}>
          {record?.endBalance ?? 0} xu
        </Descriptions.Item>

        <Descriptions.Item label="Ngày bắt đầu">
          <DateField value={record?.startDate} format="DD/MM/YYYY" />
        </Descriptions.Item>
        <Descriptions.Item label="Ngày kết thúc" span={2}>
          <DateField value={record?.endDate} format="DD/MM/YYYY" />
        </Descriptions.Item>

        <Descriptions.Item label="Ngày tạo">
          <DateField value={record?.createdAt} format="HH:mm - DD/MM/YYYY" />
        </Descriptions.Item>
        <Descriptions.Item label="Ngày thanh toán" span={2}>
          <DateField value={record?.transferAt} format="HH:mm - DD/MM/YYYY" />
        </Descriptions.Item>
        <Descriptions.Item label="Trạng thái" span={3}>
          <TagField
            value={getStatus(record?.status ?? "PENDING")?.label}
            color={getStatus(record?.status ?? "PENDING")?.color}
          />
          {record?.status === "PAID" && (
            <>
              <Button type="link" onClick={() => setPreviewImage(true)}>
                Xem hoá đơn chuyển khoản
              </Button>
              <Image
                width={200}
                style={{ display: "none" }}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                preview={{
                  visible: previewImage,
                  scaleStep: 0.5,
                  src: record?.transferReceipt?.url ?? "",
                  onVisibleChange: (value) => {
                    setPreviewImage(false);
                  },
                }}
              />
            </>
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Thông tin thanh toán" span={3}>
          <PaymentInfoDesc
            paymentInfo={
              record?.paymentInfo ?? {
                accountName: "Nguyễn Văn A",
                accountNumber: "123456789",
                bankName: "Ngân hàng ABC",
              }
            }
          />
        </Descriptions.Item>
      </Descriptions>
    </Show>
  );
};
