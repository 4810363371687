import { IResourceComponentsProps } from "@refinedev/core";
import React, { FC } from "react";
import { Edit, useForm } from "@refinedev/antd";
import { TreeSelect, Typography } from "antd";
import { IBook } from "../../interfaces";
import { BookEditForm } from "../../components/books/BookEditForm";

const { Text } = Typography;
const { SHOW_PARENT } = TreeSelect;

export const BookEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IBook>({});

  return (
    <Edit title="Chỉnh sửa truyện" saveButtonProps={saveButtonProps}>
      <BookEditForm
        type={"novel"}
        formProps={formProps}
        queryResult={queryResult}
      />
    </Edit>
  );
};
