import React from "react";
import { Avatar, Button, Divider, Space, Tooltip } from "antd";
import moment from "moment";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useOne } from "@refinedev/core";
import { IUser } from "../../interfaces";
import { ButtonBanChat, ButtonDeleteMessage } from "./chat-mess-utils";
import { AttachmentItem } from "./attachment-picker";
import { ChatMessageAttachment } from "./ChatMessageAttachment";

export const ChatMessage: React.FC<{
  _id: string;
  userId: string;
  message: string;
  createdAt: Date;
  localUserId: string;
  type: "sticker" | "text";
  attachment: any;
  deleteOne: Function;
  deleteAll: Function;
  onBanSuccess: Function;
}> = ({
  _id,
  userId,
  message,
  createdAt,
  localUserId,
  type,
  attachment,
  deleteOne,
  deleteAll,
  onBanSuccess,
}) => {
  const { data } = useOne<IUser>({
    resource: "user",
    id: userId,
    errorNotification: false,
    queryOptions: {
      retry: false,
    },
  });

  const user = data?.data;
  const isLocalUser = user?._id === localUserId;

  const renderMessage = () => {
    if (type === "sticker") {
      return <img src={message} width="120px" />;
    }

    if (attachment) {
      return (
        <div>
          <span>{message}</span>
          <div>
            <Divider />
            <ChatMessageAttachment
              attachment={attachment}
              textColor={isLocalUser ? "white" : "black"}
            />
          </div>
        </div>
      );
    }

    return <span>{message}</span>;
  };

  return (
    <Space
      align="start"
      style={{
        flexDirection: isLocalUser ? "row-reverse" : "row",
        justifyContent: isLocalUser ? "end" : "start",
        width: "100%",
      }}
    >
      <Avatar src={user?.avatarUrl ?? ""} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Space align="baseline" size={16}>
          <b>{user ? user.username : userId}</b>
          <span
            style={{
              color: "gray",
              fontSize: "12px",
            }}
          >
            {moment(createdAt ?? new Date()).format("HH:mm DD/MM/YYYY")}
          </span>
        </Space>
        <div
          style={{
            backgroundColor: isLocalUser ? "#1890ff" : "#f0f0f0",
            padding: "8px 16px",
            borderRadius: "16px",
            maxWidth: "300px",
            marginTop: "8px",
            color: isLocalUser ? "white" : "black",
          }}
        >
          <span>{renderMessage()}</span>
        </div>
        <Space style={{ marginTop: "16px" }}>
          <ButtonBanChat
            userId={userId}
            onSuccess={() => onBanSuccess(userId)}
          />
          <ButtonDeleteMessage
            deleteAll={() => deleteAll(userId)}
            deleteOne={() => deleteOne(_id)}
          />
        </Space>
      </div>
    </Space>
  );
};
