import React from "react";
import {
  IResourceComponentsProps,
  useCreate,
  useUpdate,
} from "@refinedev/core";
import {
  DeleteButton,
  EditButton,
  FilterDropdown,
  List,
  TagField,
  TextField,
  useSelect,
  useTable,
} from "@refinedev/antd";
import {
  Button,
  Form,
  Image,
  Input,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  MinusCircleFilled,
  MinusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { bookTypes, suggestSexOptions } from "../../constants";
import { IBook, ICategory, ICollection } from "../../interfaces";
import { getColorStatus } from "../../utils";
import { BookTable } from "../../components/books/BookTable";

export const CollectionList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ICollection>({
    dataProviderName: "defaultV2",
  });

  const { mutate: mutateUpdateOrder } = useUpdate();

  const changeOrder = (id: string, current: number, amount: number) => {
    // clamp 0 order
    const newOrder = Math.max(0, current + amount);

    if (newOrder === current) return;

    mutateUpdateOrder({
      dataProviderName: "defaultV2",
      resource: "collections",
      id: id,
      values: {
        order: newOrder,
      },
    });
  };

  return (
    <List title="Bộ sưu tập">
      <Table
        {...tableProps}
        rowKey="_id"
        expandable={{
          expandedRowRender: renderExpanded,
        }}
      >
        <Table.Column title="Tên" dataIndex="name" />
        <Table.Column
          title="Loại truyện"
          dataIndex="bookType"
          render={(value) => {
            const option = bookTypes.find((x) => x.value === value);
            return <TagField value={option?.label} />;
          }}
        />
        <Table.Column
          title="Danh sách"
          dataIndex="suggestSex"
          render={(value) => {
            const option = suggestSexOptions.find((x) => x.value === value);
            return <TagField value={option?.label} />;
          }}
        />
        <Table.Column title="Số truyện" dataIndex="totalBooks" />
        <Table.Column
          title="Thứ tự hiển thị"
          dataIndex="order"
          render={(value, record: any, index) => {
            return (
              <Space size="large">
                <TextField value={value} />
                <Space>
                  <Button
                    icon={<ArrowUpOutlined />}
                    size="small"
                    onClick={() => changeOrder(record._id, value, -1)}
                  />
                  <Button
                    icon={<ArrowDownOutlined />}
                    size="small"
                    onClick={() => changeOrder(record._id, value, 1)}
                  />
                </Space>
              </Space>
            );
          }}
        />
        <Table.Column
          title="Hành động"
          dataIndex="acitons"
          render={(value, record: any, index) => {
            return (
              <Space>
                <EditButton recordItemId={record._id} size="small" hideText />
                <DeleteButton recordItemId={record._id} size="small" hideText />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};

const renderExpanded = (record: ICollection) => {
  return <CollectionTableExpanded bookType={record.bookType} id={record._id} />;
};

const CollectionTableExpanded: React.FC<{
  id: string;
  bookType: "novel" | "comic" | "audio" | "shortNovel";
}> = ({ bookType, id }) => {
  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    filters: [
      {
        field: "type",
        operator: "eq",
        value: bookType,
      },
    ],
    onSearch: (value) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: value,
        },
      ];
    },
  });

  const [form] = Form.useForm();

  const { mutate: mutateInsertBooks } = useCreate({});
  const { tableProps, sorters, tableQueryResult } = useTable<IBook>({
    dataProviderName: "defaultV2",
    resource: `collections/${id}/books`,
    pagination: {
      pageSize: 5,
    },
  });

  const { mutate: mutateRemoveBook } = useCreate({});

  const removeBookFromCollection = (bookId: string) => {
    mutateRemoveBook(
      {
        dataProviderName: "defaultV2",
        resource: `collections/remove-book`,
        values: {
          id,
          bookId,
        },
        successNotification: (data) => {
          return {
            message: "Xóa truyện khỏi bộ sưu tập thành công",
            description: "Xóa thành công",
            type: "success",
          };
        },
      },
      {
        onSuccess: async () => {
          await tableQueryResult.refetch();
        },
      }
    );
  };

  const onFinish = (values: any) => {
    const { bookIds } = values;

    if (!bookIds || bookIds.length === 0) return;

    mutateInsertBooks(
      {
        dataProviderName: "defaultV2",
        resource: `collections/${id}/books`,
        values: values,
        successNotification: (data) => {
          return {
            message: "Thêm truyện vào bộ sưu tập thành công",
            description: `Thêm thành công`,
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          form.resetFields();
        },
      }
    );
  };

  return (
    <Space
      style={{
        display: "flex",
      }}
      direction="vertical"
    >
      <Form onFinish={onFinish} form={form}>
        <Space
          style={{
            display: "flex",
          }}
        >
          <Form.Item
            name="bookIds"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn truyện",
              },
            ]}
          >
            <Select
              placeholder="Chọn truyện"
              style={{
                minWidth: "300px",
              }}
              {...bookSelectProps}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              icon={<PlusCircleOutlined />}
              type="primary"
            >
              Thêm vào danh sách
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <BookTable
        tableProps={tableProps}
        sorter={sorters}
        hiddenCols={["feature"]}
        renderActions={(book) => {
          return (
            <Space>
              <Tooltip title="Xoá khỏi list">
                <Button
                  icon={<MinusOutlined />}
                  size="small"
                  danger
                  onClick={() => removeBookFromCollection(book._id)}
                />
              </Tooltip>
            </Space>
          );
        }}
      />

      {/*<Table {...tableProps} rowKey="_id">*/}
      {/*  <Table.Column*/}
      {/*    title="Cover"*/}
      {/*    dataIndex="cover"*/}
      {/*    render={(value) => {*/}
      {/*      return (*/}
      {/*        <Image*/}
      {/*          src={value[0].url ?? ""}*/}
      {/*          width={60}*/}
      {/*          style={{*/}
      {/*            objectFit: "cover",*/}
      {/*            aspectRatio: "3/4",*/}
      {/*            borderRadius: "4px",*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      );*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <Table.Column title="Tên" dataIndex="name" />*/}
      {/*  <Table.Column*/}
      {/*    title="Danh mục"*/}
      {/*    dataIndex="categories"*/}
      {/*    render={(value: [ICategory]) => {*/}
      {/*      return value.map((x, index) => (*/}
      {/*        <TagField value={x.name} key={index} />*/}
      {/*      ));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <Table.Column*/}
      {/*    dataIndex="status"*/}
      {/*    title="Trạng thái"*/}
      {/*    render={(value) => {*/}
      {/*      return <TagField value={value} color={getColorStatus(value)} />;*/}
      {/*    }}*/}
      {/*    filterDropdown={(props) => (*/}
      {/*      <FilterDropdown*/}
      {/*        {...props}*/}
      {/*        mapValue={(selectedKeys) => selectedKeys.map(String)}*/}
      {/*      >*/}
      {/*        <Select*/}
      {/*          style={{ minWidth: 200 }}*/}
      {/*          mode="multiple"*/}
      {/*          placeholder="Chọn trạng thái"*/}
      {/*          options={[*/}
      {/*            {*/}
      {/*              value: "UPDATING",*/}
      {/*              name: "UPDATING",*/}
      {/*            },*/}
      {/*            {*/}
      {/*              value: "COMPLETED",*/}
      {/*              name: "COMPLETED",*/}
      {/*            },*/}
      {/*          ]}*/}
      {/*        />*/}
      {/*      </FilterDropdown>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <Table.Column title="Ngày tạo" />*/}
      {/*  <Table.Column title="Cập nhật lần cuối" />*/}
      {/*  <Table.Column title="Số chương" />*/}
      {/*</Table>*/}
    </Space>
  );
};
