import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { Space, Table } from "antd";

export const PopupList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable();

  return (
    <List>
      <Table {...tableProps} rowKey="_id">
        <Table.Column title="Tên" dataIndex="name" key="name" />
        <Table.Column
          title="Lượt hiển thị"
          dataIndex="totalShows"
          key="totalShows"
        />
        <Table.Column
          title="Lượt click"
          dataIndex="totalClicks"
          key="totalClicks"
        />
        <Table.Column<any>
          dataIndex="actions"
          key="actions"
          title="Hành động"
          render={(value, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record._id} />
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
