import { useCreate, useOne } from "@refinedev/core";
import { Button, Image, List, Modal, Space, Typography } from "antd";
import { IEditorJob } from "interfaces";
import React from "react";
import { BsGrid } from "react-icons/bs";
import { RiFileList2Line } from "react-icons/ri";

const { Text, Link } = Typography;

type ModalEditJobReviewProps = {
  reviewId: string | null;
  onClosed: () => void;
};

export default function ModalEditJobReview({
  reviewId,
  onClosed,
}: ModalEditJobReviewProps) {
  const [viewMode, setViewMode] = React.useState<"image" | "list">("image");
  const { mutate: mutateApprove, isLoading: isApproveLoading } = useCreate();

  const { data } = useOne<IEditorJob>({
    dataProviderName: "defaultV2",
    resource: "editor-jobs",
    id: reviewId ?? "",
    queryOptions: {
      enabled: reviewId !== null,
    },
  });

  const record = data?.data;

  const approveJob = () => {
    mutateApprove(
      {
        dataProviderName: "defaultV2",
        resource: `editor-jobs/${reviewId}/approve`,
        values: {},
        successNotification(data, values, resource) {
          return {
            message: "Duyệt thành công",
            description: `Đã duyệt thành công`,
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          onClosed();
        },
      }
    );
  };

  const renderViewImages = () => {
    return (
      record &&
      record.editedImages.map((image, index) => (
        <img src={image.url} alt={`page_${index}`} width="100%" key={index} />
      ))
    );
  };

  const renderViewList = () => {
    return (
      record &&
      record.editedImages.map((image, index) => (
        <div
          style={{
            marginBottom: "4px",
            border: "1px solid #e8e8e8",
            borderRadius: "4px",
          }}
          key={index}
        >
          <Space>
            <Image
              src={image.url}
              alt={`page_${index}`}
              style={{
                width: "80px",
                height: "80px",
                objectFit: "contain",
              }}
            />
            <Link href={image.url} target="_blank">
              {image.name}
            </Link>
          </Space>
        </div>
      ))
    );
  };

  return (
    <Modal
      open={reviewId !== null}
      title="Kiểm duyệt"
      onCancel={() => onClosed()}
      centered
      width={820}
      footer={
        <Space>
          <Button danger>Từ Chối</Button>
          <Button
            type="primary"
            loading={isApproveLoading}
            onClick={approveJob}
          >
            Duyệt
          </Button>
        </Space>
      }
    >
      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "16px",
        }}
      >
        <Button
          icon={<BsGrid />}
          type={viewMode === "image" ? "primary" : "text"}
          onClick={() => setViewMode("image")}
        />
        <Button
          icon={<RiFileList2Line />}
          type={viewMode === "list" ? "primary" : "text"}
          onClick={() => setViewMode("list")}
        />
      </Space>
      <div
        style={{
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {viewMode === "image" && renderViewImages()}
        {viewMode === "list" && renderViewList()}
      </div>
    </Modal>
  );
}
