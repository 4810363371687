import React from "react";

export const StatisticLabel: React.FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <p>
      {label}:{" "}
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {value}
      </span>
    </p>
  );
};
