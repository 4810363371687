import React from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { useModal } from "@refinedev/antd";
import {
  DeleteOutlined,
  DollarOutlined,
  DownOutlined,
  InfoCircleOutlined,
  MailOutlined,
  RedoOutlined,
  TrophyOutlined,
  WalletOutlined,
} from "@ant-design/icons";

function ContributorTableActions() {
  function handleMenuClick(key: string) {}

  const items: MenuProps["items"] = [
    {
      label: "Thông tin",
      key: "0",
      icon: <InfoCircleOutlined />,
    },
    {
      label: "Phương thức thanh toán",
      key: "1",
      icon: <WalletOutlined />,
    },
    // {
    //   label: "Lương",
    //   key: "2",
    //   icon: <DollarOutlined />,
    // },
    {
      label: "Reset mật khẩu",
      key: "3",
      icon: <RedoOutlined />,
    },
    // {
    //   label: "Gửi thư",
    //   key: "4",
    //   icon: <MailOutlined />,
    // },
    {
      label: "Điều chỉnh quyền",
      key: "5",
      icon: <TrophyOutlined />,
    },
    {
      label: "Vô hiệu hoá tài khoản",
      key: "6",
      icon: <DeleteOutlined />,
      danger: true,
    },
    // {
    //   label: "Xoá tài khoản",
    //   key: "7",
    //   icon: <DeleteOutlined />,
    //   danger: true,
    // },
  ];

  const menuProps: MenuProps = {
    items,
    onClick: ({ key }) => handleMenuClick(key),
  };

  return (
    <>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            Hành động
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </>
  );
}

export default ContributorTableActions;
