import { DateField, List, TagField } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core/dist/contexts/resource";
import { Avatar, Button, Card, Col, Form, Input, Row, Table } from "antd";
import { partnerPermissions } from "../../constants";
import { IPartner } from "interfaces";
import { SearchOutlined } from "@ant-design/icons";
import { usePartnerTable } from "hooks/partnersHooks";
import { ButtonPartnerActions } from "components/partners/ButtonPartnerActions";
import React from "react";

export const PartnerList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = usePartnerTable();

  return (
    <>
      <List title="Danh sách">
        <Card style={{ marginBottom: 16 }}>
          <Form layout="vertical" {...searchFormProps}>
            <Row gutter={24} wrap>
              <Col>
                <Form.Item label="Tìm kiếm" name="q">
                  <Input
                    placeholder="Tìm tên, email,..."
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button htmlType="submit" type="primary">
                Tìm
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Table {...tableProps} rowKey="_id">
          <Table.Column
            title="Ảnh đại diện"
            dataIndex="avatarUrl"
            render={(value) => {
              return <Avatar src={value} />;
            }}
          />
          <Table.Column title="Tên" dataIndex="name" />
          <Table.Column title="Email" dataIndex="email" />
          <Table.Column
            title="Quyền"
            dataIndex="permissions"
            render={(value) => {
              return value.map((item: string) => {
                const label = partnerPermissions.find(
                  (p) => p.value === item
                )?.label;
                return <TagField value={label} key={item} />;
              });
            }}
          />
          <Table.Column
            title="Ngày tạo"
            dataIndex="createdAt"
            render={(value) => {
              return <DateField value={value} format="HH:mm DD/MM/YYYY" />;
            }}
          />
          <Table.Column<IPartner>
            title="Hành động"
            dataIndex="actions"
            render={(value, record) => {
              return <ButtonPartnerActions partner={record} />;
            }}
          />
        </Table>
      </List>
    </>
  );
};
