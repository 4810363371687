import React, { FC, useEffect, useState } from "react";
import { IResourceComponentsProps, useNavigation } from "@refinedev/core";
import { List } from "@refinedev/antd";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { BookSelect } from "../../components/chapter/BookSelect";
import { ChapterTable } from "../../components/chapter/ChapterTable";
import { ChapterListUtility } from "components/chapter/ChapterListUtility";

const { Title, Text } = Typography;

export const ChapterList: FC<IResourceComponentsProps> = () => {
  const { useLocation, useParams } = routerProvider;
  const { search, pathname } = useLocation();

  const params = new URLSearchParams(search);
  const bookId = params.get("bookId");

  const [selectId, setSelectId] = useState<string | null>(bookId);
  const { push, create, replace } = useNavigation();

  useEffect(() => {
    if (selectId) {
      replace(`${pathname}?bookId=${selectId}`);
    }
  }, [selectId]);

  return (
    <>
      <List
        title="Danh sách chương"
        createButtonProps={{
          onClick: () => {
            if (selectId == null) {
              create("chapter");
            } else {
              push("/book-management/chapter/create?bookId=" + selectId);
            }
          },
        }}
      >
        <div style={{ marginBottom: "16px" }}>
          <Title level={5}>Truyện</Title>
          <BookSelect
            type="novel"
            onChange={(value: string) => setSelectId(value)}
          />
        </div>

        {selectId == null ? (
          <div>
            <Row justify="center">
              <Col>
                <Text>Vui lòng chọn truyện </Text>
              </Col>
            </Row>
          </div>
        ) : (
          <>
            <ChapterListUtility bookId={selectId} onSuccess={() => {}} />
            <ChapterTable bookId={selectId} />
          </>
        )}
      </List>
    </>
  );
};
