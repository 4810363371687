import { Area } from "@ant-design/plots";
import { Card, Space, Statistic } from "antd";
import React from "react";
import { IAnalyticsEditorJob } from "../../interfaces";

type CompleteJobsDashboardCardProps = {
  props: IAnalyticsEditorJob[];
  field: "totalApproved" | "totalReceived" | "totalDone";
  title: string;
};

export default function CompleteJobsDashboardCard({
  props,
  field,
  title,
}: CompleteJobsDashboardCardProps) {
  const data = props.map((item) => {
    return {
      ...item,
      partner: item.editorId.name,
    };
  });
  const total = props.reduce((acc, cur) => {
    return acc + cur[field];
  }, 0);

  const config = {
    data: data,
    xField: "date",
    yField: field,
    seriesField: "partner",
    xAxis: {
      type: "time",
      //   tickCount: 5,
      mask: "DD/MM/YYYY",
    },
    tooltip: {
      formatter: function formatter(datum: any) {
        return {
          name: "Số job",
          value: datum[field] ?? 0,
        };
      },
    },
  };

  return (
    <Card>
      <Space
        direction="vertical"
        style={{
          display: "flex",
        }}
        size="large"
      >
        <Space>
          <Statistic title={title} value={total} />
        </Space>

        <Area {...config} />
      </Space>
    </Card>
  );
}
