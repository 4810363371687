import { useModalForm, useSelect } from "@refinedev/antd";
import { Dropdown, Form, MenuProps, Modal, Select } from "antd";
import React from "react";
import {
  AudioOutlined,
  EditOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { IBook } from "../../interfaces";
import { useDelete, useNavigation } from "@refinedev/core";
import { useNotifyNewChapters } from "../../hooks/client_notification_hooks";
import { useCreateAudioFromNovel } from "../../hooks/bookHooks";
import { AiOutlineMergeCells, AiOutlineUserDelete } from "react-icons/ai";

import { ModalLinkVariants } from "components/modal/ModalLinkVariants";
import { BiTransferAlt } from "react-icons/bi";

import _ from "underscore";

export const NovelDropdownButtonActions: React.FC<{
  data: IBook;
  onTransferToContributor: () => void;
  refreshTable?: () => void;
}> = ({ data, onTransferToContributor, refreshTable }) => {
  const nav = useNavigation();
  const { notify } = useNotifyNewChapters();
  const { createAudio } = useCreateAudioFromNovel();
  const {
    show: showModalVariants,
    modalProps,
    formProps,
  } = useModalForm({
    resource: `books/${data._id}/variants`,
    dataProviderName: "defaultV2",
    redirect: false,
    action: "create",
    successNotification(data, values, resource) {
      return {
        message: "Thành công",
        description: "Liên kết biến thể thành công",
        type: "success",
      };
    },
  });

  const { mutate: mutateDelete } = useDelete();

  const handleShowChapters = (id: string, bookType: string) => {
    switch (bookType) {
      case "comic":
        nav.push(`/comic-management/chapter?bookId=${id}`);
        break;

      case "novel":
        nav.push(`/book-management/chapter?bookId=${id}`);
        break;

      case "audio":
        nav.push(`/audio-management/chapter?bookId=${id}`);
        break;

      default:
        break;
    }
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleShowChapters(data._id, data.type ?? "novel");
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    switch (e.key) {
      case "1":
        nav.edit("book", data.slugId ?? "");
        break;

      case "2":
        notify(data._id);
        break;

      case "3":
        createAudio(data._id);
        break;

      case "4":
        showModalVariants();
        break;

      case "5":
        onTransferToContributor();
        break;

      case "remove-contributor":
        onRemoveContributor();
        break;
    }
  };

  const onRemoveContributor = () => {
    mutateDelete(
      {
        resource: `books/${data._id}`,
        id: "contributor",
        values: {},
        dataProviderName: "defaultV2",
        successNotification: (data) => {
          return {
            message: "Xoá thành công",
            description: "Đã xoá người đóng góp thành công",
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          refreshTable && refreshTable();
        },
      }
    );
  };

  const items: MenuProps["items"] = [
    {
      label: "Chỉnh sửa",
      key: "1",
      icon: <EditOutlined />,
    },
    {
      label: "Thông báo chương mới",
      key: "2",
      icon: <NotificationOutlined />,
    },
  ];

  if (!data.type || data.type === "novel")
    items.push({
      label: "Tạo Audio",
      key: "3",
      icon: <AudioOutlined />,
    });

  items.push({
    label: "Liên kết",
    key: "4",
    icon: <AiOutlineMergeCells />,
  });

  if (data.type === "comic" || data.type === "novel") {
    if (_.isEmpty(data.contributorId)) {
      items.push({
        label: "Chuyển tự phát hành",
        key: "5",
        icon: <BiTransferAlt />,
      });
    } else {
      items.push({
        label: "Tước quyền phát hành",
        key: "remove-contributor",
        icon: <AiOutlineUserDelete />,
      });
    }
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <Dropdown.Button menu={menuProps} onClick={handleButtonClick}>
        Ds.Chương
      </Dropdown.Button>
      {data._id && (
        <ModalLinkVariants
          modalProps={modalProps}
          formProps={formProps}
          bookId={data._id}
        />
      )}
    </>
  );
};
