import { items } from "../constants";

export function getColorStatus(status: string): string {
  switch (status) {
    case "UPDATING":
      return "green";

    case "COMPLETED":
      return "yellow";
  }

  return "green";
}

export function getColorsAccountRole(role: string): string {
  switch (role) {
    case "SUPER":
      return "orange";

    case "EDITOR":
      return "green";
  }

  return "blue";
}

export function getColorAuthProvider(provider: string) {
  switch (provider) {
    case "google.com":
      return "red";
  }

  return "blue";
}

export function getItemDetail(type: string) {
  return items.filter((item) => item.type === type)[0];
}

export const formatVND = (value: number) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function makeId(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result.toUpperCase();
}

export function getBookTypeLabel(type: string) {
  switch (type) {
    case "novel":
      return "Tiểu thuyết";

    case "audio":
      return "Audio";

    case "comic":
      return "Truyện Tranh";

    default:
      return "";
  }
}

// "pending", "done", "error"
export const genAudioStatus = [
  {
    status: "pending",
    value: "pending",
    label: "Đang chờ",
    color: "blue",
  },
  {
    status: "processing",
    value: "processing",
    color: "yellow",
    label: "Đang xử lý",
  },
  {
    status: "done",
    value: "done",
    label: "Hoàn thành",
    color: "green",
  },
  {
    status: "error",
    value: "error",
    label: "Lỗi",
    color: "red",
  },
  {
    status: "publish-queue",
    value: "publish-queue",
    label: "Đợi xuất bản",
    color: "orange",
  },
];

export function getAudioStatus(status: string) {
  return genAudioStatus.filter((item) => item.status === status)[0];
}

export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  return formatter.format(value);
};

export function thousandFormat(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
