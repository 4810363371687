import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  CreateButton,
  DateField,
  FilterDropdown,
  getDefaultSortOrder,
  List,
  TagField,
  useModal,
  useModalForm,
  useTable,
} from "@refinedev/antd";
import {
  Avatar,
  Button,
  Descriptions,
  Popover,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { IContributor, IContributorInvoice } from "../../interfaces";
import { formatVND, genAudioStatus } from "../../utils";
import { invoiceStatusOptions } from "../../constants";
import { StatisticLabel } from "../../components/common/StatisticLabel";
import { ContributorInvoiceActions } from "../../components/contributor-invoice/ContributorInvoiceActions";
import { PaymentInfoDesc } from "../../components/contributor-invoice/PaymentInfoDesc";
import { FaMagic } from "react-icons/fa";
import { ModalContributorInvoiceCreateMany } from "../../components/modal/ModalContributorInvoiceCreateMany";

const { Text } = Typography;

export const ContributorInvoiceList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps, tableQueryResult, sorters } =
    useTable<IContributorInvoice>({
      resource: "contributor-invoices",
      dataProviderName: "defaultV2",
      initialSorter: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    });

  const {
    show: showModalCreateMany,
    modalProps: modalCreateManyProps,
    formProps: modalFormProps,
  } = useModalForm({
    action: "create",
    resource: "contributor-invoices/create-many",
    dataProviderName: "defaultV2",
    redirect: false,
    onMutationSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  return (
    <>
      <List
        title="Thanh toán"
        headerButtons={[
          <Button
            type="primary"
            icon={<FaMagic style={{ marginRight: "8px", marginBottom: 0 }} />}
            onClick={() => showModalCreateMany()}
          >
            Tạo tự động
          </Button>,
          <CreateButton resource="contributor-invoices" />,
        ]}
      >
        <Table {...tableProps} rowKey="_id">
          <Table.Column
            dataIndex="contributor"
            title="Nhóm dịch"
            render={(value: IContributor, record, index) => {
              return (
                <Space>
                  <Avatar src={value.avatar} />
                  <Text>{value.name}</Text>
                </Space>
              );
            }}
          />
          <Table.Column<IContributorInvoice>
            dataIndex="time"
            title="Tháng"
            sorter
            defaultSortOrder={getDefaultSortOrder("time", sorters)}
            render={(value, record) => {
              return (
                <Popover
                  content={
                    <div>
                      <span>
                        <DateField
                          value={record.startDate}
                          format="DD/MM/YYYY"
                        />
                      </span>{" "}
                      -{" "}
                      <span>
                        <DateField value={record.endDate} format="DD/MM/YYYY" />
                      </span>
                    </div>
                  }
                >
                  <Text
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    {record.month}/{record.year}
                  </Text>
                </Popover>
              );
            }}
          />
          <Table.Column<IContributorInvoice>
            dataIndex="amount"
            title="Xu"
            sorter
            defaultSortOrder={getDefaultSortOrder("amount", sorters)}
            render={(value, record, index) => {
              return (
                <Popover
                  content={
                    <div>
                      <StatisticLabel
                        label={"Mở khoá"}
                        value={`${record.detail.soldChapter} xu`}
                      />
                      <StatisticLabel
                        label="Donate"
                        value={`${record.detail.gift ?? 0} xu`}
                      />
                      <StatisticLabel
                        label="Tổng"
                        value={`${record.amount} xu`}
                      />
                      <StatisticLabel
                        label="Quy đổi"
                        value={`${formatVND(record.amount * 50)}đ`}
                      />
                    </div>
                  }
                >
                  <span>
                    {record.amount} ({formatVND(record.amount * 50)} đ)
                  </span>
                </Popover>
              );
            }}
          />
          <Table.Column<IContributorInvoice>
            dataIndex="paymentInfo"
            title="Thông tin thanh toán"
            render={(value, record) => {
              return (
                <Popover
                  content={<PaymentInfoDesc paymentInfo={record.paymentInfo} />}
                >
                  <Button type="link">Xem</Button>
                </Popover>
              );
            }}
          />
          <Table.Column
            dataIndex="createdAt"
            title="Ngày tạo"
            sorter
            defaultSortOrder={getDefaultSortOrder("createdAt", sorters)}
            render={(value) => {
              return <DateField value={value} format="HH:mm - DD/MM/YYYY" />;
            }}
          />
          <Table.Column
            dataIndex="status"
            title="Trạng thái"
            render={(value) => {
              const status = invoiceStatusOptions.find(
                (x) => x.value === value
              );
              return <TagField value={status?.label} color={status?.color} />;
            }}
            filterDropdown={(props) => {
              return (
                <FilterDropdown
                  {...props}
                  mapValue={(selectedKeys) => selectedKeys.map(String)}
                >
                  <Select
                    options={invoiceStatusOptions}
                    mode="multiple"
                    placeholder="Lọc trạng thái..."
                    style={{ minWidth: 200 }}
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<IContributorInvoice>
            dataIndex="actions"
            title="Hành động"
            render={(value, record) => {
              return (
                <ContributorInvoiceActions
                  id={record._id}
                  refresh={() => {
                    tableQueryResult.refetch();
                  }}
                  status={record.status}
                />
              );
            }}
          />
        </Table>
      </List>

      <ModalContributorInvoiceCreateMany
        modalProps={modalCreateManyProps}
        formProps={modalFormProps}
      />
    </>
  );
};
