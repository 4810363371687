import React from "react";
import { useSelect } from "@refinedev/antd";
import { Select } from "antd";
import { IBook } from "../../interfaces";

export const BookSelect: React.FC<{ type: string; onChange: Function }> = ({
  type,
  onChange,
}) => {
  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 20,

    // defaultValue: bookId!,
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
      {
        field: "type",
        operator: "eq",
        value: type,
      },
    ],

    filters: [
      {
        field: "type",
        operator: "eq",
        value: type,
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  return (
    <Select
      {...bookSelectProps}
      style={{ minWidth: "240px" }}
      onChange={(valueType, option) => {
        const value = valueType.toString();
        onChange(value);
      }}
    />
  );
};
