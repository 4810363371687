import { useOne } from "@refinedev/core";
import { Avatar, Space, Typography } from "antd";
import React from "react";

type Props = {
  id: string;
};

const { Text } = Typography;

export default function UserTableRow({ id }: Props) {
  const { data } = useOne({
    resource: "user",
    id,
  });

  const record = data?.data;

  return (
    <Space>
      <Avatar src={record?.avatarUrl} />
      <Space
        direction="vertical"
        style={{
          display: "flex",
          gap: "0px",
        }}
        size="small"
      >
        <Text
          style={{
            fontSize: "14px",
            padding: "0px",
          }}
        >
          {record?.displayName}
        </Text>
        <Text
          style={{
            color: "gray",
            fontSize: "12px",
            padding: "0px",
          }}
        >
          {record?.email}
        </Text>
      </Space>
    </Space>
  );
}
