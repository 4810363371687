import { Form, Input, InputNumber, Modal } from "antd";
import React from "react";
import { useCreate } from "@refinedev/core";

type Props = {
  isShow: boolean;
  onClose: Function;
  ids: string[];
  onSuccess: Function;
};

export default function ModalAdjustChapterNumber({
  isShow,
  onClose,
  ids,
  onSuccess,
}: Props) {
  const { mutate } = useCreate();

  const handleFinish = (values: any) => {
    mutate(
      {
        resource: "chapters/adjust-number",
        dataProviderName: "defaultV2",
        values: {
          ...values,
          ids,
        },
        successNotification: (data) => {
          return {
            message: "Điều chỉnh số thứ tự thành công",
            title: "Thành công",
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          onClose();
          onSuccess();
        },
      }
    );
  };

  return (
    <Modal
      title="Điều chỉnh số thứ tự"
      centered
      open={isShow}
      onCancel={() => onClose()}
      okButtonProps={{
        htmlType: "submit",
        form: "form-adjust",
      }}
    >
      <Form id="form-adjust" onFinish={handleFinish} layout="vertical">
        <Form.Item
          name="amount"
          label="Số lượng"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số lượng",
            },
          ]}
        >
          <InputNumber
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
