import { IResourceComponentsProps } from "@refinedev/core";
import React, { FC } from "react";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import { Card, Form, Input, Row, Select } from "antd";
import { IAccount } from "../../interfaces";
import { accountRoleOptions } from "../../constants";
import _ from "underscore";
import PermissionShowing from "../../components/PermissionShowing";
import AuthLocalDetect from "../../components/AuthLocalDetect";

export const AccountEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IAccount>();
  const { formProps: changePassFormProps, saveButtonProps: savePassFormProps } =
    useForm<IAccount>();

  return (
    <>
      <Edit title="Chỉnh sửa tài khoản" saveButtonProps={saveButtonProps}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label="Tên hiển thị"
            name="fullName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="name" />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly />
          </Form.Item>
          <PermissionShowing enabled={["SUPER"]}>
            <Form.Item
              label="Chức vụ"
              name="role"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={accountRoleOptions} placeholder="Chọn chức vụ" />
            </Form.Item>
          </PermissionShowing>
        </Form>
      </Edit>
      <AuthLocalDetect
        isShow={true}
        username={formProps.form?.getFieldValue("username")}
      >
        <Card title="Đổi mật khẩu" style={{ marginTop: "16px" }}>
          <Form {...changePassFormProps} layout="vertical">
            <Form.Item
              label="Mật khẩu mới"
              name="password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Nhập lại mật khẩu mới"
              name="re-password"
              rules={[
                {
                  required: true,
                  validator: (rule, value, callback) => {
                    const password =
                      changePassFormProps.form?.getFieldValue("password");

                    if (_.isEmpty(password) || value !== password) {
                      return Promise.reject("Nhập lại mật khẩu không đúng");
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
          <Row justify="end">
            <SaveButton {...savePassFormProps} />
          </Row>
        </Card>
      </AuthLocalDetect>
    </>
  );
};
