import { Create, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Checkbox, Form, Input, InputNumber, Select, Space } from "antd";
import { IBook } from "interfaces";

export const EditJobsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    dataProviderName: "defaultV2",
  });

  const { selectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "comic",
      },
    ],
    onSearch: (search) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: search,
        },
      ];
    },
  });

  return (
    <Create title="Tạo công việc Edit" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Quan trọng?"
          name="isImportant"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Truyện"
          name="bookId"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn truyện",
            },
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>
        <Space>
          <Form.Item
            label="Từ chương"
            name="fromNumber"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập số chương",
              },
            ]}
          >
            <InputNumber
              min={0}
              style={{
                minWidth: 240,
              }}
            />
          </Form.Item>
          <Form.Item
            label="Đến chương"
            name="toNumber"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập số chương",
              },
            ]}
          >
            <InputNumber
              min={0}
              style={{
                minWidth: 240,
              }}
            />
          </Form.Item>
        </Space>
        <Form.Item
          label="Link Raw"
          name="rawLink"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập link raw",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Thời gian quy định (ngày)"
          name="expiredDays"
          initialValue={7}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Create>
  );
};
