import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import { InfinityScroller } from "../infinity-scroller";
import { List } from "antd";
import { socket } from "../../socket";

import useSound from "use-sound";
import { useInfiniteList, useNavigation } from "@refinedev/core";
import { ChatMessage } from "./ChatMessage";

import { CloseOutlined, PaperClipOutlined } from "@ant-design/icons";
import { StickerPicker } from "./StickerPicker";
import { AttachmentItem, AttachmentPicker } from "./attachment-picker";

const room = "global";
const { Text } = Typography;

export const ChatBox: React.FC<{ userId: string }> = ({ userId }) => {
  const [attachment, setAttachment] = React.useState<any>(null);
  const [messages, setMessages] = React.useState<any>([]);
  const formRef = React.useRef<FormInstance>(null);
  const [roomInfo, setRoomInfo] = React.useState<any>({
    totalUsers: 0,
    userIds: [],
  });
  const router = useNavigation();

  const [playSound] = useSound("/audio/notify.mp3", { volume: 0.25 });
  const { fetchNextPage, hasNextPage, isLoading } = useInfiniteList({
    dataProviderName: "realtime",
    resource: "chat-logs",
    queryOptions: {
      onSuccess: (data) => {
        const allPages = data.pages
          .map((page: any) => page.data)
          .flat()
          .reverse();
        setMessages(allPages);
      },
    },
  });

  useEffect(() => {
    socket.connect();

    function onConnect() {
      socket.emit("join", { room: "global", userId: userId });
    }

    function onDisconnect() {
      console.log("disconnect");
    }

    function onChat(data: any) {
      console.log("chat", data);
      insertChat(data);
    }

    function onJoined(data: any) {
      setRoomInfo(data);
      playSound();
    }

    function onLeave(data: any) {
      setRoomInfo(data);
      playSound();
    }

    function onDeleteChat(data: any) {
      setMessages((preMessages: any) =>
        preMessages.filter((msg: any) => msg._id !== data._id)
      );
    }

    function onDeleteChatUser(data: any) {
      setMessages((preMessages: any) =>
        preMessages.filter((msg: any) => msg.userId !== data.userId)
      );
    }

    function onKickedChatUser(data: any) {
      // setMessages((preMessages: any) =>
      //     preMessages.filter((msg: any) => msg.userId !== data.userId)
      // );
      Modal.error({
        title: "Bạn đã bị đá khỏi phòng",
        content: "Bạn đã bị đá khỏi phòng",
        onOk() {
          socket.disconnect();
          router.push("/");
        },
      });
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("chat", onChat);
    socket.on("joined", onJoined);
    socket.on("leave", onLeave);
    socket.on("delete-chat", onDeleteChat);
    socket.on("delete-chat-user", onDeleteChatUser);
    socket.on("kick", onKickedChatUser);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("chat", onChat);
      socket.off("joined", onJoined);
      socket.off("leave", onLeave);
      socket.off("delete-chat", onDeleteChat);
      socket.off("delete-chat-user", onDeleteChatUser);
      socket.off("kick", onKickedChatUser);
      socket.disconnect();
    };
  }, []);

  const insertChat = (msgData: any) => {
    setMessages((preMessages: any) => [...preMessages, msgData]);
  };

  const handleDeleteChat = (msgId: string) => {
    socket.emit("delete-chat", { _id: msgId, room: room });
  };

  const handleDeleteAllChat = (userId: string) => {
    socket.emit("delete-chat-user", { userId: userId, room: room });
  };

  const handleBanUser = (userId: string) => {
    socket.emit("kick", { userId: userId, room: room });
  };

  return (
    <Card loading={isLoading}>
      <InfinityScroller
        itemLength={messages.length}
        fetch={async () => {
          await fetchNextPage();
        }}
        hasMore={hasNextPage ?? false}
      >
        <List
          dataSource={messages}
          renderItem={(item: any) => {
            return (
              <List.Item key={item._id ?? ""}>
                <ChatMessage
                  {...item}
                  localUserId={userId}
                  deleteOne={(_id: string) => handleDeleteChat(_id)}
                  deleteAll={(userId: string) => handleDeleteAllChat(userId)}
                  onBanSuccess={(userId: string) => handleBanUser(userId)}
                />
              </List.Item>
            );
          }}
        />
      </InfinityScroller>
      <div
        style={{
          marginTop: "16px",
        }}
      >
        <Form
          ref={formRef}
          name="basic"
          onFinish={(data) => {
            socket.emit("chat", { ...data, ...{ type: "text", attachment } });
            formRef.current?.resetFields();
          }}
          style={{
            marginBottom: "0px",
          }}
        >
          <Form.Item name="room" hidden initialValue="global" />
          <Form.Item name="userId" hidden initialValue={userId} />
          <Row gutter={16}>
            <Col flex="auto">
              <Form.Item
                name="message"
                rules={[{ required: true, message: "Vui lòng nhập tin nhắn!" }]}
                style={{
                  marginBottom: "0px",
                }}
              >
                <Input.TextArea placeholder="Hãy sủa gì đó" />
              </Form.Item>
              {attachment && (
                <AttachmentItem
                  onRemoveAttachment={() => setAttachment(null)}
                  attachment={attachment}
                />
              )}
            </Col>
            <Col flex="none">
              <Button htmlType="submit">Gửi</Button>
            </Col>
          </Row>
        </Form>
      </div>

      <Space style={{ marginTop: "8px" }}>
        <StickerPicker
          onSendSticker={(url: string) => {
            socket.emit("chat", {
              message: url,
              type: "sticker",
              room: "global",
              userId: userId,
            });
          }}
        />

        <AttachmentPicker
          onAttachment={(attachment: any) => setAttachment(attachment)}
        />
      </Space>

      <div
        style={{
          marginTop: "8px",
        }}
      >
        <span>Online: {roomInfo.totalUsers} người</span>
      </div>
    </Card>
  );
};
