import React from "react";
import { TabsProps, Tabs } from "antd";
import EditorJobsReviewTable from "./EditorJobsReviewTable";

export default function EditorJobsReview() {
  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: `Đang chờ`,
      children: <EditorJobsReviewTable status="DONE" />,
    },
    {
      key: "2",
      label: `Đã duyệt`,
      children: <EditorJobsReviewTable status="APPROVED" />,
    },
    {
      key: "3",
      label: `Đã từ chối`,
      children: <EditorJobsReviewTable status="REJECTED" />,
    },
    {
      key: "4",
      label: `Đã đăng`,
      children: <EditorJobsReviewTable status="PUBLISHED" />,
    },
  ];

  return <Tabs items={tabItems} />;
}
