import { UploadFile } from "antd/lib/upload/interface";
import { Tooltip } from "antd";
import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";

const type = "DraggableUploadList";

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile;
  fileList: UploadFile[];
  onClick?: (file: UploadFile) => void;
  moveRow: (dragIndex: any, hoverIndex: any) => void;
}

const DraggableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
  onClick,
}: DraggableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);

  const [collectedProps, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },

    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );

  return (
    <div
      ref={ref}
      style={{
        cursor: "move",
        border: collectedProps.isOver ? "2px dashed green" : "0px",
        marginTop: collectedProps.isOver ? "12px" : "0px",
        paddingRight: collectedProps.isOver ? "12px" : "0px",
        paddingLeft: collectedProps.isOver ? "12px" : "0px",
        borderRadius: "8px",
        transition: "all 0.3s",
      }}
    >
      {file.status === "error" ? (
        errorNode
      ) : (
        // : originNode
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div style={{ flex: "100%" }}>{originNode}</div>

          {/*<div style={{}}>*/}
          {/*  <EditButton*/}
          {/*    size="small"*/}
          {/*    hideText*/}
          {/*    onClick={() => {*/}
          {/*      onClick && onClick(file);*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      )}
    </div>
  );
};

export default DraggableUploadListItem;
