import {
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Select,
} from "antd";
import React from "react";
import { useSelect } from "@refinedev/antd";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const ModalAudioTaskCreateRange: React.FC<Props> = ({
  modalProps,
  formProps,
}: Props) => {
  const { selectProps: audioBookSelectProps } = useSelect({
    resource: "book",
    queryOptions: {
      enabled: true,
    },
    optionValue: "_id",
    optionLabel: "name",
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "audio",
      },
    ],
    onSearch: (value) => {
      return [
        {
          field: "q",
          operator: "eq",
          value,
        },
      ];
    },
  });

  return (
    <Modal {...modalProps} centered width="480px" title="Tự động">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Truyện audio"
          name="bookId"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập không để trống",
            },
          ]}
        >
          <Select {...audioBookSelectProps} />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  label="Truyện chữ"*/}
        {/*  name="refBookId"*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*      message: "Vui lòng nhập không để trống",*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  hidden={true}*/}
        {/*>*/}
        {/*  <Input />*/}
        {/*</Form.Item>*/}
        <Form.Item
          label="Từ số"
          name="from"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập không để trống",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="Đến số"
          name="to"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập không để trống",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
