import React, { FC, ReactElement } from "react";
import { useGetIdentity } from "@refinedev/core";
import { IAccount } from "../interfaces";

const AuthLocalDetect: FC<{
  children: ReactElement;
  isShow: boolean;
  username: string;
}> = ({ children, isShow, username }) => {
  const { data: accountInfo, status } = useGetIdentity<IAccount>({
    v3LegacyAuthProviderCompatible: true
  });

  if (status === "success") {
    console.log("A");
    if (isShow) {
      return accountInfo?.username === username ? children : <div />;
    } else {
      return accountInfo?.username === username ? <div /> : children;
    }
  }

  return <div />;
};

export default AuthLocalDetect;
