import React from "react";
import { Form, Modal, Upload, Typography, message } from "antd";

import { getValueFromEvent } from "@refinedev/antd";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useCreate } from "@refinedev/core";

const { Text } = Typography;

type Props = {
  invoiceId: string | null;
  onClose: Function;
};

export const ModalCompleteContributorInvoice: React.FC<Props> = ({
  invoiceId,
  onClose,
}) => {
  const { mutate, isLoading } = useCreate({});

  const onFinish = async (values: any) => {
    const receipt = values.transferReceipt[0];

    if (receipt.response[0]) {
      mutate(
        {
          resource: `contributor-invoices/${invoiceId}/complete`,
          dataProviderName: "defaultV2",
          values: {
            transferReceipt: receipt.response[0],
          },
          successNotification: (data) => {
            return {
              message: "Hoàn thành thanh toán thành công",
              description: "Thành công",
              type: "success",
            };
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
          onError: (error) => {
            onClose();
          },
        }
      );
    } else {
      message.error("Vui lòng tải lên ảnh xác nhận chuyển khoản");
    }
  };

  return (
    <Modal
      title="Hoàn thành thanh toán"
      centered
      onCancel={() => onClose()}
      open={invoiceId != null}
      okButtonProps={{
        htmlType: "submit",
        form: "form-complete",
        loading: isLoading,
      }}
    >
      <Form id={"form-complete"} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Ảnh xác nhận chuyển khoản"
          name="transferReceipt"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
              message: "Vui lòng tải lên ảnh xác nhận chuyển khoản",
            },
          ]}
        >
          <Upload.Dragger
            name="images"
            listType="picture"
            action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
            multiple={false}
            maxCount={1}
            accept=".png,.jpeg,.jpg,.webp"
          >
            <div
              style={{
                height: 360,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineCloudUpload
                style={{
                  fontSize: 48,
                }}
              />
              <Text>Kéo & thả ảnh để tải lên</Text>
            </div>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};
