import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { List } from "@refinedev/antd";
import { Tabs, TabsProps } from "antd";
import { ReviewingTable } from "../../components/reviewing/ReviewingTable";

export const ReviewingList: React.FC<IResourceComponentsProps> = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Chờ duyệt`,
      children: <ReviewingTable status="PENDING" />,
    },
    {
      key: "2",
      label: `Đã duyệt`,
      children: <ReviewingTable status="APPROVED" />,
    },
    {
      key: "3",
      label: `Đã từ chối`,
      children: <ReviewingTable status="REJECTED" />,
    },
  ];

  return (
    <List title="Xét duyệt truyện">
      <Tabs items={items} />
    </List>
  );
};
