import React from "react";
import { Button, Col, Popover, Row, Tabs } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useList } from "@refinedev/core";
import { ISticker } from "../../interfaces";
import { StickerTabContent } from "./StickerTabContent";

export const StickerPicker: React.FC<{ onSendSticker: Function }> = ({
  onSendSticker,
}) => {
  const { data } = useList<ISticker>({
    resource: "stickers",

    pagination: {
      pageSize: 100,
    }
  });

  const itemsToTabs = (items: ISticker[]) => {
    return items.map((item) => {
      return {
        key: item._id,
        label: item.name,
        children: (
          <StickerTabContent
            stickerId={item._id}
            onSendSticker={(url: string) => onSendSticker(url)}
          />
        ),
      };
    });
  };

  return (
    <Popover
      placement="bottomLeft"
      title={"Stickers"}
      content={
        <div
          style={{
            width: "400px",
          }}
        >
          <Tabs
            defaultActiveKey="1"
            items={itemsToTabs(data == null ? [] : data.data)}
            tabPosition="top"
          />
        </div>
      }
      trigger="click"
    >
      <Button icon={<SmileOutlined />} />
    </Popover>
  );
};
