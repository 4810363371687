import { Edit, getValueFromEvent, useForm } from "@refinedev/antd";
import { Form, Input, Select, TreeSelect, Typography, Upload } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { ICategory } from "interfaces";
import React, { FC } from "react";
import { useTreeCategoriesSelect } from "hooks/treeHook";
import { bookTypes } from "../../constants";

const { Text } = Typography;

export const CategoryEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ICategory>({});

  const { treeData, onLoadData } = useTreeCategoriesSelect({
    preventId: queryResult?.data?.data._id,
    initCateParentId: queryResult?.data?.data.parentId,
    editMode: true,
  });

  return (
    <Edit title="Chỉnh sửa" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Thumbnail"
          name="cover"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload
            name="images"
            listType="picture-card"
            action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
            maxCount={1}
            multiple={false}
            accept=".png,.jpeg,.jpg"
          >
            <Text>Drag & drop image to upload</Text>{" "}
          </Upload>
        </Form.Item>
        <Form.Item label="Danh mục cha" name="parentId">
          <TreeSelect
            placeholder="Chọn danh mục cha"
            multiple={false}
            treeDataSimpleMode
            showSearch
            treeData={treeData}
            loadData={async (treeNode) => onLoadData(treeNode.id)}
            allowClear
            onSearch={(value) => {
              console.log(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mô tả"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea maxLength={400} />
        </Form.Item>
        <Form.Item
          label="Nhóm"
          name="suggestSex"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn nhóm",
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Nam",
                value: "male",
              },
              {
                label: "Nữ",
                value: "female",
              },
              {
                label: "Tất cả",
                value: "both",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Loại truyện"
          name="bookTypes"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn loại truyện",
            },
          ]}
        >
          <Select options={bookTypes} mode="multiple" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
