import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, getValueFromEvent, useForm } from "@refinedev/antd";
import { Form, Input, InputNumber, Select, Upload, Typography } from "antd";
import slug from "slug";
import {
  popupConditionOptions,
  popupRedirectOptions,
  popupRepeatOptions,
  popupTriggerOptions,
} from "../../data/popup";

const { Text, Title } = Typography;

export const PopupEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading, saveButtonProps } = useForm({});

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title="Chỉnh sửa"
      isLoading={formLoading}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="name"
          label="Tên"
          rules={[{ required: true, message: "Tên không thể để trống" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Hình ảnh"
          name="image"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
              message: "Vui lòng chọn hình ảnh",
            },
          ]}
        >
          <Upload.Dragger
            name="images"
            listType="picture-card"
            action={`${
              process.env.REACT_APP_UPLOAD_URL
            }/images-raw?dirName=${slug("popups")}`}
            maxCount={1}
            multiple={false}
            accept=".png,.jpeg,.jpg,.gif,.webp"
          >
            <Text>Drag & drop image to upload</Text>{" "}
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          name="trigger"
          rules={[
            {
              required: true,
              message: "Trigger không thể để trống",
            },
          ]}
          label="Trigger"
        >
          <Select options={popupTriggerOptions} />
        </Form.Item>

        <Form.Item
          name="repeat"
          rules={[
            {
              required: true,
              message: "Lặp không thể để trống",
            },
          ]}
          label="Lặp"
        >
          <Select options={popupRepeatOptions} />
        </Form.Item>

        <Form.Item
          name="condition"
          rules={[
            {
              required: true,
              message: "Điều kiện không thể để trống",
            },
          ]}
          label="Điều kiện"
        >
          <Select options={popupConditionOptions} />
        </Form.Item>

        <Form.Item
          name="priority"
          rules={[
            {
              required: true,
              message: "Ưu tiên không thể để trống",
            },
          ]}
          label="Ưu tiên"
          initialValue={0}
        >
          <InputNumber />
        </Form.Item>

        <Title level={5}>Điều hướng</Title>
        <Form.Item
          label="Đích"
          name={["redirect", "to"]}
          rules={[
            {
              required: true,
              message: "Đích không thể để trống",
            },
          ]}
        >
          <Select options={popupRedirectOptions} />
        </Form.Item>
        <Form.Item label="Giá trị" name={["redirect", "value"]}>
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
