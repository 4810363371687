import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Button, Form, Input, Select, Space, Typography } from "antd";
import { partnerPermissions, partnerWagesTypes } from "../../constants";
import { IPartner } from "interfaces";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

export const PartnerCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IPartner>({
    dataProviderName: "defaultV2",
  });

  console.log("formProps", formProps.form?.getFieldsValue());

  return (
    <Create title="Thêm mới" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="name"
          label="Tên"
          rules={[{ required: true, message: "Điền tên hợp lệ" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Vui lòng điền Email hợp lệ",
              type: "email",
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[
            {
              required: true,
              message: "Vui lòng điền mật khẩu hợp lệ",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="permissions"
          label="Quyền"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn quyền",
            },
          ]}
        >
          <Select options={partnerPermissions} mode="multiple" />
        </Form.Item>

        <Title level={5}>Lương</Title>
        <Form.List name="wages">
          {(fields, { add, remove }) => (
            <Space
              direction="vertical"
              style={{
                display: "flex",
              }}
            >
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, "job"]}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng điền công việc",
                      },
                    ]}
                  >
                    <Select
                      options={partnerWagesTypes}
                      style={{
                        width: 200,
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "wage"]}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng điền mức lương",
                      },
                    ]}
                  >
                    <Input type="number" min={0} />
                  </Form.Item>
                  <Button
                    icon={<MinusCircleOutlined />}
                    danger
                    type="text"
                    onClick={() => remove(name)}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="dashed"
                  onClick={() => add()}
                >
                  Thêm mức lương
                </Button>
              </Form.Item>
            </Space>
          )}
        </Form.List>
      </Form>
    </Create>
  );
};
