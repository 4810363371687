import { DateField, DeleteButton, EditButton, List, ShowButton, TextField, useTable } from "@refinedev/antd";
import { Space, Switch, Table } from "antd";
import { IResourceComponentsProps, useUpdate } from "@refinedev/core";
import { IProduct } from "interfaces";
import React from "react";
import { formatVND } from "utils";

export const ProductList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult } = useTable<IProduct>({});
  const { mutate: mutateUpdateActive } = useUpdate();

  const handleUpdateActive = async (id: string, active: boolean) => {
    await mutateUpdateActive(
      {
        resource: "products",
        id,
        values: {
          active: active,
        },
      },
      {
        onSuccess: () => {
          tableQueryResult.refetch();
        },
      }
    );
  };

  return (
    <List title="Sản phẩm">
      <Table {...tableProps} rowKey="_id">
        <Table.Column<IProduct>
          title="Hoạt động"
          dataIndex="active"
          render={(value, record) => {
            return (
              <Switch
                defaultChecked
                checked={value}
                onChange={(value) => {
                  handleUpdateActive(record._id, value);
                }}
              />
            );
          }}
        />
        <Table.Column title="Tên" dataIndex="name" />
        <Table.Column title="Mô tả" dataIndex="description" />
        <Table.Column
          title="Giá (đ)"
          dataIndex="price"
          render={(value) => {
            return <TextField value={formatVND(value)} />;
          }}
        />
        <Table.Column
          title="Danh mục"
          dataIndex="category"
          render={(value) => {
            return value === "coin-pack" ? "Gói xu" : "Khác";
          }}
        />
        <Table.Column
          title="Ngày tạo"
          dataIndex="createdAt"
          render={(value) => {
            return <DateField value={value} format="DD/MM/YYYY - HH:mm" />;
          }}
        />
        <Table.Column<IProduct>
          title="Hành động"
          dataIndex="actions"
          render={(value, record) => {
            return (
              <Space>
                <EditButton recordItemId={record._id} size="small" hideText />
                <DeleteButton recordItemId={record._id} size="small" hideText />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
