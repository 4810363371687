import { IResourceComponentsProps } from "@refinedev/core";
import React, { FC } from "react";
import { Create, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Typography, Upload } from "antd";
import { IBanner, IBook } from "../../interfaces";

const { Text } = Typography;

export const BannerCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IBanner>({});

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 20,

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  return (
    <Create title="Tạo banner" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Ảnh"
          name="image"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload.Dragger
            name="images"
            listType="picture"
            action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
            maxCount={1}
            multiple={false}
            accept=".png,.jpeg,.jpg"
          >
            <Text>Drag & drop image to upload</Text>{" "}
          </Upload.Dragger>
        </Form.Item>
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Link"
          name="linkTo"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Link với Truyện" name="linkBook">
          <Select {...bookSelectProps} allowClear />
        </Form.Item>
        <Form.Item
          label="Loại Truyện"
          name="type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Truyện chữ",
                value: "novel",
              },
              {
                label: "Truyện Tranh",
                value: "comic",
              },
              {
                label: "Truyện Audio",
                value: "audio",
              },
              {
                label: "Truyện Ngắn",
                value: "short-novel",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Nhóm"
          name="suggestSex"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Nam",
                value: "male",
              },
              {
                label: "Nữ",
                value: "female",
              },
              {
                label: "Tất cả",
                value: "both",
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
