import { Select, Space, Table } from "antd";
import React from "react";
import {
  DateField,
  FilterDropdown,
  getDefaultSortOrder,
  ImageField,
  TagField,
  TextField,
  useSelect,
  useTable,
} from "@refinedev/antd";
import { IBook, ICategory } from "../../interfaces";
import { bookTypes } from "../../constants";
import { ReviewingTableActions } from "./ReviewingTableActions";

type ReviewingTableProps = {
  status: "PENDING" | "APPROVED" | "REJECTED";
};

export const ReviewingTable: React.FC<ReviewingTableProps> = ({ status }) => {
  const { tableProps, tableQueryResult } = useTable<IBook>({
    resource: "reviewing-books",
    dataProviderName: "defaultV2",
    filters: {
      initial: [
        {
          field: "status",
          operator: "eq",
          value: status,
        },
      ],
    },
  });

  const { selectProps: categoriesSelectProps } = useSelect<ICategory>({
    resource: "category",
    fetchSize: 10,
    optionLabel: "name",
    optionValue: "_id",

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server",
    },
  });

  const handleRefreshTable = () => {
    tableQueryResult.refetch();
  };

  return (
    <Table {...tableProps} rowKey="_id">
      <Table.Column
        dataIndex="cover"
        title="Ảnh bìa"
        key="cover"
        fixed="left"
        render={(value) => {
          return (
            <ImageField
              value={value[0].url}
              style={{
                width: "60px",
                aspectRatio: "3/4",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          );
        }}
      />
      <Table.Column
        dataIndex="name"
        title="Tên"
        key="name"
        fixed="left"
        sorter
        // defaultSortOrder={getDefaultSortOrder("name", sorter)}
        render={(value, record) => {
          return <TextField value={value} />;
        }}
      />
      <Table.Column
        dataIndex="categories"
        title="Danh mục"
        key="categories"
        render={(value, record) => {
          return value.map((item: ICategory) => <TagField value={item.name} />);
        }}
        filterDropdown={(props) => (
          <FilterDropdown
            {...props}
            mapValue={(selectedKeys) => selectedKeys.map(String)}
          >
            <Select
              style={{ minWidth: 200 }}
              mode="multiple"
              placeholder="Chọn danh mục"
              {...categoriesSelectProps}
            />
          </FilterDropdown>
        )}
      />
      <Table.Column
        dataIndex="type"
        title="Phân loại"
        render={(value) => {
          const type = bookTypes.find((x) => x.value === value);
          return <TagField value={type?.label} />;
        }}
      />
      <Table.Column
        dataIndex="createdAt"
        title="Ngày tạo"
        sorter
        // defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
        render={(value) => {
          return <DateField value={value} format={"DD/MM/YYYY - HH:mm"} />;
        }}
      />
      <Table.Column<IBook>
        dataIndex="actions"
        title="Hành động"
        render={(value, record) => {
          return (
            <ReviewingTableActions
              id={record._id}
              status={record.publishStatus}
              onRefreshTable={handleRefreshTable}
            />
          );
        }}
      />
    </Table>
  );
};
