import React from "react";
import { Button, Card, DatePicker, Form, Select, Space } from "antd";
import dayjs from "dayjs";
import { useSelect } from "@refinedev/antd";

type DashboardFilterProps = {
  startDate: Date;
  endDate: Date;
  editorId?: string;
  onChanged?: (
    startDate: Date,
    endDate: Date,
    editorId: string | undefined
  ) => void;
};

export const DashboardFilter: React.FC<DashboardFilterProps> = ({
  onChanged,
  startDate,
  endDate,
  editorId,
}) => {
  const [form] = Form.useForm();

  const { selectProps: partnerSelectProps } = useSelect({
    dataProviderName: "defaultV2",
    resource: "partners",
    optionLabel: "name",
    optionValue: "_id",
    onSearch: (search) => [
      {
        field: "q",
        operator: "eq",
        value: search,
      },
    ],
  });

  const onFinish = (values: any) => {
    const startAt = new Date(new Date(values.days[0]).setHours(0, 0, 0, 0));
    const endAt = new Date(new Date(values.days[1]).setHours(23, 59, 59, 999));
    const { editorId: formEditorId } = values;

    if (onChanged) {
      onChanged(startAt, endAt, formEditorId);
    }
  };

  return (
    <Card>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Space align="end">
          <Form.Item
            label="Ngày"
            name="days"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn ngày",
              },
            ]}
            initialValue={[dayjs(startDate), dayjs(endDate)]}
          >
            <DatePicker.RangePicker />
          </Form.Item>

          <Form.Item label="Editor" name="editorId" initialValue={editorId}>
            <Select
              style={{
                minWidth: 240,
              }}
              {...partnerSelectProps}
              allowClear
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Thống kê
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Card>
  );
};
