import React, { useEffect } from "react";
import {
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Switch,
} from "antd";

export const ModalUpdateSharingRevenueState: React.FC<{
  modalProps: ModalProps;
  formProps: FormProps;
  bookId: string;
}> = ({ modalProps, formProps, bookId }) => {
  useEffect(() => {
    formProps.form?.setFieldsValue({
      bookId,
    });
  }, [bookId, formProps.form]);

  return (
    <Modal
      {...modalProps}
      centered
      width={480}
      title="Cập nhật trạng thái chia sẻ doanh thu"
      destroyOnClose
    >
      <Form {...formProps} layout="vertical">
        <Form.Item name="bookId" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Từ số"
          name="from"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập không để trống",
            },
          ]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Đến số"
          name="to"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập không để trống",
            },
          ]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Không chia sẻ doanh thu?"
          initialValue={false}
          name="isLockSharingRevenue"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
