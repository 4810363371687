import { Form, FormProps, Input, InputNumber, Modal, ModalProps } from "antd";
import React from "react";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const ModalContributorInvoiceCreateMany: React.FC<Props> = ({
  modalProps,
  formProps,
}: Props) => {
  if (formProps.form != null) {
    // get current month
    const currentMonth = new Date().getMonth();
    formProps.form.setFieldValue("month", currentMonth);

    // get current year
    const currentYear = new Date().getFullYear();
    formProps.form.setFieldValue("year", currentYear);
  }

  return (
    <Modal
      {...modalProps}
      centered
      width="480px"
      title="Tự động tạo hoá đơn tháng"
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="month"
          label="Tháng"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn tháng",
            },
          ]}
        >
          <InputNumber min={1} max={12} placeholder="Tháng" />
        </Form.Item>
        <Form.Item
          name="year"
          label="Năm"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn năm",
            },
          ]}
        >
          <InputNumber min={2023} max={2030} placeholder="Năm" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
