import React, { FC, useState } from "react";
import { Tree } from "antd";
import { useList } from "@refinedev/core";
import CategoryTreeNodeTitle from "../CategoryTreeNodeTitle";

const updateTreeNode: any = (list: any[], key: string, children: any[]) => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeNode(node.children, key, children),
      };
    }
    return node;
  });
};

const createNodeTitle = (item: any, onDeleteSuccess: Function) => {
  return (
    <CategoryTreeNodeTitle
      slugId={item.slugId}
      onDeleteSuccess={onDeleteSuccess}
    >
      <div>{item.title}</div>
    </CategoryTreeNodeTitle>
  );
};

const CategoryTreeList: FC<{ bookTypes: string }> = ({ bookTypes }) => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [parentId, setParentId] = useState<string | null>(null);

  const { refetch } = useList({
    resource: "category/tree-select",

    queryOptions: {
      onSuccess(data) {
        const newTreeData = data.data.map((item: any) => {
          item.title = createNodeTitle(item, () => handleDelete());
          return item;
        });

        setTreeData(newTreeData);
      },
    },

    filters: [
      {
        field: "bookTypes",
        operator: "eq",
        value: bookTypes,
      },
    ]
  });

  const {} = useList({
    resource: "category/tree-select",

    queryOptions: {
      onSuccess(data) {
        const list = data.data.map((item: any) => {
          item.title = createNodeTitle(item, () => handleDelete());
          return item;
        });
        const _newTree = updateTreeNode(treeData, parentId, list);
        setTreeData(_newTree);
      },
      enabled: parentId !== null,
    },

    filters: [
      {
        field: "parentId",
        operator: "eq",
        value: parentId,
      },
      {
        field: "bookTypes",
        operator: "eq",
        value: bookTypes,
      },
    ]
  });

  const handleDelete = () => {
    setParentId(null);
    refetch();
  };

  return (
    <Tree
      showLine
      treeData={treeData}
      loadData={async ({ key, children }: any) => {
        setParentId(key);
      }}
    />
  );
};

export default CategoryTreeList;
