import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import React from "react";
import { IContributor } from "../../interfaces";
import { Checkbox, Form, Input, InputNumber, Select } from "antd";
import { contributorPermissions, partnerPermissions } from "../../constants";

export const ContributorCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IContributor>({
    dataProviderName: "defaultV2",
  });

  return (
    <Create title="Tạo tài khoản đóng góp" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="name"
          label="Tên"
          rules={[{ required: true, message: "Điền tên hợp lệ" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Vui lòng điền Email hợp lệ",
              type: "email",
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[
            {
              required: true,
              message: "Vui lòng điền mật khẩu hợp lệ",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="permissions"
          label="Quyền"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn quyền",
            },
          ]}
        >
          <Select options={contributorPermissions} mode="multiple" />
        </Form.Item>
        <Form.Item
          name="commission"
          label="Hoa hồng"
          rules={[
            {
              required: true,
              message: "Vui lòng điền hoa hồng",
            },
          ]}
        >
          <InputNumber max={1} min={0} />
        </Form.Item>
        <Form.Item
          name="autoApprove"
          label="Tự động duyệt"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn",
            },
          ]}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Create>
  );
};
