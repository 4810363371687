import { useOne } from "@refinedev/core";
import React from "react";

type IAPProductFieldProps = {
  id: string;
};

export default function IAPProductField({ id }: IAPProductFieldProps) {
  const { data } = useOne({
    resource: "iap-products",
    id,
    dataProviderName: "defaultV2",
  });

  const record = data?.data;
  return <div>{record?.productId}</div>;
}
