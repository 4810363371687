export const popupTriggerOptions = [
  {
    label: "Mở app",
    value: "open-app",
  },
  {
    label: "Mở app từ nền",
    value: "open-from-background",
  },
];

export const popupRepeatOptions = [
  {
    label: "Hiện 1 lần duy nhất",
    value: "once",
  },
  {
    label: "Luôn lặp lại",
    value: "always",
  },
];

export const popupConditionOptions = [
  {
    label: "Không có điều kiện",
    value: "none",
  },
  {
    label: "Chưa đánh giá App",
    value: "rate-available",
  },
];

export const popupRedirectOptions = [
  {
    label: "Đường Link",
    value: "url",
  },
  {
    label: "Truyện",
    value: "book",
  },
  {
    label: "Bảng nhiệm vụ",
    value: "mission",
  },
  {
    label: "Đánh giá app trên chợ",
    value: "rate-app",
  },
];
