import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, List, useForm } from "@refinedev/antd";
import { Checkbox, Form, Input, InputNumber, Select, Typography } from "antd";
import {
  iapProductDefinePrices,
  iapProductPurchaseTypes,
  iapProductTypes,
} from "../../constants";

const { Text, Title } = Typography;

export const IAPProductEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    resource: "iap-products",
    dataProviderName: "defaultV2",
  });

  return (
    <Edit title="Chỉnh sửa" saveButtonProps={saveButtonProps}>
      <Form layout="vertical" {...formProps}>
        <Form.Item
          label="Chỉ hiển thị trên web"
          name="webOnly"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Product id"
          name="productId"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập product id",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Kiểu mua"
          name="purchaseType"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn kiểu mua",
            },
          ]}
        >
          <Select options={iapProductPurchaseTypes} />
        </Form.Item>
        <Form.Item label="Kiểu gói" name="type">
          <Select options={iapProductTypes} />
        </Form.Item>
        <Form.Item label="Số lượng" name="amount">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Số lượng banking" name="bankingAmount">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Giá tiền (USD)" name="definePrice">
          <Select options={iapProductDefinePrices} />
        </Form.Item>
        <Form.Item label="Giá tiền banking (VND)" name="bankingPrice">
          <InputNumber
            min={10000}
            max={1000000}
            style={{
              width: "240px",
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
          />
        </Form.Item>
        <Title level={5}>Khuyến mãi lần đầu</Title>
        <Form.Item label="Số lượng" name={["firstTimeBonus", "amount"]}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Phần trăm x*100 (%)"
          name={["firstTimeBonus", "percent"]}
        >
          <InputNumber min={0} max={1} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
