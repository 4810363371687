import React, { FC } from "react";
import { IResourceComponentsProps, useOne } from "@refinedev/core";
import { DateField, DeleteButton, EditButton, List, TextField, useTable } from "@refinedev/antd";
import { Empty, Grid, Space, Table } from "antd";
import { INotification } from "../../interfaces";

export const NotificationList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<INotification>({});

  const breakpoint = Grid.useBreakpoint();

  return (
    <List title="Thông báo">
      <Table
        {...tableProps}
        rowKey="_id"
        expandable={{
          expandedRowRender: !breakpoint.xs ? expandedRowRender : undefined,
        }}
      >
        <Table.Column
          dataIndex="title"
          title="Tiêu đề"
          key="title"
          render={(value, record) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Ngày tạo"
          key="createdAt"
          render={(value, record) => {
            return <DateField value={value} format={"DD/MM/YYYY - HH:mm"} />;
          }}
        />
        <Table.Column<INotification>
          dataIndex="actions"
          title="Hành động"
          render={(value, record) => {
            return (
              <Space>
                <EditButton recordItemId={record._id} size="small" hideText />
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};

const NotificationContent: FC<{ record: INotification }> = ({ record }) => {
  const { data, isLoading } = useOne<INotification>({
    resource: "notification",
    id: record._id,
  });

  return (
    <div>
      {data == null ? (
        <Empty />
      ) : (
        <div>
          <p>{data!.data.content}</p>
          <h2>Chi tiết</h2>
          <div dangerouslySetInnerHTML={{ __html: data.data.detail }} />
        </div>
      )}
    </div>
  );
};

const expandedRowRender = (record: INotification) => {
  return <NotificationContent record={record} />;
};
