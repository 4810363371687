import React from "react";
import _ from "underscore";
import { Form, Modal, Select } from "antd";
import { useSelect } from "@refinedev/antd";
import { IContributor } from "../../interfaces";
import { useUpdate } from "@refinedev/core";

type ModalTransferContributorProps = {
  bookId: string | null;
  onClosed: (isChanged: boolean) => void;
};

export const ModalTransferContributor: React.FC<
  ModalTransferContributorProps
> = ({ bookId, onClosed }) => {
  const [form] = Form.useForm();
  const { mutate } = useUpdate();

  const { selectProps } = useSelect<IContributor>({
    resource: "contributors",
    dataProviderName: "defaultV2",
    optionLabel: "name",
    optionValue: "_id",
    pagination: {
      pageSize: 20,
    },
    onSearch: (search) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: search,
        },
      ];
    },
  });

  const onFinish = (values: any) => {
    mutate(
      {
        resource: `books/${bookId}/contributor`,
        dataProviderName: "defaultV2",
        id: "",
        values: values,
        successNotification: (data) => {
          return {
            message: "Chuyển thành công",
            description: "Chuyển thành công",
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          onClosed(true);
        },
      }
    );
  };

  return (
    <Modal
      open={!_.isEmpty(bookId)}
      title="Chuyển sang tự phát hành"
      centered
      onCancel={() => onClosed(false)}
      okText="Chuyển"
      cancelText="Huỷ"
      okButtonProps={{
        htmlType: "submit",
        form: "form",
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} id="form">
        <Form.Item
          label="Contributor"
          name="contributorId"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn contributor",
            },
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
