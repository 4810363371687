import React, { useState } from "react";
import { IResourceComponentsProps, useNavigation, useOne } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Checkbox, Col, Form, Input, InputNumber, Row, Select, Space } from "antd";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { IBook, IChapter } from "../../interfaces";
import { audioVoices } from "../../constants";
import ButtonListAudio from "../../components/ButtonListenAudio";

export const AudioChapterCreate: React.FC<IResourceComponentsProps> = () => {
  const { useLocation } = routerProvider;

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [setSelectId, setSetSelectId] = useState<string | null>(
    params.get("bookId")
  );
  const { listUrl, list, push, goBack } = useNavigation();

  const { formProps, saveButtonProps, form } = useForm<IChapter>({
    redirect: false,
    onMutationSuccess: () => {
      goBack();
    },
  });

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 20,

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "audio",
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  const bookDetailResponse = useOne<IBook>({
    resource: "book",
    id: setSelectId!,
    queryOptions: {
      enabled: setSelectId != null,
      onSuccess: (data) => {
        form.setFieldsValue({ number: data.data?.lastChapter + 1 });

        bookSelectProps.options?.push({
          label: data.data?.name,
          value: data.data?._id,
        });
      },
    },
  });

  return (
    <Create title="Tạo Chương Audio" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Truyện"
          name="bookId"
          initialValue={setSelectId}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...bookSelectProps}
            onChange={(type, options) => {
              const value = type.toString();
              setSetSelectId(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Số"
          name="number"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Giá unlock"
          name="price"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Voices"
          name="voices"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox.Group>
            <Row gutter={[16, 16]}>
              {audioVoices.map((voice, index) => (
                <Col span={32} key={index}>
                  <Checkbox value={voice.value}>
                    <Space align="baseline">
                      <p>{voice.label}</p>
                      <ButtonListAudio audioPath={voice.demo} />
                    </Space>
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="Nội dung"
          name="content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea maxLength={5000} showCount />
        </Form.Item>
        <Form.Item name="type" hidden initialValue="audio" />
      </Form>
    </Create>
  );
};
