import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { DateField, DeleteButton, EditButton, List, ShowButton, useTable } from "@refinedev/antd";
import { Card, Space, Table } from "antd";
import PermissionShowing from "../../components/PermissionShowing";
import { IGiftCode } from "../../interfaces";

export const GiftCodeList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IGiftCode>();

  return (
    <PermissionShowing
      enabled={["SUPER"]}
      nonPermission={
        <Card>
          Bạn không có quyền để xem nội dung này, vui lòng liên hệ với Admin của
          site.
        </Card>
      }
    >
      <List title="Gift Code">
        <Table {...tableProps}>
          <Table.Column dataIndex="code" title="Mã" />
          <Table.Column
            dataIndex="createdAt"
            title="Ngày tạo"
            render={(value) => (
              <DateField value={value} format="DD/MM/YYYY - HH:mm" />
            )}
          />
          <Table.Column
            dataIndex="expiredAt"
            title="Ngày hết hạn"
            render={(value) => (
              <DateField value={value} format="DD/MM/YYYY - HH:mm" />
            )}
          />
          <Table.Column dataIndex="maxUse" title="Số lượt dùng" />
          <Table.Column dataIndex="totalUse" title="Đã dùng" />
          <Table.Column<IGiftCode>
            dataIndex="actions"
            title="Hành động"
            render={(value, record) => (
              <Space>
                <ShowButton size="small" hideText recordItemId={record._id} />
                <EditButton size="small" hideText recordItemId={record._id} />
                <DeleteButton size="small" hideText recordItemId={record._id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </PermissionShowing>
  );
};
