import React from "react";
import useSound from "use-sound";
import { SiAudiomack } from "react-icons/si";
import { Button } from "antd";

const ButtonListAudio: React.FC<{ audioPath: string }> = ({ audioPath }) => {
  const [play] = useSound(audioPath, { volume: 0.5, interrupt: true });

  return <Button icon={<SiAudiomack />} size="small" onClick={() => play()} />;
};

export default ButtonListAudio;
