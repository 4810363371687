export const blackListReasonOptions = [
  {
    value: "SPAM",
    label: "Spam",
  },
  {
    value: "ABUSE",
    label: "Chửi bậy",
  },
  {
    value: "HARASSMENT",
    label: "Quấy rối",
  },
  {
    value: "OTHER",
    label: "Lý do khác",
  },
];

export const timeBlackListOptions = [
  {
    value: 0,
    label: "Vĩnh viễn",
  },
  {
    value: 1,
    label: "1 Tiếng",
  },
  {
    value: 3,
    label: "3 Tiếng",
  },
  {
    value: 24,
    label: "1 Ngày",
  },
  {
    value: 24 * 7,
    label: "1 Tuần",
  },
];
