import React from "react";
import { Button, Space, Typography } from "antd";
import { CloseOutlined, PaperClipOutlined } from "@ant-design/icons";
import { useOne } from "@refinedev/core";
import { IBook } from "../../../interfaces";

const { Text } = Typography;

export const AttachmentItem: React.FC<{
  onRemoveAttachment: Function;
  attachment: any;
}> = ({ onRemoveAttachment, attachment }) => {
  const { value, type } = attachment;
  const { data } = useOne<IBook>({
    resource: "book",
    id: value,
  });

  const book = data?.data;

  return (
    <Space style={{ marginTop: "8px" }}>
      <Space>
        <PaperClipOutlined />
        {book && <Text>{book.name}</Text>}
      </Space>
      <Button
        size="small"
        onClick={() => onRemoveAttachment(null)}
        icon={<CloseOutlined />}
      ></Button>
    </Space>
  );
};
