import React, { FC } from "react";
import { IResourceComponentsProps, useList } from "@refinedev/core";
import { List } from "@refinedev/antd";
import * as dayjs from "dayjs";
import { Col, Row, Space } from "antd";
import { DashboardFilter } from "../../components/dashboard/DashboardFilter";
import { IAnalyticsEditorJob } from "../../interfaces";
import { MoneyDashboardCard } from "../../components/dashboard/MoneyDashboardCard";
import CompleteJobsDashboardCard from "../../components/dashboard/CompleteJobasDashboardCard";

export const PartnerAnalyticList: FC<IResourceComponentsProps> = () => {
  const [endDate, setEndDate] = React.useState<Date>(
    new Date(new Date().setHours(23, 59, 59, 999))
  );

  const [startDate, setStartDate] = React.useState<Date>(
    new Date(
      new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
    )
  );

  const [editorId, setEditorId] = React.useState<string | undefined>(undefined);

  const { data: analyticsData } = useList<IAnalyticsEditorJob>({
    resource: "partner-analytics",
    dataProviderName: "defaultV2",
    filters: [
      {
        field: "startDate",
        operator: "eq",
        value: startDate.toISOString(),
      },
      {
        field: "endDate",
        operator: "eq",
        value: endDate.toISOString(),
      },
      {
        field: "editorId",
        operator: "eq",
        value: editorId,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  let analytics = analyticsData?.data ?? [];

  // total days
  const currentDay = dayjs.default(endDate);
  const startDay = dayjs.default(startDate);
  const totalDays = currentDay.diff(startDay, "day");

  return (
    <List>
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: "flex",
        }}
      >
        <Row>
          <Col span={24}>
            <DashboardFilter
              endDate={endDate}
              startDate={startDate}
              onChanged={(start, end, editorId) => {
                setStartDate(start);
                setEndDate(end);
                setEditorId(editorId);
              }}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <MoneyDashboardCard props={analytics} />
          </Col>
          <Col span={8}>
            <CompleteJobsDashboardCard
              props={analytics}
              field="totalReceived"
              title="Job Đã nhận"
            />
          </Col>
          <Col span={8}>
            <CompleteJobsDashboardCard
              props={analytics}
              field="totalDone"
              title="Đã xong"
            />
          </Col>
          <Col span={8}>
            <CompleteJobsDashboardCard
              props={analytics}
              field="totalApproved"
              title="Job Đã duyệt"
            />
          </Col>
        </Row>
      </Space>
    </List>
  );
};
