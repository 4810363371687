import React from "react";
import { Form, Modal, Select } from "antd";
import { useSelect } from "@refinedev/antd";
import { useCreate, useOne } from "@refinedev/core";
import { IEditorJob } from "../../interfaces";

type Props = {
  editorJobId: string | null;
  onClose: Function;
};

export const ModalReplaceChapterContent: React.FC<Props> = ({
  editorJobId,
  onClose,
}) => {
  const { data } = useOne<IEditorJob>({
    resource: "editor-jobs",
    id: editorJobId!,
    dataProviderName: "defaultV2",
    queryOptions: {
      enabled: editorJobId != null,
    },
  });

  const record = data?.data;

  const { selectProps } = useSelect({
    resource: `books/${record?.bookId}/chapters-select`,
    dataProviderName: "defaultV2",
    queryOptions: {
      enabled: record?.bookId != null,
    },
    optionLabel: "label",
    optionValue: "value",
    pagination: {
      mode: "off",
    },
  });

  const { mutate: mutateReplace } = useCreate();

  const submit = (values: any) => {
    console.log(values);
    mutateReplace(
      {
        resource: `editor-jobs/${editorJobId}/replace-chapter`,
        dataProviderName: "defaultV2",
        values: values,
        successNotification: (data) => {
          return {
            message: "Thành công",
            description: "Thay thế chương thành công",
            type: "success",
          };
        },
      },
      {
        onSettled: () => {
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      title="Chọn chương thay thế"
      centered
      open={editorJobId != null}
      okButtonProps={{
        htmlType: "submit",
        form: "form-replace",
      }}
      onCancel={() => onClose()}
    >
      <Form id="form-replace" onFinish={submit}>
        <Form.Item
          name="replaceChapterId"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn chương",
            },
          ]}
        >
          <Select {...selectProps} showSearch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
