import { FC } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, InputNumber, Space, Typography } from "antd";
import { ISeoSite } from "../../interfaces";
import JoditEditor from "jodit-react";

const { Text } = Typography;

export const SeoSiteCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ISeoSite>({
    dataProviderName: "defaultV2",
    resource: "seo-sites",
  });

  return (
    <Create title="Thêm Site SEO" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Keyword"
          name="keyword"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập keyword",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Domain"
          name="domain"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập domain",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Số ngày mỗi lần nhận thưởng"
          name="claimCycleDays"
          initialValue={30}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Thời hạn gift code (phút)"
          name="codeLifeTimeMinutes"
          initialValue={5}
        >
          <InputNumber />
        </Form.Item>

        <Space
          style={{
            display: "flex",
          }}
          direction="vertical"
        >
          <Text>Phần Thưởng</Text>
          <Space>
            <Form.Item label="Xu" name={["codeRewards", "coin"]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label="Điểm" name={["codeRewards", "point"]}>
              <InputNumber min={0} />
            </Form.Item>
          </Space>
        </Space>

        <Form.Item
          label="Hướng dẫn"
          name="tutorial"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập hướng dẫn",
            },
          ]}
        >
          <JoditEditor value={""} />
        </Form.Item>
      </Form>
    </Create>
  );
};
