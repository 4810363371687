import React from "react";
import { Image, Space, Spin, Typography } from "antd";
import { useOne } from "@refinedev/core";
import { IBook } from "../interfaces";
const { Text } = Typography;

type Props = {
  bookId: string;
};

export const BookRow: React.FC<Props> = ({ bookId }) => {
  const { data } = useOne<IBook>({
    id: bookId,
    resource: "book",
    queryOptions: {
      enabled: bookId !== "",
    },
  });

  const record = data?.data;

  return (
    <Space>
      <Image
        src={record?.cover[0].url}
        width={40}
        style={{
          aspectRatio: "1/1.5",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
      <Text>{record?.name}</Text>
    </Space>
  );
};
