import React, { FC, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";

import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Typography,
} from "antd";

import { IGiftCode } from "../../interfaces";
import PermissionShowing from "../../components/PermissionShowing";
import { MdDeleteOutline } from "react-icons/md";
import { makeId } from "../../utils";

const { Title } = Typography;

export const GiftCodeCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IGiftCode>();

  const [state, setState] = useState<boolean>(false);

  const handleAutoGenerate = () => {
    const code = makeId(8);
    formProps?.form?.setFieldsValue({ code });
  };

  const renderAddonData = (index: number) => {
    const rewards = formProps.form?.getFieldValue("rewards");

    if (rewards && rewards.length > 0) {
      if (rewards[index] && rewards[index]["type"] === "ticket") {
        return (
          <>
            <Form.Item
              name={[index, "itemData", "id"]}
              label="Loại vé"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn loại vé",
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: "Đọc truyện",
                    value: "read",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={[index, "itemData", "lifeTime"]}
              label="Thời gian (Giờ)"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thời gian",
                },
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>
          </>
        );
      }
    }

    return <div></div>;
  };

  return (
    <PermissionShowing enabled={["SUPER"]}>
      <Create title="Tạo Gift Code" saveButtonProps={saveButtonProps}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
                message: "Code không được để trống",
                validator: async (_, value) => {
                  if (!value) {
                    return Promise.reject("Code không được để trống");
                  }

                  if (value.length < 6) {
                    return Promise.reject("Code phải có ít nhất 6 ký tự");
                  }

                  if (value.length > 20) {
                    return Promise.reject("Code không được quá 20 ký tự");
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              addonAfter={
                <div
                  onClick={() => handleAutoGenerate()}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Auto
                </div>
              }
            />
          </Form.Item>
          <Form.Item
            label="Số lần dùng"
            name="maxUse"
            rules={[
              {
                required: true,
                message: "Số lần dùng là bắt buộc",
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            label="Ngày hết hạn"
            name="expiredAt"
            rules={[
              {
                required: true,
                message: "Ngày hết hạn là bắt buộc",
              },
            ]}
          >
            <DatePicker picker="date" showToday showTime />
          </Form.Item>

          <Title level={5}>Phần thưởng</Title>
          <Form.List
            name={"rewards"}
            rules={[
              {
                validator: async (_, rewards) => {
                  if (!rewards || rewards.length === 0) {
                    return Promise.reject(
                      new Error("Phần thưởng không được để trống")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, validate) => (
              <Space direction="vertical" style={{ display: "flex" }}>
                {fields.map((field, index) => (
                  <div key={index}>
                    <Card
                      extra={
                        <Button
                          danger
                          size="small"
                          shape="circle"
                          icon={<MdDeleteOutline />}
                          onClick={() => remove(index)}
                        ></Button>
                      }
                    >
                      <Space>
                        <Form.Item
                          label="Nhóm"
                          name={[index, "type"]}
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng chọn nhóm",
                            },
                          ]}
                        >
                          <Select
                            style={{ minWidth: "240px" }}
                            options={[
                              {
                                label: "Xu",
                                value: "coin",
                              },
                              {
                                label: "Điểm",
                                value: "point",
                              },
                              {
                                label: "Vé",
                                value: "ticket",
                              },
                            ]}
                            onChange={() => setState(!state)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Số lượng"
                          initialValue={1}
                          name={[index, "amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập số lượng",
                            },
                          ]}
                        >
                          <InputNumber min={1} />
                        </Form.Item>
                      </Space>
                      {renderAddonData(index)}
                    </Card>
                  </div>
                ))}

                <div style={{ color: "red" }}>{validate.errors}</div>

                <div>
                  <Button onClick={() => add()} block type="dashed">
                    Thêm phần thưởng
                  </Button>
                </div>
              </Space>
            )}
          </Form.List>
        </Form>
      </Create>
    </PermissionShowing>
  );
};
