import React from "react";
import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export const ButtonDeleteMessage: React.FC<{
  deleteOne: Function;
  deleteAll: Function;
}> = ({ deleteOne, deleteAll }) => {
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const { key } = e;

    switch (key) {
      case "1":
        deleteOne();
        break;

      case "2":
        deleteAll();
        break;
    }
  };

  const items: MenuProps["items"] = [
    { label: "Tin nhắn này", key: "1" },
    { label: "Tất cả tin nhắn user", key: "2" },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Tooltip title="Xoá">
      <Dropdown menu={menuProps}>
        <Button size="small" type="text" icon={<DeleteOutlined />} />
      </Dropdown>
    </Tooltip>
  );
};
