import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { List } from "@refinedev/antd";
import CategoryTreeList from "../../components/category/CategoryTreeList";

export const ComicCategoryList: React.FC<IResourceComponentsProps> = () => {
  return (
    <List title="Danh sách thể loại">
      <CategoryTreeList bookTypes="comic" />
    </List>
  );
};
