import { Button, Dropdown, MenuProps, message, Modal, Space } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { FiEye } from "react-icons/fi";
import { TiTick } from "react-icons/ti";
import { AiOutlineDelete } from "react-icons/ai";
import { ModalCompleteContributorInvoice } from "./ModalCompleteContributorInvoice";
import React, { useState } from "react";
import { useDelete, useNavigation } from "@refinedev/core";

type ContributorInvoiceActionsProps = {
  id: string;
  refresh: () => void;
  status: string;
};

export const ContributorInvoiceActions: React.FC<
  ContributorInvoiceActionsProps
> = ({ id, refresh, status }) => {
  const [completeId, setCompleteId] = useState<string | null>(null);
  const nav = useNavigation();
  const { mutateAsync: mutateDelete } = useDelete({});

  const handleDelete = async () => {
    try {
      await mutateDelete({
        resource: "contributor-invoices",
        id,
        dataProviderName: "defaultV2",
        successNotification: false,
        errorNotification: false,
      });
      message.success("Xoá thành công");
      refresh();
    } catch (err) {
      message.error("Xoá thất bại");
    }
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    switch (e.key) {
      case "complete":
        setCompleteId(id);
        break;

      case "detail":
        nav.show("contributor-invoices", id);
        break;

      case "delete":
        Modal.confirm({
          title: "Bạn có chắc chắn muốn xoá?",
          content: "Hành động này không thể hoàn tác",
          centered: true,
          onOk: async () => await handleDelete(),
        });
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Chi tiết",
      key: "detail",
      icon: <FiEye />,
    },
    {
      label: "Hoàn thành",
      key: "complete",
      icon: <TiTick />,
      disabled: status === "PAID",
    },
    {
      label: "Xoá",
      key: "delete",
      icon: <AiOutlineDelete />,
      danger: true,
      disabled: status === "PAID",
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            Hành động
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <ModalCompleteContributorInvoice
        invoiceId={completeId}
        onClose={() => {
          setCompleteId(null);
          refresh();
        }}
      />
    </>
  );
};
