import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { IBook } from "../../interfaces";
import { BookEditForm } from "../../components/books/BookEditForm";

export const ComicEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IBook>({});

  return (
    <Edit title="Chỉnh sửa truyện" saveButtonProps={saveButtonProps}>
      <BookEditForm
        type={"comic"}
        formProps={formProps}
        queryResult={queryResult}
      />
    </Edit>
  );
};
