import React from "react";
import { Edit, getValueFromEvent, List, useForm } from "@refinedev/antd";
import { Form, Upload, Typography, Card, Button, Space } from "antd";
import { IResourceComponentsProps, useOne, useUpdate } from "@refinedev/core";
import { SaveOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const AppConfigList: React.FC<IResourceComponentsProps> = () => {
  const { isLoading, data } = useOne({
    resource: "app-configs",
    id: "",
    dataProviderName: "defaultV2",
  });

  const { mutate, isLoading: isLoadingMutate } = useUpdate({});

  const [form] = Form.useForm();
  const record = data?.data;

  if (record) {
    form.resetFields();
    form.setFieldsValue({
      bannerUnderChapter: record.bannerUnderChapter,
    });
  }

  const handleSave = async (values: any) => {
    mutate({
      resource: "app-configs",
      id: "",
      dataProviderName: "defaultV2",
      values,
      successNotification: () => {
        return {
          message: "Đã lưu",
          description: "Cài đặt đã được lưu",
          type: "success",
        };
      },
    });
  };

  return (
    <List title="Cài đặt chung">
      <Card
        loading={isLoading}
        actions={[
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 24px",
            }}
          >
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="form-app-config"
              loading={isLoadingMutate}
            >
              Lưu
            </Button>
          </Space>,
        ]}
      >
        <Form
          id="form-app-config"
          layout="vertical"
          form={form}
          onFinish={handleSave}
        >
          <Form.Item
            label="Banner dưới chương"
            name="bannerUnderChapter"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
          >
            <Upload.Dragger
              name="images"
              listType="picture"
              action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
              maxCount={1}
              multiple={false}
              accept=".png,.jpeg,.jpg,.webp"
            >
              <Text>Drag & drop image to upload</Text>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Card>
    </List>
  );
};
