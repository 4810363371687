import React, { useState } from "react";

import {
  DateField,
  EditButton,
  FilterDropdown,
  getDefaultSortOrder,
  ImageField,
  ShowButton,
  TagField,
  TextField,
  useModalForm,
  useSelect,
} from "@refinedev/antd";

import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Modal,
  notification,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tooltip,
} from "antd";

import { IBook, ICategory } from "../../interfaces";
import { getColorStatus, thousandFormat } from "../../utils";
import { AiOutlineNotification } from "react-icons/ai";
import {
  CrudSorting,
  useCreate,
  useNavigation,
  useUpdate,
} from "@refinedev/core";
import { OrderedListOutlined } from "@ant-design/icons";
import { NovelDropdownButtonActions } from "./NovelDropdownButtonActions";
import { useNotifyNewChapters } from "../../hooks/client_notification_hooks";
import _ from "underscore";
import { ModalTransferContributor } from "../modal/ModalTransferContributor";
import { ContributorInfo } from "../contributor/ContributorInfo";

export const BookTable: React.FC<{
  tableProps: TableProps<IBook>;
  sorter: CrudSorting;
  renderActions?: (item: IBook) => React.ReactNode;
  refreshTable?: () => void;
  hiddenCols?: [string];
}> = ({
  tableProps,
  sorter,
  hiddenCols = [""],
  renderActions,
  refreshTable,
}) => {
  const mutationResult = useUpdate<IBook>();
  const { mutate, isLoading: mutateIsLoading } = mutationResult;
  const nav = useNavigation();
  const { notify } = useNotifyNewChapters();
  const [transferContributorId, setTransferContributorId] = useState<
    string | null
  >(null);

  const handleUpdatePublished = (item: IBook, status: boolean) => {
    mutate({
      resource: "book",
      id: item.slugId,
      values: { ...item, published: status },
    });
  };

  const handleUpdateFeature = (item: IBook, feature: boolean) => {
    mutate({
      resource: "book",
      id: item.slugId,
      values: { ...item, feature: feature },
    });
  };

  const handleUpdateIsSafe = (item: IBook, isSafe: boolean) => {
    mutate({
      resource: "book",
      id: item.slugId,
      values: { ...item, isSafe },
    });
  };

  const { selectProps: categoriesSelectProps } = useSelect<ICategory>({
    resource: "category",
    fetchSize: 10,
    optionLabel: "name",
    optionValue: "_id",

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: tagsSelectProps } = useSelect({
    resource: "tag",
    fetchSize: 10,
    optionLabel: "value",
    optionValue: "_id",

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server",
    },
  });

  const handleShowChapters = (id: string, bookType: string) => {
    switch (bookType) {
      case "comic":
        nav.push(`/comic-management/chapter?bookId=${id}`);
        break;

      case "novel":
        nav.push(`/book-management/chapter?bookId=${id}`);
        break;

      case "audio":
        nav.push(`/audio-management/chapter?bookId=${id}`);
        break;

      default:
        break;
    }
  };

  const renderAction = (value: any, record: IBook) => {
    return (
      <Space>
        <Tooltip title="Danh sách chương">
          <Button
            icon={<OrderedListOutlined />}
            size="small"
            onClick={() =>
              handleShowChapters(record._id, record.type ?? "novel")
            }
          />
        </Tooltip>
        <EditButton recordItemId={record.slugId} size="small" hideText />
        <Tooltip title="Gửi thông báo chương mới đến người đọc">
          <ShowButton
            recordItemId={record._id}
            onClick={() => notify(record._id)}
            size="small"
            hideText
            icon={<AiOutlineNotification color="orange" />}
            style={{ borderColor: "orange" }}
          />
        </Tooltip>
      </Space>
    );
  };

  const renderNovelActions = (value: any, record: IBook) => {
    return (
      <NovelDropdownButtonActions
        data={record}
        onTransferToContributor={() => handleTransferToContributor(record._id)}
        refreshTable={refreshTable}
      />
    );
  };

  const handleTransferToContributor = (id: string) => {
    setTransferContributorId(id);
  };

  //*** Tree Categories Hook */
  // const { treeData, loadChild } = useTreeList();

  return (
    <>
      <Table {...tableProps} rowKey="_id">
        {/*<Table.Column<IBook>*/}
        {/*  dataIndex="published"*/}
        {/*  title="Phát hành"*/}
        {/*  key="published"*/}
        {/*  fixed="left"*/}
        {/*  render={(value, record) => {*/}
        {/*    return (*/}
        {/*      <Switch*/}
        {/*        defaultChecked={value}*/}
        {/*        onChange={(value) => {*/}
        {/*          handleUpdatePublished(record, value);*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}
        {!hiddenCols.includes("feature") && (
          <Table.Column<IBook>
            dataIndex="feature"
            title="Đề cử"
            key="feature"
            fixed="left"
            render={(value, record) => {
              return (
                <Tooltip title="Đề cử truyện lên đầu trang web/ app">
                  <Checkbox
                    defaultChecked={value}
                    onChange={(value) => {
                      handleUpdateFeature(record, value.target.checked);
                    }}
                  />
                </Tooltip>
              );
            }}
          />
        )}
        <Table.Column<IBook>
          dataIndex="isSafe"
          title="An toàn"
          key="isSafe"
          fixed="left"
          render={(value, record) => {
            return (
              <Tooltip title="An toàn cho mọi lứa tuổi">
                <Checkbox
                  defaultChecked={value}
                  onChange={(value) => {
                    handleUpdateIsSafe(record, value.target.checked);
                  }}
                />
              </Tooltip>
            );
          }}
        />
        <Table.Column
          dataIndex="cover"
          title="Ảnh bìa"
          key="cover"
          fixed="left"
          render={(value) => {
            return (
              <ImageField
                value={value[0].url}
                style={{
                  maxWidth: "60px",
                  aspectRatio: "3/4",
                  objectFit: "cover",
                  // minWidth: "80px",
                  borderRadius: "8px",
                }}
              />
            );
          }}
        />
        <Table.Column<IBook>
          dataIndex="name"
          title="Tên"
          key="name"
          fixed="left"
          sorter
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          render={(value, record) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column<IBook>
          dataIndex="contributorId"
          title="Người đăng"
          key="contributorId"
          render={(value, record) => {
            if (_.isEmpty(record.contributorId)) {
              return <TextField value="App Truyện" />;
            }

            return <ContributorInfo id={record.contributorId} />;
          }}
        />
        <Table.Column
          dataIndex="categories"
          title="Danh mục"
          key="categories"
          render={(value, record) => {
            return value.map((item: ICategory) => (
              <TagField value={item.name} />
            ));
          }}
          filterDropdown={(props) => (
            <FilterDropdown
              {...props}
              mapValue={(selectedKeys) => selectedKeys.map(String)}
            >
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Chọn danh mục"
                {...categoriesSelectProps}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="tags"
          title="Tags"
          render={(value) => {
            return value.map((item: string) => (
              <TagField value={item} color="green" />
            ));
          }}
          filterDropdown={(props) => (
            <FilterDropdown
              {...props}
              mapValue={(selectedKeys) => selectedKeys.map(String)}
            >
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Chọn thẻ"
                {...tagsSelectProps}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="status"
          title="Trạng thái"
          render={(value) => {
            return <TagField value={value} color={getColorStatus(value)} />;
          }}
          filterDropdown={(props) => (
            <FilterDropdown
              {...props}
              mapValue={(selectedKeys) => selectedKeys.map(String)}
            >
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Chọn trạng thái"
                options={[
                  {
                    value: "UPDATING",
                    name: "UPDATING",
                  },
                  {
                    value: "COMPLETED",
                    name: "COMPLETED",
                  },
                ]}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Ngày tạo"
          sorter
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          render={(value) => {
            return <DateField value={value} format={"DD/MM/YYYY - HH:mm"} />;
          }}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Cập nhật"
          sorter
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          render={(value) => {
            return <DateField value={value} format={"DD/MM/YYYY - HH:mm"} />;
          }}
        />
        <Table.Column
          dataIndex="lastChapter"
          title="Tổng số chap"
          sorter
          defaultSortOrder={getDefaultSortOrder("totalChap", sorter)}
          render={(value) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="totalViews"
          title="Lượt xem"
          sorter
          defaultSortOrder={getDefaultSortOrder("totalViews", sorter)}
          render={(value) => {
            return <TextField value={thousandFormat(value) ?? "__"} />;
          }}
        />
        <Table.Column
          dataIndex="rate"
          title="Rating"
          sorter
          defaultSortOrder={getDefaultSortOrder("rate", sorter)}
          render={(value) => {
            if (!value) return <TextField value="__" />;

            return <TextField value={value.avg.toFixed(1)} />;
          }}
        />
        <Table.Column<IBook>
          dataIndex="actions"
          title="Hành động"
          render={(value, record) => {
            if (renderActions != null) return renderActions(record);

            if (
              record.type === "novel" ||
              record.type == null ||
              record.type === "audio" ||
              record.type === "comic"
            ) {
              return renderNovelActions(value, record);
            }

            return renderAction(value, record);
          }}
        />
      </Table>
      <ModalTransferContributor
        onClosed={(isChanged) => {
          setTransferContributorId(null);

          if (isChanged && refreshTable != null) {
            refreshTable();
          }
        }}
        bookId={transferContributorId}
      />
    </>
  );
};
