import { List } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Tabs, TabsProps } from "antd";

import React from "react";
import EditorJobsReview from "components/editor-jobs/EditorJobsReview";
import EditorJobsTable from "components/editor-jobs/EditorJobsTable";

export const EditJobsList: React.FC<IResourceComponentsProps> = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Danh sách`,
      children: <EditorJobsTable />,
    },
    {
      key: "2",
      label: `Kiểm duyệt`,
      children: <EditorJobsReview />,
    },
  ];

  return (
    <List title="Editor Jobs">
      <Tabs items={items} />
    </List>
  );
};
