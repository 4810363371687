import { useEffect, useState } from "react";
import { useCreate } from "@refinedev/core";
import { notification } from "antd";

export const useSelectedBook = (id: string | null) => {
  const [bookId, setBookId] = useState<string | null>(null);

  useEffect(() => {
    if (id != null) setBookId(id);
  }, [id]);

  return {
    bookId,
  };
};

export const useCreateAudioFromNovel = () => {
  const mutationNotification = useCreate();
  const { mutate, isLoading } = mutationNotification;

  const createAudio = (id: string) => {
    mutate(
      {
        resource: `book/${id}/audio-from-novel`,
        values: {},
        successNotification: false,
        errorNotification: false,
      },
      {
        onError: (error, variables, context) => {
          // An error happened!
          notification.error({ message: "Đã có lỗi xảy ra" }); //
        },
        onSuccess: (data, variables, context) => {
          notification.success({ message: "Tạo audio thành công!" }); // Let's celebrate!
        },
      }
    );
  };

  return {
    isLoading,
    createAudio,
  };
};
