import { DownOutlined } from "@ant-design/icons";
import { useCreate, useDelete, useUpdate } from "@refinedev/core";
import { Button, Dropdown, MenuProps, Modal, Space } from "antd";
import { IReceipt, IWheelMoneyReceipt } from "interfaces";
import { AiOutlineDelete } from "react-icons/ai";
import { TiTick } from "react-icons/ti";

type WheelMoneyReceiptActionsProps = {
  receipt: IWheelMoneyReceipt;
  onRefresh: () => void;
};

export const WheelMoneyReceiptActions = ({
  receipt,
  onRefresh,
}: WheelMoneyReceiptActionsProps) => {
  const { status } = receipt;
  const { mutateAsync } = useCreate({});
  const { mutateAsync: mutateDelete } = useDelete({});

  const handleComplete = async () => {
    await mutateAsync({
      resource: `wheel-money-receipts/${receipt._id}/confirm-pay`,
      dataProviderName: "defaultV2",
      values: {},
      successNotification(data, values, resource) {
        return {
          message: "Thành công",
          description: "Đã hoàn thành",
          type: "success",
        };
      },
    });

    onRefresh();
  };

  const handleDelete = async () => {
    try {
      await mutateDelete({
        resource: "wheel-money-receipts",
        id: receipt._id,
        dataProviderName: "defaultV2",
        successNotification(data, values, resource) {
          return {
            message: "Thành công",
            description: "Đã xoá",
            type: "success",
          };
        },
      });

      onRefresh();
    } catch (err) {}
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    switch (e.key) {
      case "complete":
        handleComplete();
        break;
      case "delete":
        Modal.confirm({
          title: "Bạn có chắc chắn muốn xoá?",
          content: "Hành động này không thể hoàn tác",
          centered: true,
          onOk: async () => await handleDelete(),
        });
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Hoàn thành",
      key: "complete",
      icon: <TiTick />,
      disabled: status === "done",
    },
    {
      label: "Xoá",
      key: "delete",
      icon: <AiOutlineDelete />,
      danger: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            Hành động
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </>
  );
};
