import { IResourceComponentsProps, useGetIdentity } from "@refinedev/core";
import React, { FC } from "react";
import { DeleteButton, EditButton, ImageField, List, TagField, TextField, useTable } from "@refinedev/antd";
import { Avatar, Row, Space, Table } from "antd";
import { IAccount } from "../../interfaces";
import { getColorsAccountRole } from "../../utils";
import AuthLocalDetect from "../../components/AuthLocalDetect";
import PermissionShowing from "../../components/PermissionShowing";

export const AccountList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IAccount>({});
  const { data: accountInfo } = useGetIdentity<IAccount>({
    v3LegacyAuthProviderCompatible: true
  });

  return (
    <List
      title={"Tài khoản quản trị"}
      canCreate={accountInfo?.role === "SUPER"}
    >
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="avatarUrl"
          title="Avatar"
          key="avatarUrl"
          fixed="left"
          render={(value) => {
            return (
              <Avatar
                size={{ xs: 24, sm: 32, md: 40, lg: 40, xl: 40, xxl: 40 }}
                icon={<ImageField value={value[0].url} />}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="username"
          title="Username"
          key="username"
          fixed="left"
          render={(value) => {
            return (
              <Row>
                <TextField value={value} style={{ paddingRight: "2px" }} />
                <AuthLocalDetect isShow={true} username={value}>
                  <TagField
                    value={"YOU"}
                    color={"green"}
                    style={{ marginLeft: "2px" }}
                  />
                </AuthLocalDetect>
              </Row>
            );
          }}
        />
        <Table.Column
          dataIndex="fullName"
          title="Tên"
          key="fullName"
          render={(value) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="role"
          title="Chức vụ"
          key="role"
          render={(value) => {
            return (
              <TagField value={value} color={getColorsAccountRole(value)} />
            );
          }}
        />
        {accountInfo?.role === "SUPER" && (
          <Table.Column<IAccount>
            dataIndex="actions"
            title="Hành động"
            render={(value, record) => {
              return (
                <Space>
                  <PermissionShowing enabled={["SUPER"]}>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record._id}
                    />
                  </PermissionShowing>

                  <AuthLocalDetect isShow={false} username={record.username}>
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record._id}
                    />
                  </AuthLocalDetect>
                </Space>
              );
            }}
          />
        )}
      </Table>
    </List>
  );
};
