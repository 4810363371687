import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { DateField, List, TagField, useTable } from "@refinedev/antd";
import { Avatar, Space, Table, Typography } from "antd";
import { IContributor } from "../../interfaces";
import { contributorStatus } from "../../constants";
import ContributorTableActions from "../../components/contributor/ContributorTableActions";

const { Text, Title } = Typography;

export const ContributorList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult } = useTable<IContributor>({
    dataProviderName: "defaultV2",
  });

  return (
    <List title="Người đóng góp">
      <Table {...tableProps} rowKey="_id">
        <Table.Column<IContributor>
          dataIndex="name"
          title="Tên"
          render={(value, record) => {
            return (
              <Space>
                <Avatar src={record.avatar} />
                <Text>{record.name}</Text>
              </Space>
            );
          }}
        />
        <Table.Column dataIndex="email" title="Email" />
        <Table.Column
          dataIndex="createdAt"
          title="Ngày tạo"
          render={(value) => {
            return <DateField value={value} format="DD/MM/YYYY - HH:mm" />;
          }}
        />
        <Table.Column
          dataIndex="status"
          title="Trạng thái"
          render={(value) => {
            const status = contributorStatus.find((x) => x.value === value);
            return <TagField value={status?.label} color={status?.color} />;
          }}
        />
        <Table.Column<IContributor>
          dataIndex="actions"
          title="Hành động"
          render={(value, record, index) => {
            return <ContributorTableActions />;
          }}
        />
      </Table>
    </List>
  );
};
