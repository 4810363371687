import React from "react";
import { useSelect } from "@refinedev/antd";
import { Avatar, Button, Card, Form, Select, Space } from "antd";
import { IUser } from "../../interfaces";

export const AccountSelectCard: React.FC<{
  onSelectedUserId: Function;
}> = ({ onSelectedUserId }) => {
  const [selectOptions, setSelectOptions] = React.useState<any>([]);

  const { selectProps } = useSelect<IUser>({
    resource: "user",
    optionValue: "_id",
    optionLabel: "username",

    queryOptions: {
      onSuccess: (data) => {
        const options = data.data.map((user: IUser) => ({
          label: (
            <Space>
              <Avatar src={user.avatarUrl} />
              <span>
                {user.username} ({user.email})
              </span>
            </Space>
          ),
          value: user._id,
          label2: user.username,
        }));
        setSelectOptions(options);
      },
    },

    onSearch: (value) => [
      {
        field: "search",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  return (
    <Card>
      <Form
        layout="vertical"
        name="basic"
        onFinish={(data) => {
          onSelectedUserId(data.userId);
        }}
      >
        <Space
          direction="vertical"
          align="center"
          style={{
            width: "100%",
          }}
        >
          <h4>Chọn 1 tài khoản người dùng để tiếp tục</h4>
          <Form.Item
            label="Tài khoản"
            name="userId"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn 1 tài khoản người dùng!",
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
                minWidth: "320px",
              }}
              {...selectProps}
              options={selectOptions}
              optionLabelProp={"label2"}
            />
          </Form.Item>

          <Button
            htmlType="submit"
            style={{
              width: "100%",
              minWidth: "320px",
            }}
            type="primary"
          >
            Tiếp tục
          </Button>
        </Space>
      </Form>
    </Card>
  );
};
