import React from "react";
import { Breadcrumb, Create, useForm } from "@refinedev/antd";
import { TreeSelect, Typography } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { BookCreateForm } from "../../components/books/BookCreateForm";
import { IBook } from "../../interfaces";

const { Text } = Typography;
const { SHOW_PARENT } = TreeSelect;

export const ComicBookCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IBook>({});

  return (
    <Create
      title="Thêm truyện"
      saveButtonProps={saveButtonProps}
      headerProps={{ breadcrumb: <Breadcrumb hideIcons /> }}
    >
      <BookCreateForm type={"comic"} formProps={formProps} />
    </Create>
  );
};
