import {
  Create,
  SaveButton,
  useStepsForm,
  useSelect,
  useTable,
} from "@refinedev/antd";
import {
  Button,
  Form,
  Select,
  Steps,
  Table,
  Typography,
  Input,
  Spin,
} from "antd";
import { IResourceComponentsProps, useOne } from "@refinedev/core";
import { IBook, IBookVariantDetail, IChapter } from "interfaces";
import JoditEditor from "jodit-react";
import React from "react";
import { getBookTypeLabel } from "utils";

const { Title } = Typography;

export const AudioTaskCreate: React.FC<IResourceComponentsProps> = () => {
  const [bookGroupOptions, setBookGroupOptions] = React.useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const { saveButtonProps, stepsProps, formProps, current, gotoStep, submit } =
    useStepsForm({
      dataProviderName: "defaultV2",
      redirect: false,
      onMutationSuccess: () => {
        gotoStep(1);
      },
    });

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "novel",
      },
    ],

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    queryOptions: {
      onSuccess(data) {
        // group book by type
        const groupByType = data.data?.reduce((acc: any, book) => {
          const type = book.type ?? "novel";

          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push(book);
          return acc;
        }, {});

        // convert to options
        const options = Object.keys(groupByType ?? {}).map((key) => {
          return {
            label: getBookTypeLabel(key),
            options: groupByType[key].map((book: IBook) => ({
              label: book.name,
              value: book._id,
            })),
          };
        });

        setBookGroupOptions(options as []);
      },
    },

    pagination: {
      mode: "server",
    },
  });

  const { data: variantsRes } = useOne<IBookVariantDetail>({
    resource: "books",
    dataProviderName: "defaultV2",
    id: `${formProps.form?.getFieldValue("refBookId")}/variants`,
    queryOptions: {
      enabled:
        current === 2 && formProps.form?.getFieldValue("refBookId") != null,
      onSuccess(data) {
        formProps.form?.setFieldsValue({
          bookId: data.data?.audio?._id,
        });
      },
    },
  });

  const { selectProps: audioSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "audio",
      },
    ],

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    defaultValue: variantsRes?.data.audio?._id,

    queryOptions: {
      enabled: current === 2 && variantsRes != null,
    },

    pagination: {
      mode: "server",
    },
  });

  const { isLoading: isLoadingChapterDetail } = useOne<IChapter>({
    resource: "chapter",
    id: selectedRowKeys[0] as string,
    queryOptions: {
      enabled: current === 2,
      onSuccess(data) {
        let content = data.data?.content ?? "";
        content = content.split("\n\n").join("<br />");
        const bannedWords = [
          "https://",
          "https",
          "donate",
          "đọc truyện",
          "ủng hộ",
          "Shopee",
          "Lazada",
          "Tiki",
          "Sendo",
          "nettruyen",
          "truyenfull",
          "Advertisement",
        ];

        // check content includes banned words and mark them in
        const markedContent = bannedWords.reduce((acc, word) => {
          const regex = new RegExp(word, "gi");
          return acc.replace(
            regex,
            `<span style="background-color: red; color: white; padding-right: 2px; padding-left: 2px; font-weight: bold">${word}</span>${" "}`
          );
        }, content);

        formProps.form?.setFieldsValue({
          chapterName: data.data?.name,
          content: markedContent,
          number: data.data?.number,
        });
      },
    },
  });

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "ratio",
  };

  const { tableProps } = useTable<IChapter>({
    resource: "chapter",

    queryOptions: {
      enabled: current === 1,
    },

    filters: {
      permanent: [
        {
          field: "bookId",
          operator: "eq",
          value: formProps.form?.getFieldValue("refBookId"),
        },
      ],
    },
  });

  const handleNextStep = () => {
    if (current === 0) {
      setSelectedRowKeys([]);
    }

    gotoStep(current + 1);
  };

  const formList = [
    <>
      <Form.Item
        name="refBookId"
        label="Chọn tiểu thuyết"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn truyện",
          },
        ]}
      >
        <Select {...bookSelectProps} options={bookGroupOptions} />
      </Form.Item>
    </>,
    <>
      <Title level={5}>Chọn chương để lấy nội dung</Title>
      <Table
        {...tableProps}
        rowKey="_id"
        rowSelection={{
          ...rowSelection,
          type: "radio",
        }}
      >
        <Table.Column title="STT" dataIndex="number" />
        <Table.Column title="Tên chương" dataIndex="name" />
      </Table>
    </>,
    <>
      <Form.Item
        label="Chọn truyện Audio"
        name="bookId"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn truyện audio",
          },
        ]}
      >
        <Select
          {...audioSelectProps}
          disabled={variantsRes?.data.audio?._id != null}
        />
      </Form.Item>
      <Form.Item label="Tên chương" name="chapterName">
        <Input />
      </Form.Item>
      <Form.Item label="STT" name="number">
        <Input disabled />
      </Form.Item>
      <p
        style={{
          color: "red",
        }}
      >
        *Lưu ý những từ bôi đỏ
      </p>
      <Form.Item label="Nội dung" name="content">
        <JoditEditor
          value={""}
          config={{
            height: 500,
          }}
        />
      </Form.Item>
    </>,
  ];

  const isDisabled = current === 1 && selectedRowKeys.length === 0;

  return (
    <Create
      saveButtonProps={saveButtonProps}
      footerButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Quay lại
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button onClick={handleNextStep} disabled={isDisabled}>
              Tiếp
            </Button>
          )}
          {current === formList.length - 1 && (
            <SaveButton
              {...saveButtonProps}
              style={{ marginRight: 10 }}
              onClick={() => submit()}
            >
              Hoàn thành
            </SaveButton>
          )}
        </>
      }
    >
      <Steps {...stepsProps}>
        <Steps.Step title="Chọn truyện" />
        <Steps.Step title="Chọn chương" />
        <Steps.Step title="Nội dung" />
      </Steps>
      <Form
        {...formProps}
        layout="vertical"
        style={{
          marginTop: 20,
        }}
      >
        {current === 2 && isLoadingChapterDetail ? <Spin /> : formList[current]}
      </Form>
    </Create>
  );
};
