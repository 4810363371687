import React from "react";
import { CrudFilters, IResourceComponentsProps } from "@refinedev/core";
import { BookTable } from "../../components/books/BookTable";
import { Breadcrumb, List, useEditableTable } from "@refinedev/antd";
import { IBook } from "../../interfaces";
import { BookFiltering } from "../../components/books/BookFiltering";

export const ComicList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, searchFormProps, tableQueryResult } =
    useEditableTable<IBook>({
      initialSorter: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
      initialPageSize: 5,
      permanentFilter: [
        {
          field: "type",
          operator: "eq",
          value: "comic",
        },
      ],
      syncWithLocation: true,
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { q, createdAt, feature, category, hasLockChapters } = params;

        filters.push(
          {
            field: "q",
            operator: "eq",
            value: q,
          },
          {
            field: "createdAt",
            operator: "gte",
            value: createdAt ? createdAt[0].toISOString() : undefined,
          },
          {
            field: "createdAt",
            operator: "lte",
            value: createdAt ? createdAt[1].toISOString() : undefined,
          },
          {
            field: "feature",
            operator: "eq",
            value: feature,
          },
          {
            field: "hasLockChapters",
            operator: "eq",
            value: hasLockChapters,
          },
          {
            field: "category",
            operator: "in",
            value: category,
          }
        );

        return filters;
      },
    });

  return (
    <>
      <BookFiltering searchFormProps={searchFormProps} bookTypes="comic" />
      <List
        title="Danh sách truyện"
        headerProps={{ breadcrumb: <Breadcrumb hideIcons /> }}
      >
        <BookTable
          tableProps={tableProps}
          sorter={sorter!}
          refreshTable={() => tableQueryResult.refetch()}
        />
      </List>
    </>
  );
};
