import React, { useState } from "react";
import { useSelect } from "@refinedev/antd";
import { Button, Form, Input, Popover, Select, Space } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { IBook } from "../../../interfaces";

interface IGroupBookOption {
  label: string;
  options: [
    {
      label: string;
      value: string;
    }
  ];
}

const bookTypeMap = [
  {
    label: "Truyện tranh",
    value: "comic",
  },
  {
    label: "Truyện chữ",
    value: "novel",
  },
  {
    label: "Truyện audio",
    value: "audio",
  },
];

const getValueOfLabelType = (label: string) => {
  const type = bookTypeMap.find((item) => item.label === label);
  return type?.value ?? "novel";
};

const getLabelOfType = (value: string) => {
  const type = bookTypeMap.find((item) => item.value === value);
  return type?.label ?? "Truyện chữ";
};

export const AttachmentPicker: React.FC<{ onAttachment: Function }> = ({
  onAttachment,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const [selectOptions, setSelectOptions] = React.useState<IGroupBookOption[]>(
    []
  );

  const { selectProps } = useSelect<IBook>({
    resource: "book",
    pagination: {
      pageSize: 40,
    },
    queryOptions: {
      onSuccess: (data) => {
        const options = data.data.reduce((acc, item) => {
          const group = acc.find(
            (g) => getValueOfLabelType(g.label) === item.type
          );
          if (group) {
            group.options.push({
              label: item.name ?? "",
              value: item._id,
            });
          } else {
            acc.push({
              label: getLabelOfType(item.type ?? "novel") ?? "Truyện chữ",
              options: [
                {
                  label: item.name ?? "",
                  value: item._id,
                },
              ],
            });
          }
          return acc;
        }, [] as IGroupBookOption[]);
        setSelectOptions(options);
      },
    },
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "all",
      },
    ],
  });

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const renderContent = () => {
    return (
      <div
        style={{
          width: "400px",
        }}
      >
        <Form
          layout="vertical"
          onFinish={(values) => {
            onAttachment(values);
            hide();
          }}
        >
          <Form.Item name="type" initialValue="book" hidden />
          <Form.Item name="value">
            <Select
              {...selectProps}
              style={{
                width: "100%",
              }}
              placeholder="Chọn truyện"
              options={selectOptions}
            />
          </Form.Item>

          <Button
            block
            type="primary"
            icon={<PaperClipOutlined />}
            htmlType="submit"
          >
            Đính kèm
          </Button>
        </Form>
      </div>
    );
  };

  return (
    <Popover
      placement="bottomLeft"
      title="Truyện"
      content={renderContent()}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button icon={<PaperClipOutlined />} />
    </Popover>
  );
};
