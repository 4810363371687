import React from "react";
import { IResourceComponentsProps, useCreate, useOne } from "@refinedev/core";
import {
  DateField,
  DeleteButton,
  List,
  TagField,
  useTable,
} from "@refinedev/antd";
import { Button, Popover, Space, Table } from "antd";
import { MdFileDownloadDone } from "react-icons/md";
import { IChapterReportCompass, IUser } from "../../interfaces";
import { UserInformation } from "../../components/UserInformation";
import { ChapterInformation } from "../../components/ChapterInformation";
import {
  chapterReportIssueOptions,
  chapterReportStatusOptions,
} from "../../constants";

export const ChapterReportList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult } = useTable<IChapterReportCompass>({
    dataProviderName: "defaultV2",
    resource: "chapter-reports",
  });

  const { mutate } = useCreate();

  const handleResolve = async (id: string) => {
    mutate(
      {
        resource: `chapter-reports/${id}/resolve`,
        dataProviderName: "defaultV2",
        values: {
          status: "RESOLVED",
        },
        successNotification: () => {
          return {
            message: "Đã sửa",
            description: "Báo cáo đã được đánh dấu là đã sửa",
            type: "success",
          };
        },
      },
      {
        onSettled: () => {
          tableQueryResult.refetch();
        },
      }
    );
  };

  return (
    <List title="Báo lỗi">
      <Table {...tableProps} rowKey="_id">
        <Table.Column<IChapterReportCompass>
          title="Người dùng"
          dataIndex="user"
          render={(value, record) => {
            return <UserInformation userId={record.userId} />;
          }}
        />
        <Table.Column<IChapterReportCompass>
          title="Chương"
          dataIndex="chapter"
          render={(value, record) => {
            return <ChapterInformation chapterId={record.chapterId} />;
          }}
        />
        <Table.Column<IChapterReportCompass>
          title="Lỗi"
          dataIndex="issues"
          render={(value, record) => {
            const issues = chapterReportIssueOptions.find(
              (x) => x.value === record.issues
            );

            if (issues?.value === "OTHER") {
              return (
                <Popover content={<p>{record.otherIssue}</p>}>
                  <p
                    style={{
                      margin: 0,
                      color: issues?.color ?? undefined,
                      cursor: "pointer",
                    }}
                  >
                    {issues?.label}
                  </p>
                </Popover>
              );
            }

            return (
              <p
                style={{
                  margin: 0,
                  color: issues?.color ?? undefined,
                  cursor: issues?.value === "OTHER" ? "pointer" : undefined,
                }}
              >
                {issues?.label}
              </p>
            );
          }}
        />
        <Table.Column
          title="Trạng thái"
          dataIndex="status"
          render={(value) => {
            const status = chapterReportStatusOptions.find(
              (item) => item.value === value
            );
            return <TagField value={status?.label} color={status?.color} />;
          }}
        />
        <Table.Column
          title="Ngày tạo"
          dataIndex="createdAt"
          render={(value) => {
            return <DateField value={value} format="HH:mm - DD/MM/YYYY" />;
          }}
        />
        <Table.Column<IChapterReportCompass>
          title="Hành động"
          render={(value, record) => {
            return (
              <Space>
                <Button size="small" onClick={() => handleResolve(record?._id)}>
                  <Space align="center">
                    <MdFileDownloadDone />
                    Đã sửa
                  </Space>
                </Button>
                <DeleteButton
                  recordItemId={record?._id}
                  size="small"
                  hideText
                  dataProviderName="defaultV2"
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
