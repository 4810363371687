import React from "react";

import {
  BooleanField,
  DateField,
  DeleteButton,
  EditButton,
  TextField,
  useEditableTable,
} from "@refinedev/antd";

import { Button, Popconfirm, Space, Table, Tooltip } from "antd";
import { IChapter } from "../../interfaces";
import { useCreate, useDeleteMany, useUpdateMany } from "@refinedev/core";
import {
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { AiOutlineFieldNumber } from "react-icons/ai";
import ModalAdjustChapterNumber from "../modal/ModalAdjustChapterNumber";
import { TiTick, TiTimes } from "react-icons/ti";

type Props = {
  bookId: string;
};

export const ChapterTable: React.FC<Props> = ({ bookId }) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [isShowModalAdjustNumber, setIsShowModalAdjustNumber] =
    React.useState<boolean>(false);

  const { tableProps, tableQueryResult } = useEditableTable<any>({
    queryOptions: {
      enabled: bookId != null,
    },
    syncWithLocation: true,
    permanentFilter: [
      {
        field: "bookId",
        operator: "eq",
        value: bookId,
      },
    ],
  });

  // const bookResponse = useOne<IBook>({
  //   resource: "book",
  //   id: bookId!,
  //   queryOptions: {
  //     enabled: bookId != null,
  //   },
  // });

  const { isLoading: isLoadingUpdate, mutate } = useUpdateMany();
  const { isLoading: isLoadingDelete, mutate: mutateDeleteMany } =
    useDeleteMany();
  const { mutate: mutateAdjustNumber } = useCreate();

  const updateSelectedChapters = (isLock: boolean) => {
    mutate(
      {
        resource: "chapter",
        ids: selectedRowKeys as string[],
        values: {
          isLock,
        },
      },
      {
        onSuccess: () => {
          setSelectedRowKeys([]);
        },
      }
    );
  };

  const deleteSelectedChapters = () => {
    mutateDeleteMany(
      {
        resource: "chapter",
        ids: selectedRowKeys as string[],
      },
      {
        onSuccess: () => {
          setSelectedRowKeys([]);
        },
      }
    );
  };

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handlelAdjustNumber = (isOn: boolean) => {
    setIsShowModalAdjustNumber(true);
  };

  const handleIncreaseNumber = (id: string) => {
    mutateAdjustNumber(
      {
        resource: `chapters/${id}/adjust-number`,
        dataProviderName: "defaultV2",
        values: {
          amount: 1,
        },
        successNotification: (data) => {
          return {
            message: "Điều chỉnh số thứ tự thành công",
            title: "Thành công",
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          tableQueryResult.refetch();
        },
      }
    );
  };

  const handleDecreaseNumber = (id: string) => {
    mutateAdjustNumber(
      {
        resource: `chapters/${id}/adjust-number`,
        dataProviderName: "defaultV2",
        values: {
          amount: -1,
        },
        successNotification: (data) => {
          return {
            message: "Điều chỉnh số thứ tự thành công",
            title: "Thành công",
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          tableQueryResult.refetch();
        },
      }
    );
  };

  const moreMenu = () => {
    return (
      <div
        style={{
          marginBottom: "16px",
        }}
      >
        <Space
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "end",
          }}
          align="end"
        >
          <Button
            icon={<AiOutlineFieldNumber />}
            onClick={() => handlelAdjustNumber(true)}
          >
            Điều chỉnh số thứ tự
          </Button>
          <Button
            icon={<UnlockOutlined />}
            onClick={() => updateSelectedChapters(false)}
            loading={isLoadingUpdate || isLoadingDelete}
          >
            Bỏ khoá
          </Button>
          <Button
            icon={<LockOutlined />}
            onClick={() => updateSelectedChapters(true)}
            loading={isLoadingUpdate || isLoadingDelete}
          >
            Khoá
          </Button>
          <Popconfirm
            title="Xác nhận xoá chương"
            description={`Bạn có chắc chắn muốn xoá ${selectedRowKeys.length} chương?`}
            onConfirm={() => deleteSelectedChapters()}
            onCancel={() => {}}
            okText="Xoá"
            cancelText="Huỷ"
            placement="topRight"
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              loading={isLoadingUpdate || isLoadingDelete}
            >
              Xoá
            </Button>
          </Popconfirm>
        </Space>
      </div>
    );
  };

  return (
    <div>
      {selectedRowKeys.length > 0 && moreMenu()}
      <Table {...tableProps} rowKey={"_id"} rowSelection={rowSelection}>
        <Table.Column
          dataIndex="number"
          title="Số"
          key="number"
          fixed="left"
          render={(value, record) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="name"
          title="Tên"
          key="name"
          fixed="left"
          render={(value, record) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Ngày tạo"
          key="createdAt"
          fixed="left"
          render={(value, record) => {
            return <DateField value={value} format={"DD/MM/YYYY - HH:mm"} />;
          }}
        />
        <Table.Column
          dataIndex="isLock"
          title="Khoá"
          key="isLock"
          render={(value, record) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<LockOutlined style={{ color: "red" }} />}
                falseIcon={<UnlockOutlined style={{ color: "green" }} />}
                valueLabelFalse="Mở"
                valueLabelTrue="Khoá"
              />
            );
          }}
        />
        <Table.Column
          dataIndex="isLockSharingRevenue"
          title="Chia sẻ doanh thu"
          key="isLockSharingRevenue"
          render={(value, record) => {
            return (
              <BooleanField
                value={!value}
                // trueIcon={<LockOutlined style={{ color: "red" }} />}
                // falseIcon={<UnlockOutlined style={{ color: "green" }} />}
                trueIcon={<TiTick style={{ color: "green" }} />}
                falseIcon={<TiTimes style={{ color: "red" }} />}
                valueLabelFalse="Không chia sẻ doanh thu với người đăng"
                valueLabelTrue="Chia sẻ doanh thu với người đăng"
              />
            );
          }}
        />
        <Table.Column
          dataIndex="totalViews"
          title="Lượt xem"
          key="totalViews"
          render={(value, record) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column<IChapter>
          dataIndex="actions"
          title="Hành động"
          render={(value, record) => {
            return (
              <Space>
                <Tooltip title="Tăng số thứ tự">
                  <Button
                    size="small"
                    onClick={() => handleIncreaseNumber(record._id)}
                  >
                    +1
                  </Button>
                </Tooltip>

                <Tooltip title="Giảm số thứ tự">
                  <Button
                    size="small"
                    onClick={() => handleDecreaseNumber(record._id)}
                  >
                    -1
                  </Button>
                </Tooltip>

                <EditButton recordItemId={record._id} size="small" hideText />
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Space>
            );
          }}
        />
      </Table>
      <ModalAdjustChapterNumber
        isShow={isShowModalAdjustNumber}
        onClose={() => setIsShowModalAdjustNumber(false)}
        ids={selectedRowKeys.map((item) => item as string)}
        onSuccess={() => tableQueryResult.refetch()}
      />
    </div>
  );
};
