import {
  DeleteOutlined,
  DownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  useCreate,
  useCustom,
  useCustomMutation,
  useDelete,
} from "@refinedev/core";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { TiTick } from "react-icons/ti";

type EditorJobReviewActionsProps = {
  id: string;
  onReview: () => void;
};

export default function EditorJobReviewActions({
  id,
  onReview,
}: EditorJobReviewActionsProps) {
  const { mutate } = useDelete();

  function handleMenuClick(key: string) {
    switch (key) {
      case "1":
        onReview();
        break;

      case "3":
        mutate({
          dataProviderName: "defaultV2",
          resource: "editor-jobs",
          id,
          successNotification(data, values, resource) {
            return {
              message: "Xoá thành công",
              description: `Đã xoá thành công`,
              type: "success",
            };
          },
        });
        break;
    }
  }

  const items: MenuProps["items"] = [
    {
      label: "Thông tin",
      key: "0",
      icon: <InfoCircleOutlined />,
    },
    {
      label: "Kiểm duyệt",
      key: "1",
      icon: <TiTick />,
    },
    {
      label: "Xoá",
      key: "3",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const menuProps: MenuProps = {
    items,
    onClick: ({ key }) => handleMenuClick(key),
  };

  return (
    <>
      <Space>
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Hành động
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Space>
    </>
  );
}
