import {
  DeleteOutlined,
  LockOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { Button, Card, Space } from "antd";
import { ModalDeleteMultipleChapter } from "components/modal/ModalDeleteMultipleChapter";

import { ModalLockMultipleChapters } from "components/modal/ModalLockMultipleChapters";
import { ModalUpdateSharingRevenueState } from "../modal/ModalUpdateSharingRevenueState";

export const ChapterListUtility: React.FC<{
  bookId: string;
  onSuccess: Function;
}> = ({ bookId, onSuccess }) => {
  const { show, formProps, modalProps } = useModalForm({
    resource: "chapters/multiple-lock-state",
    dataProviderName: "defaultV2",
    action: "create",
    redirect: false,
    onMutationSuccess: () => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
  });

  const {
    show: showUnlock,
    formProps: unlockFormProps,
    modalProps: unlockModalProps,
  } = useModalForm({
    resource: "chapters/multiple-lock-state",
    dataProviderName: "defaultV2",
    action: "create",
    redirect: false,
    onMutationSuccess: () => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
  });

  const {
    show: deleteShow,
    formProps: deleteFormProps,
    modalProps: deleteModalProps,
  } = useModalForm({
    resource: "chapters/delete-range",
    dataProviderName: "defaultV2",
    action: "create",
    redirect: false,
    onMutationSuccess: () => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
  });

  const {
    show: showUpdateSharingRevenue,
    formProps: formPropsUpdateSharingRevenue,
    modalProps: modalPropsUpdateSharingRevenue,
  } = useModalForm({
    resource: "chapters/update-sharing-revenue",
    dataProviderName: "defaultV2",
    action: "create",
    redirect: false,
    onMutationSuccess: () => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  });

  formProps.form?.setFieldValue("bookId", bookId);
  unlockFormProps.form?.setFieldValue("bookId", bookId);
  deleteFormProps.form?.setFieldValue("bookId", bookId);
  formPropsUpdateSharingRevenue.form?.setFieldValue("bookId", bookId);

  return (
    <>
      <Card
        style={{
          marginBottom: "16px",
        }}
        title="Thao tác"
      >
        <Space wrap>
          <Button icon={<LockOutlined />} onClick={() => show()}>
            Khoá nhiều
          </Button>
          <Button icon={<LockOutlined />} onClick={() => showUnlock()}>
            Mở khoá nhiều
          </Button>
          <Button
            icon={<MoneyCollectOutlined />}
            onClick={() => showUpdateSharingRevenue()}
          >
            Điều chỉnh chia sẻ doanh thu
          </Button>
          <Button danger icon={<DeleteOutlined />} onClick={() => deleteShow()}>
            Xoá nhiều
          </Button>
        </Space>
      </Card>
      {bookId && bookId !== "" && (
        <ModalLockMultipleChapters
          modalProps={modalProps}
          formProps={formProps}
          isLock={true}
          bookId={bookId}
        />
      )}
      {bookId && bookId !== "" && (
        <ModalLockMultipleChapters
          modalProps={unlockModalProps}
          formProps={unlockFormProps}
          isLock={false}
          bookId={bookId}
        />
      )}
      {bookId && bookId !== "" && (
        <ModalDeleteMultipleChapter
          modalProps={deleteModalProps}
          formProps={deleteFormProps}
          bookId={bookId}
        />
      )}
      {bookId && bookId !== "" && (
        <ModalUpdateSharingRevenueState
          modalProps={modalPropsUpdateSharingRevenue}
          formProps={formPropsUpdateSharingRevenue}
          bookId={bookId}
        />
      )}
    </>
  );
};
