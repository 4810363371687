import React from "react";
import { Image, Typography } from "antd";
import { useOne } from "@refinedev/core";
import { IBook } from "../../interfaces";

const { Paragraph } = Typography;

export const ChatMessageAttachment: React.FC<{
  attachment: any;
  textColor: string;
}> = ({ attachment, textColor }) => {
  const { value, type } = attachment;
  const { data } = useOne<IBook>({
    resource: "book",
    id: value,
  });

  const book = data?.data;

  return (
    <div>
      {book && (
        <div>
          <Image
            src={book.cover[0].url}
            width="120px"
            style={{
              borderRadius: "8px",
              objectFit: "cover",
              aspectRatio: "3/4",
            }}
          />
          <Paragraph
            ellipsis={{ rows: 2 }}
            style={{
              color: textColor,
              marginTop: "8px",
            }}
          >
            {book.name}
          </Paragraph>
        </div>
      )}
    </div>
  );
};
