import { useTable } from "@refinedev/antd";
import { CrudFilters } from "@refinedev/core";
import { IPartner } from "interfaces";

export const usePartnerTable = () => {
  const { tableProps, searchFormProps } = useTable<IPartner>({
    dataProviderName: "defaultV2",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q } = params;

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  return {
    tableProps,
    searchFormProps,
  };
};
