import React, { FC } from "react";
import { DateField, DeleteButton, List, ShowButton, TagField, TextField, useTable } from "@refinedev/antd";
import { Avatar, Space, Table } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { IUser } from "../../interfaces";
import { getColorAuthProvider } from "../../utils";
import { FaBan } from "react-icons/fa";

export const UserList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IUser>();

  return (
    <List title="Danh sách người dùng">
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="avatarUrl"
          title="Avatar"
          key="avatarUrl"
          render={(value, record) => {
            return <Avatar src={value} />;
          }}
        />
        <Table.Column
          dataIndex="displayName"
          title="Tên hiển thị"
          key="displayName"
          render={(value, record) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Ngày gia nhập"
          key="createdAt"
          render={(value, record) => {
            return <DateField value={value} format={"DD/MM/YYYY - HH:mm"} />;
          }}
        />
        <Table.Column
          dataIndex="lastOnlineAt"
          title="Online lần cuối"
          key="lastOnlineAt"
          render={(value, record) => {
            const now = new Date();
            const date = new Date(value.toLocaleString());
            const diff = now.getTime() - date.getTime();
            const days = Math.floor(diff / (1000 * 3600 * 24));
            let s = "";

            if (days < 1) {
              s = "Hôm nay";
            } else {
              s = `${days} hôm trước`;
            }

            return <TextField value={s} />;
          }}
        />
        <Table.Column
          dataIndex="authProvider"
          title="Phương thức đăng nhập"
          key="authProvider"
          render={(value, record) => {
            return (
              <TagField value={value} color={getColorAuthProvider(value)} />
            );
          }}
        />
        <Table.Column<IUser>
          dataIndex="actions"
          title="Hành động"
          render={(value, record) => {
            return (
              <Space>
                <ShowButton recordItemId={record._id} size="small" hideText />
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record._id}
                  icon={<FaBan />}
                  confirmTitle="Cấm user này?"
                  confirmOkText="Cấm"
                  confirmCancelText="Huỷ"
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
