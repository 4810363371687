import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  BooleanField,
  DeleteButton,
  EditButton,
  List,
  TagField,
  useTable,
} from "@refinedev/antd";
import { Button, Checkbox, Popover, Space, Table, Typography } from "antd";
import { IIapProduct } from "../../interfaces";
import { formatVND, thousandFormat } from "utils";

const { Text } = Typography;

export const IAPProductList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IIapProduct>({
    resource: "iap-products",
    dataProviderName: "defaultV2",
  });

  return (
    <List title="IAP Products">
      <Table {...tableProps} rowKey="_id">
        <Table.Column title="Product id" dataIndex="productId" />
        <Table.Column title="Kiểu mua" dataIndex="purchaseType" />
        <Table.Column
          title="Kiểu gói"
          dataIndex="type"
          render={(value) => {
            if (value === "COIN_PACK") {
              return <TagField value={"Gói xu"} />;
            }

            return value;
          }}
        />
        <Table.Column title="Giá (USD)" dataIndex="definePrice" />
        <Table.Column
          title="Giá banking (VND)"
          dataIndex="bankingPrice"
          render={(value) => {
            if (!value) return <>NaN</>;

            return <>{formatVND(value)}</>;
          }}
        />
        <Table.Column
          title="Số lượng"
          dataIndex="amount"
          render={(value) => {
            return <Text>{thousandFormat(value)}</Text>;
          }}
        />
        <Table.Column
          title="Số lượng banking"
          dataIndex="bankingAmount"
          render={(value) => {
            if (!value) return <>NaN</>;

            return <Text>{thousandFormat(value)}</Text>;
          }}
        />
        <Table.Column<IIapProduct>
          title="Khuyến mãi lần đầu"
          dataIndex="firstTimeBonus"
          render={(value, record) => {
            return (
              <Popover
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Space
                      align="baseline"
                      style={{
                        paddingBottom: 0,
                        marginBottom: 0,
                      }}
                    >
                      <b>Số lượng:</b>
                      <p>{record.firstTimeBonus.amount}</p>
                    </Space>
                    <Space align="baseline">
                      <b>Phần trăm (%):</b>
                      <p>{record.firstTimeBonus.percent * 100}%</p>
                    </Space>
                  </div>
                }
                title="Chi tiết"
                trigger="hover"
                placement="leftTop"
              >
                <Button type="link">Xem</Button>
              </Popover>
            );
          }}
        />
        <Table.Column
          title="Chỉ hiển thị trên web"
          dataIndex="webOnly"
          render={(value) => {
            return <Checkbox checked={value} disabled />;
          }}
        />
        <Table.Column<IIapProduct>
          title="Hành động"
          dataIndex="actions"
          render={(value, record) => {
            return (
              <Space>
                <EditButton recordItemId={record._id} size="small" hideText />
                <DeleteButton
                  recordItemId={record._id}
                  size="small"
                  hideText
                  dataProviderName="defaultV2"
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
