import {
  DeleteOutlined,
  DollarOutlined,
  DownOutlined,
  InfoCircleOutlined,
  MailOutlined,
  RedoOutlined,
  TrophyOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { useModal } from "@refinedev/antd";
import { Button, Dropdown, MenuProps, Modal, Space } from "antd";
import { IPartner } from "interfaces";
import PartnerPaymentInfo from "./PartnerPaymentInfo";

export const ButtonPartnerActions: React.FC<{ partner: IPartner }> = ({
  partner,
}) => {
  const { show: showModalPayment, modalProps } = useModal();

  function handleMenuClick(key: string) {
    switch (key) {
      case "1":
        showModalPayment();
        break;
    }
  }

  const items: MenuProps["items"] = [
    {
      label: "Thông tin",
      key: "0",
      icon: <InfoCircleOutlined />,
    },
    {
      label: "Phương thức thanh toán",
      key: "1",
      icon: <WalletOutlined />,
    },
    {
      label: "Lương",
      key: "2",
      icon: <DollarOutlined />,
    },
    {
      label: "Reset mật khẩu",
      key: "3",
      icon: <RedoOutlined />,
    },
    {
      label: "Gửi thư",
      key: "4",
      icon: <MailOutlined />,
    },
    {
      label: "Điều chỉnh quyền",
      key: "5",
      icon: <TrophyOutlined />,
    },
    {
      label: "Xoá tài khoản",
      key: "6",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const menuProps: MenuProps = {
    items,
    onClick: ({ key }) => handleMenuClick(key),
  };

  return (
    <>
      <Space>
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Hành động
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Space>
      <Modal
        {...modalProps}
        title="Phương thức thanh toán"
        centered
        width={720}
      >
        <PartnerPaymentInfo />
      </Modal>
    </>
  );
};
