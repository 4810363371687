import { Refine } from "@refinedev/core";
import {
  AuthPage,
  ErrorComponent,
  Layout,
  notificationProvider,
  ReadyPage,
  ThemedLayoutV2,
} from "@refinedev/antd";

import routerProvider from "@refinedev/react-router-v6/legacy";
import JsonServer from "./providers/dataProvider";
import { CategoryCreate, CategoryEdit, CategoryList } from "./pages/category";
import {
  AudioBookCreate,
  AudioBookList,
  BookCreate,
  BookList,
  ComicBookCreate,
  ComicEdit,
  ComicList,
} from "./pages/book";
import { BookEdit } from "./pages/book/edit";
import {
  AudioChapterCreate,
  AudioChapterEdit,
  AudioChapterList,
  ChapterCreate,
  ChapterEdit,
  ChapterList,
  ComicChapterCreate,
  ComicChapterList,
} from "./pages/chapter";
import { AiFillAudio, AiFillSetting } from "react-icons/ai";
import { BannerCreate, BannerList } from "./pages/banner";
import { authProvider } from "./providers/authProvider";
import {
  NotificationCreate,
  NotificationEdit,
  NotificationList,
} from "./pages/notification";
import {
  MdGTranslate,
  MdLocalActivity,
  MdNotificationsActive,
} from "react-icons/md";
import { AccountCreate, AccountEdit, AccountList } from "./pages/accounts";
import { RiAdminFill } from "react-icons/ri";
import { UserList } from "./pages/user";
import { FaDonate, FaHandshake, FaUserFriends } from "react-icons/fa";
import { CheckinList } from "pages/activity/checkin";
import { TbBusinessplan } from "react-icons/tb";
import { ProductCreate, ProductEdit, ProductList } from "pages/products";
import { ReceiptList } from "pages/receipts";
import { GiftCodeCreate, GiftCodeList, GiftCodeShow } from "./pages/gift-code";
import React from "react";
import { IoChatbubblesOutline, IoImages } from "react-icons/io5";
import { HiDocumentText } from "react-icons/hi";
import { ComicCategoryList } from "./pages/comic-category";
import { AudioCategoryList } from "./pages/audio-category";
import "@refinedev/antd/dist/reset.css";
import { ComicChapterEdit } from "./pages/chapter/comic-edit";
import { TicketCreate, TicketList } from "pages/tickets";
import { TicketEdit } from "pages/tickets/edit";
import { BsFileEarmarkPerson, BsPeopleFill } from "react-icons/bs";
import { ChatPage } from "./pages/chat";
import { StickersCreate, StickersEdit, StickersList } from "pages/stickers";
import { PopupCreate, PopupEdit, PopupList } from "./pages/popups";
import { AuthorCreate, AuthorList } from "pages/author";
import { AuthorEdit } from "pages/author/edit";
import { AudioTaskCreate, AudioTaskList } from "pages/audio-task";
import { PartnerCreate, PartnerList } from "pages/partners";
import { EditJobsCreate, EditJobsList } from "pages/editor-jobs";
import { PartnerAnalyticList } from "./pages/partner-analystic";
import { ContributorCreate, ContributorList } from "./pages/contributor";
import {
  IAPProductCreate,
  IAPProductEdit,
  IAPProductList,
} from "./pages/iap-product";
import {
  CollectionCreate,
  CollectionEdit,
  CollectionList,
} from "./pages/collection";
import { BiCollection } from "react-icons/bi";
import { SeoSiteCreate, SeoSiteEdit, SeoSiteList } from "./pages/seo-site";
import { ReviewingList } from "./pages/reviewing";
import { VscOpenPreview } from "react-icons/vsc";
import {
  ContributorInvoiceCreate,
  ContributorInvoiceList,
  ContributorInvoiceShow,
} from "./pages/contributor-invoice";
import { ChapterReportList } from "./pages/chapter-report";
import { AppConfigList } from "./pages/app-config";
import { LuckWheelMoneyReceiptList } from "pages/lucky-wheel-money-receipt";
import { GiCarWheel } from "react-icons/gi";
import LuckyWheelUserCloneList from "pages/lucky-wheel-user-clone/list";

function App() {
  document.title = "AppTruyen Admin Panel";

  return (
    <Refine
      notificationProvider={notificationProvider}
      legacyAuthProvider={authProvider}
      LoginPage={AuthPage}
      Layout={Layout}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      legacyRouterProvider={routerProvider}
      dataProvider={{
        default: JsonServer(process.env.REACT_APP_API_URL),
        defaultV2: JsonServer(`${process.env.REACT_APP_API_URL}/v2`),
        realtime: JsonServer(`${process.env.REACT_APP_RT_URL}/api-admin`),
      }}
      resources={[
        {
          name: "book-management",
          icon: <HiDocumentText />,
          meta: {
            label: "Truyện chữ",
          },
        },
        {
          name: "category",
          parentName: "book-management",
          list: CategoryList,
          create: CategoryCreate,
          edit: CategoryEdit,
          meta: {
            label: "Thể loại",
          },
        },
        {
          name: "book",
          parentName: "book-management",
          list: BookList,
          create: BookCreate,
          edit: BookEdit,
          meta: {
            label: "Truyện",
          },
        },
        {
          name: "chapter",
          parentName: "book-management",
          list: ChapterList,
          create: ChapterCreate,
          edit: ChapterEdit,
          meta: {
            label: "Chương",
          },
        },
        {
          name: "comic-management",
          icon: <IoImages />,
          meta: {
            label: "Truyện Tranh",
          },
        },
        {
          name: "category",
          parentName: "comic-management",
          list: ComicCategoryList,
          create: CategoryEdit,
          edit: CategoryEdit,
          meta: {
            label: "Thể loại",
          },
        },
        {
          name: "book",
          parentName: "comic-management",
          list: ComicList,
          create: ComicBookCreate,
          edit: ComicEdit,
          meta: {
            label: "Truyện",
          },
        },
        {
          name: "chapter",
          parentName: "comic-management",
          list: ComicChapterList,
          create: ComicChapterCreate,
          edit: ComicChapterEdit,
          meta: {
            label: "Chương",
          },
        },
        {
          name: "audio-management",
          icon: <AiFillAudio />,
          meta: {
            label: "Truyện Audio",
          },
        },
        {
          name: "category",
          parentName: "audio-management",
          list: AudioCategoryList,
          create: CategoryCreate,
          edit: CategoryEdit,
          meta: {
            label: "Thể loại",
          },
        },
        {
          name: "book",
          parentName: "audio-management",
          list: AudioBookList,
          create: AudioBookCreate,
          edit: BookEdit,
          meta: {
            label: "Truyện",
          },
        },
        {
          name: "chapter",
          parentName: "audio-management",
          list: AudioChapterList,
          create: AudioChapterCreate,
          edit: AudioChapterEdit,
          meta: {
            label: "Chương",
          },
        },
        {
          name: "audio-tasks",
          parentName: "audio-management",
          list: AudioTaskList,
          create: AudioTaskCreate,
          meta: {
            label: "Generate Tasks",
          },
        },
        {
          name: "reviewing",
          list: ReviewingList,
          meta: {
            label: "Xét duyệt",
            icon: <VscOpenPreview />,
          },
        },
        {
          name: "authors",
          list: AuthorList,
          create: AuthorCreate,
          edit: AuthorEdit,
          meta: {
            label: "Tác giả",
          },
          icon: <BsFileEarmarkPerson />,
        },
        {
          name: "collections",
          list: CollectionList,
          create: CollectionCreate,
          edit: CollectionEdit,
          meta: {
            label: "Bộ sưu tập",
            icon: <BiCollection />,
          },
        },
        {
          name: "client-setting",
          icon: <AiFillSetting />,
          meta: {
            label: "Cài đặt client",
          },
        },
        {
          name: "app-configs",
          parentName: "client-setting",
          list: AppConfigList,
          meta: {
            label: "Chung",
          },
        },
        {
          name: "banner",
          parentName: "client-setting",
          meta: {
            label: "Banner",
          },
          list: BannerList,
          create: BannerCreate,
        },
        {
          name: "notification",
          meta: {
            label: "Thông báo",
          },
          list: NotificationList,
          create: NotificationCreate,
          edit: NotificationEdit,
          icon: <MdNotificationsActive />,
        },
        {
          name: "account",
          meta: {
            label: "Tài khoản",
          },
          list: AccountList,
          create: AccountCreate,
          edit: AccountEdit,
          icon: <RiAdminFill />,
        },
        {
          name: "user",
          meta: {
            label: "Người dùng",
          },
          list: UserList,
          icon: <FaUserFriends />,
        },
        {
          name: "partners-management",
          icon: <FaHandshake />,
          meta: {
            label: "Cộng tác viên",
          },
        },
        {
          name: "partner-analytics",
          parentName: "partners-management",
          list: PartnerAnalyticList,
          meta: {
            label: "Thống kê",
          },
        },
        {
          name: "partners",
          parentName: "partners-management",
          list: PartnerList,
          create: PartnerCreate,
          meta: {
            label: "Danh sách",
          },
        },
        {
          name: "editor-jobs",
          parentName: "partners-management",
          list: EditJobsList,
          create: EditJobsCreate,
          meta: {
            label: "Editor Jobs",
          },
        },
        {
          name: "translate-teams",
          icon: <MdGTranslate />,
          meta: {
            label: "Nhóm dịch",
          },
        },
        {
          name: "contributors",
          list: ContributorList,
          create: ContributorCreate,
          parentName: "translate-teams",
          // icon: <FaDonate />,
          meta: {
            label: "Danh sách",
          },
        },
        {
          name: "contributor-invoices",
          list: ContributorInvoiceList,
          create: ContributorInvoiceCreate,
          show: ContributorInvoiceShow,
          parentName: "translate-teams",
          // icon: <FaDonate />,
          meta: {
            label: "Thanh toán",
          },
        },
        {
          name: "community",
          meta: {
            label: "Cộng đồng",
          },
          icon: <IoChatbubblesOutline />,
        },
        {
          name: "chat",
          parentName: "community",
          list: ChatPage,
          meta: {
            label: "Kênh Chat",
          },
        },
        {
          name: "chapter-reports",
          parentName: "community",
          list: ChapterReportList,
          meta: {
            label: "Báo lỗi",
          },
        },
        {
          name: "activities",
          icon: <MdLocalActivity />,
          meta: {
            label: "Hoạt động",
          },
        },
        {
          name: "checkin",
          parentName: "activities",
          meta: {
            label: "Checkin",
          },
          list: CheckinList,
        },
        {
          name: "achivement",
          parentName: "activities",
          meta: {
            label: "Thành tưu",
          },
        },
        {
          name: "gift-code",
          parentName: "activities",
          meta: {
            label: "Gift Code",
          },
          list: GiftCodeList,
          create: GiftCodeCreate,
          show: GiftCodeShow,
        },
        {
          name: "ticket",
          parentName: "activities",
          meta: {
            label: "Vé",
          },
          list: TicketList,
          create: TicketCreate,
          edit: TicketEdit,
        },
        {
          name: "stickers",
          parentName: "activities",
          meta: {
            label: "Stickers",
          },
          list: StickersList,
          create: StickersCreate,
          edit: StickersEdit,
        },
        {
          name: "popups",
          parentName: "activities",
          meta: {
            label: "Popups",
          },
          list: PopupList,
          create: PopupCreate,
          edit: PopupEdit,
        },
        {
          name: "seo-sites",
          parentName: "activities",
          list: SeoSiteList,
          create: SeoSiteCreate,
          edit: SeoSiteEdit,
          meta: {
            label: "SEO",
          },
        },
        {
          name: "business",
          icon: <TbBusinessplan />,
          meta: {
            label: "Kinh doanh",
          },
        },
        {
          name: "iap-products",
          parentName: "business",
          list: IAPProductList,
          create: IAPProductCreate,
          edit: IAPProductEdit,
          meta: {
            label: "IAP Products",
          },
        },
        {
          name: "products",
          parentName: "business",
          list: ProductList,
          create: ProductCreate,
          edit: ProductEdit,
          meta: {
            label: "Sản phẩm",
          },
        },
        {
          name: "receipts",
          parentName: "business",
          list: ReceiptList,
          meta: {
            label: "Hoá đơn",
          },
        },
        {
          name: "lucky-wheel",
          meta: {
            label: "Vòng quay",
            icon: <GiCarWheel />,
          },
        },

        {
          name: "wheel-money-receipts",
          parentName: "lucky-wheel",
          list: LuckWheelMoneyReceiptList,
          meta: {
            label: "Hoá đơn",
          },
        },
        {
          name: "wheel-user-clones",
          parentName: "lucky-wheel",
          list: LuckyWheelUserCloneList,
          meta: {
            label: "Clone",
          },
        },
      ]}
    />
  );
}

export default App;
