import React, { FC } from "react";
import { IResourceComponentsProps, useNavigation, useOne } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, InputNumber, Select } from "antd";
import { IBook, IChapter } from "interfaces";

export const ChapterEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, queryResult, saveButtonProps } = useForm<IChapter>({
    redirect: false,
    onMutationSuccess: () => {
      goBack();
    },
  });

  const { goBack } = useNavigation();

  const bookQueryResponse = useOne<IBook>({
    resource: "book",
    id: queryResult?.data?.data.bookId!,
    queryOptions: {
      enabled: queryResult != null && queryResult.data != null,
    },
  });

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 10,

    queryOptions: {
      enabled: bookQueryResponse != null && bookQueryResponse.data != null,
      onSuccess: (data) => {
        data.data.push(bookQueryResponse.data?.data!);
      },
    },

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  return (
    <Edit
      title="Sửa chương"
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Truyện"
          name="bookId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...bookSelectProps} disabled />
        </Form.Item>
        <Form.Item
          label="Số"
          name="number"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Giá unlock"
          name="price"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nội dung"
          name="content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Edit>
  );
};
