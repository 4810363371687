import { useCreate } from "@refinedev/core";
import { notification } from "antd";

export const useNotifyNewChapters = () => {
  const mutationNotification = useCreate();
  const { mutate: mutateNotify, isLoading: postNotificationLoading } =
    mutationNotification;

  const notify = (id: string) => {
    mutateNotify(
      {
        resource: `book/${id}/notify-update`,
        values: {},
        successNotification: false,
        errorNotification: false,
      },
      {
        onError: (error, variables, context) => {
          // An error happened!
          notification.error({ message: "Đã có lỗi xảy ra" }); //
        },
        onSuccess: (data, variables, context) => {
          notification.success({ message: "Gửi thông báo thành công!" }); // Let's celebrate!
        },
      }
    );
  };

  return {
    notify,
  };
};
