import React, { FC } from "react";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { INotification } from "../../interfaces";
import JoditEditor from "jodit-react";

export const NotificationCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<INotification>({});

  return (
    <Create title="Tạo thông báo" saveButtonProps={saveButtonProps}>
      <div style={{ color: "red", paddingBottom: "8px" }}>
        * Thông báo sẽ gửi đến tất cả người dùng
      </div>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nội dung"
          name="content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea maxLength={1000} />
        </Form.Item>
        <Form.Item
          label="Link"
          name="link"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Chi tiết"
          name="detail"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <JoditEditor value={""} />
        </Form.Item>
      </Form>
    </Create>
  );
};
