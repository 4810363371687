import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { DatePicker, Form, InputNumber, Select, Space, Typography } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";

import "dayjs/locale/vi";
import { IContributor } from "../../interfaces";
const { Title } = Typography;

export const ContributorInvoiceCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectProps } = useSelect<IContributor>({
    resource: "contributors",
    dataProviderName: "defaultV2",
    optionLabel: "name",
    optionValue: "_id",
    onSearch: (value) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: value,
        },
      ];
    },
  });

  const { formProps, saveButtonProps } = useForm({
    resource: "contributor-invoices",
    dataProviderName: "defaultV2",
  });

  if (formProps.form != null) {
    // get current month
    const currentMonth = new Date().getMonth();
    formProps.form.setFieldValue("month", currentMonth);

    // get current year
    const currentYear = new Date().getFullYear();
    formProps.form.setFieldValue("year", currentYear);
  }

  return (
    <Create title="Tạo hoá đơn thanh toán" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nhóm dịch"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn nhóm dịch",
            },
          ]}
          name="contributorId"
        >
          <Select
            {...selectProps}
            placeholder="Nhập tên hoặc email để tìm kiếm…"
          />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  label="Thời gian"*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*      message: "Vui lòng chọn thời gian",*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  name="time"*/}
        {/*>*/}
        {/*  <DatePicker*/}
        {/*    picker="month"*/}
        {/*    placeholder="Chọn tháng - năm"*/}
        {/*    style={{*/}
        {/*      minWidth: 240,*/}
        {/*    }}*/}
        {/*    locale={locale}*/}
        {/*    format="MM/YYYY"*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        <Title level={5}>Thời gian</Title>
        <Space>
          <Form.Item
            name="month"
            label="Tháng"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn tháng",
              },
            ]}
          >
            <InputNumber min={1} max={12} placeholder="Tháng" />
          </Form.Item>
          <Form.Item
            name="year"
            label="Năm"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn năm",
              },
            ]}
          >
            <InputNumber min={2023} max={2030} placeholder="Năm" />
          </Form.Item>
        </Space>
      </Form>
    </Create>
  );
};
