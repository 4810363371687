import React, { useCallback, useState } from "react";
import { IResourceComponentsProps, useNavigation, useOne } from "@refinedev/core";
import { Create, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Button, Form, Input, InputNumber, Select, Space, Typography, Upload } from "antd";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { IBook, IChapter } from "../../interfaces";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableUploadListItem from "../../components/DraggableUploadListItem";

const { Text } = Typography;

export const ComicChapterCreate: React.FC<IResourceComponentsProps> = () => {
  const { useLocation } = routerProvider;

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [setSelectId, setSetSelectId] = useState<string | null>(
    params.get("bookId")
  );
  const { listUrl, list, push, goBack } = useNavigation();

  const { formProps, saveButtonProps, form } = useForm<IChapter>({
    redirect: false,
    onMutationSuccess: () => {
      goBack();
    },
  });

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 20,

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "comic",
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  const bookDetailResponse = useOne<IBook>({
    resource: "book",
    id: setSelectId!,
    queryOptions: {
      enabled: setSelectId != null,
      onSuccess: (data) => {
        form.setFieldsValue({ number: data.data?.lastChapter + 1 });
      },
    },
  });

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const fileList = formProps!.form!.getFieldValue("contents");

      const dragRow = fileList[dragIndex];
      fileList.splice(dragIndex, 1);
      fileList.splice(hoverIndex, 0, dragRow);
      formProps!.form!.setFieldsValue({ contents: fileList });
    },
    [formProps]
  );

  return (
    <Create title="Thêm chapter" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Truyện"
          name="bookId"
          initialValue={setSelectId}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...bookSelectProps}
            onChange={(type, options) => {
              const value = type.toString();
              setSetSelectId(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Số"
          name="number"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Giá unlock"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <DndProvider backend={HTML5Backend}>
          <Form.Item
            label={
              <Space
                align="baseline"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                size="large"
              >
                <p>Nội dung</p>
                <Button
                  size="small"
                  danger
                  onClick={() => {
                    // setFileList([]);
                    // setUploadedFile([]);
                    formProps.form?.setFieldsValue({ content: [] });
                  }}
                >
                  Xoá hết
                </Button>
              </Space>
            }
            name="contents"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload.Dragger
              name="images"
              listType="picture"
              action={`${process.env.REACT_APP_UPLOAD_URL}/images-chapter`}
              multiple
              accept=".png,.jpeg,.jpg,.webp"
              // fileList={fileList}
              // onChange={handleChange}
              itemRender={(originNode, file, currFileList) => (
                <DraggableUploadListItem
                  originNode={originNode}
                  file={file}
                  fileList={currFileList}
                  moveRow={moveRow}
                  onClick={(_file) => {}}
                />
              )}
            >
              <Text>Drag & drop image to upload</Text>{" "}
            </Upload.Dragger>
          </Form.Item>
        </DndProvider>
        <Form.Item name="type" hidden initialValue="comic" />
      </Form>
    </Create>
  );
};
