import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Card, Typography } from "antd";

import { AccountSelectCard, ChatBox } from "../../components/chat";

const { Title } = Typography;

export const ChatPage: React.FC<IResourceComponentsProps> = () => {
  const [userId, setUserId] = React.useState<string | null>(null);

  return (
    <>
      <Title level={4}>Kênh Chat</Title>
      {userId ? (
        <ChatBox userId={userId} />
      ) : (
        <AccountSelectCard onSelectedUserId={(id: string) => setUserId(id)} />
      )}
    </>
  );
};
