import { IResourceComponentsProps, useNavigation, useOne } from "@refinedev/core";
import React, { FC, useState } from "react";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, InputNumber, Select } from "antd";
import { IBook, IChapter } from "interfaces";
import routerProvider from "@refinedev/react-router-v6/legacy";

export const ChapterCreate: FC<IResourceComponentsProps> = () => {
  const { useLocation } = routerProvider;

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [setSelectId, setSetSelectId] = useState<string | null>(
    params.get("bookId")
  );
  const { listUrl, list, push, goBack } = useNavigation();

  const { formProps, saveButtonProps, form } = useForm<IChapter>({
    redirect: false,
    onMutationSuccess: () => {
      goBack();
    },
  });

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 20,

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "novel",
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  const bookDetailResponse = useOne<IBook>({
    resource: "book",
    id: setSelectId!,
    queryOptions: {
      enabled: setSelectId != null,
      onSuccess: (data) => {
        form.setFieldsValue({ number: data.data?.lastChapter + 1 });
        bookSelectProps.options?.push({
          label: data.data?.name,
          value: data.data?._id,
        });
      },
    },
  });

  return (
    <Create title="Thêm chapter" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Truyện"
          name="bookId"
          initialValue={setSelectId}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...bookSelectProps}
            onChange={(type, options) => {
              const value = type.toString();
              setSetSelectId(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Số"
          name="number"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Giá unlock"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nội dung"
          name="content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="type" hidden initialValue="novel" />
      </Form>
    </Create>
  );
};
