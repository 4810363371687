import { DeleteButton, EditButton, ImageField, List, useTable } from "@refinedev/antd";
import { Space, Table } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { ITicket } from "interfaces";
import React from "react";

export const TicketList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ITicket>({});

  return (
    <List>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="icon"
          render={(value) => {
            return (
              <ImageField
                value={value[0].url}
                width="80px"
                height="80px"
                style={{
                  objectFit: "cover",
                }}
              />
            );
          }}
        />
        <Table.Column dataIndex="name" title="Tên" />
        <Table.Column dataIndex="desc" title="Mô tả" />
        <Table.Column dataIndex="coinPrice" title="Giá coin" />
        <Table.Column dataIndex="pointPrice" title="Giá điểm" />
        <Table.Column<ITicket>
          dataIndex="actions"
          title="Hành động"
          render={(value, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record._id} />
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
