import { LegacyAuthProvider as AuthProvider } from "@refinedev/core";
import axios from "axios";
import { IAccount } from "../interfaces";

export const authProvider: AuthProvider = {
  async getUserIdentity(): Promise<IAccount> {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_API_URL}/account/me`
    );

    if (status === 200) {
      return Promise.resolve<IAccount>(data.data);
    }

    return Promise.reject<IAccount>();
  },
  async checkAuth(params: any): Promise<void> {
    const { data, status } = await axios.put(
      `${process.env.REACT_APP_AUTH_URL}/verify`
    );

    if (status === 200) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  checkError(error: any): Promise<void> {
    return Promise.resolve(undefined);
  },
  getPermissions(params: any): Promise<any> {
    return Promise.resolve(undefined);
  },
  async login(params: any): Promise<any> {
    const { email, password } = params;

    const { data, status } = await axios.post(
      `${process.env.REACT_APP_AUTH_URL}/login`,
      {
        username: email,
        password,
      }
    );

    if (status === 200) {
      return Promise.resolve();
    }

    return Promise.reject({ message: data.message });
  },
  async logout(params: any): Promise<any> {
    const { status } = await axios.delete(
      `${process.env.REACT_APP_AUTH_URL}/logout`
    );

    if (status === 200) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
};
