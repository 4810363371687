import {
  DateField,
  FilterDropdown,
  List,
  TagField,
  TextField,
  useTable,
} from "@refinedev/antd";
import {
  Avatar,
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { wheelMoneyReceiptStatusOptions } from "../../constants";
import { IUser, IWheelMoneyReceipt } from "interfaces";
import { currencyFormatter } from "utils";
import { WheelMoneyReceiptActions } from "components/wheel-money-receipt/WheelMoneyReceiptActions";

const { Text } = Typography;

export const LuckWheelMoneyReceiptList: React.FC = () => {
  const { tableProps, tableQueryResult, searchFormProps } =
    useTable<IWheelMoneyReceipt>({
      dataProviderName: "defaultV2",
      onSearch(data: any) {
        const { search } = data;

        return [
          {
            field: "search",
            operator: "eq",
            value: search,
          },
        ];
      },
    });

  return (
    <List title="Hoá đơn">
      <Card title="Lọc">
        <Form {...searchFormProps} layout="vertical">
          <Form.Item name="search" label="Tìm kiếm">
            <Input placeholder="Nhập tên hoặc email …" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Tìm kiếm
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Table {...tableProps} rowKey="_id">
        <Table.Column<IWheelMoneyReceipt>
          title="Người dùng"
          dataIndex="user"
          render={(value, record) => {
            const user = record.user as IUser | null;

            if (!user) return <div>Người dùng không tồn tại</div>;

            return (
              <Space>
                <Avatar src={user.avatarUrl} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "start",
                  }}
                >
                  <Text>{user.displayName}</Text>
                  <Text
                    style={{
                      color: "gray",
                      fontSize: "0.8rem",
                    }}
                  >
                    {user.email}
                  </Text>
                </div>
              </Space>
            );
          }}
        />
        <Table.Column
          title="Số tiền"
          dataIndex="money"
          render={(value) => {
            return <TextField value={currencyFormatter(value)} />;
          }}
        />
        <Table.Column
          title="Ngày tạo"
          dataIndex="createdAt"
          render={(value) => {
            return <DateField value={value} format="HH:mm:ss - DD/MM/YYYY" />;
          }}
        />
        <Table.Column
          title="Trạng thái"
          dataIndex="status"
          filterDropdown={(props) => {
            return (
              <FilterDropdown
                {...props}
                mapValue={(selectedKeys) => selectedKeys.map(String)}
              >
                <Select
                  options={wheelMoneyReceiptStatusOptions}
                  mode="multiple"
                  placeholder="Lọc trạng thái..."
                  style={{ minWidth: 200 }}
                />
              </FilterDropdown>
            );
          }}
          render={(value) => {
            const status = wheelMoneyReceiptStatusOptions.find(
              (x) => x.value === value
            );
            return <TagField value={status?.label} color={status?.color} />;
          }}
        />
        <Table.Column<IWheelMoneyReceipt>
          title="Hành động"
          dataIndex="actions"
          render={(value, record) => {
            return (
              <WheelMoneyReceiptActions
                receipt={record}
                onRefresh={() => tableQueryResult.refetch()}
              />
            );
          }}
        />
      </Table>
    </List>
  );
};
