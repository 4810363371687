import { ImageField, useSelect } from "@refinedev/antd";
import { Card, Form, FormProps, Modal, ModalProps, Select, Space } from "antd";
import { useOne } from "@refinedev/core";
import { IBook, IBookVariantDetail } from "interfaces";
import React from "react";
import { getBookTypeLabel } from "utils";
import _ from "underscore";

export const ModalLinkVariants: React.FC<{
  modalProps: ModalProps;
  formProps: FormProps;
  bookId: string;
}> = ({ modalProps, formProps, bookId }) => {
  const [bookGroupOptions, setBookGroupOptions] = React.useState([]);

  const { data: variantsRes } = useOne<IBookVariantDetail>({
    resource: "books",
    dataProviderName: "defaultV2",
    id: `${bookId}/variants`,
    queryOptions: {
      enabled: modalProps.open,
    },
  });

  const variants = variantsRes?.data;

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "all",
      },
    ],

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    queryOptions: {
      onSuccess(data) {
        // group book by type
        const groupByType = data.data?.reduce((acc: any, book) => {
          const type = book.type ?? "novel";

          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push(book);
          return acc;
        }, {});

        // convert to options
        const options = Object.keys(groupByType ?? {}).map((key) => {
          return {
            label: getBookTypeLabel(key),
            options: groupByType[key].map((book: IBook) => ({
              label: book.name,
              value: book._id,
            })),
          };
        });

        setBookGroupOptions(options as []);
      },
    },

    pagination: {
      mode: "server"
    }
  });

  return (
    <Modal
      {...modalProps}
      centered
      width={480}
      title="Liên kết biến thể truyện"
    >
      <div
        style={{
          marginBottom: 24,
        }}
      >
        <p>Đã liên kết</p>
        {variants &&
          Object.keys(variants ?? {}).map((key) => (
            <Space key={key} direction="vertical">
              <span>{getBookTypeLabel(key)}</span>
              <Space>
                <ImageField
                  value={(variants as any)[key].cover[0].url ?? ""}
                  style={{
                    width: 80,
                    aspectRatio: "1",
                    objectFit: "cover",
                    borderRadius: 4,
                  }}
                />
                <p>{(variants as any)[key].name}</p>
              </Space>
            </Space>
          ))}
      </div>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Chọn truyện"
          name="withId"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn truyện",
            },
          ]}
        >
          <Select {...bookSelectProps} options={bookGroupOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
