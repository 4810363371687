import { Create, getValueFromEvent, useForm } from "@refinedev/antd";
import { Form, Input, Upload, Typography, InputNumber, Checkbox } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { ISticker } from "interfaces";
import React from "react";
import slug from "slug";

const { Text } = Typography;

export const StickersCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ISticker>({});
  const nameValue = Form.useWatch("name", formProps.form);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="name"
          label="Tên"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Hình ảnh"
          name="images"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
              message: "Vui lòng chọn hình ảnh",
            },
          ]}
        >
          <Upload
            name="images"
            listType="picture-card"
            action={`${
              process.env.REACT_APP_UPLOAD_URL
            }/images-raw?dirName=${slug(nameValue ?? "no-name")}`}
            maxCount={20}
            multiple
            accept=".png,.jpeg,.jpg,.gif,.webp"
            disabled={nameValue == null}
          >
            <Text>Drag & drop image to upload</Text>{" "}
          </Upload>
        </Form.Item>
        <Form.Item
          name="price"
          label="Giá (xu)"
          initialValue={0}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập giá",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name="vipOnly" label="Vip" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>
    </Create>
  );
};
