import { FC } from "react";
import { IResourceComponentsProps, useUpdate } from "@refinedev/core";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { Space, Switch, Table, Typography } from "antd";
import { ISeoSite } from "../../interfaces";

const { Text, Link } = Typography;

export const SeoSiteList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ISeoSite>({
    dataProviderName: "defaultV2",
    resource: "seo-sites",
  });

  const { mutate: mutateUpdate } = useUpdate();

  const updateActive = (id: string, active: boolean) => {
    mutateUpdate({
      dataProviderName: "defaultV2",
      resource: "seo-sites",
      id: id,
      values: {
        active: active,
      },
    });
  };

  return (
    <List title="SEO">
      <Table {...tableProps} rowKey="_id">
        <Table.Column<ISeoSite>
          title="Hoạt động"
          dataIndex="active"
          render={(value, record) => {
            return (
              <Switch
                checked={value}
                onChange={(active) => {
                  updateActive(record._id, active);
                }}
              />
            );
          }}
        />
        <Table.Column title="Keyword" dataIndex="keyword" />
        <Table.Column
          title="Domain"
          dataIndex="domain"
          render={(value) => {
            return (
              <Link href={value} target="__blank">
                {value}
              </Link>
            );
          }}
        />
        <Table.Column
          title="Phần thưởng"
          dataIndex="codeRewards"
          render={(value) => {
            return (
              <Space>
                {value.coin > 0 && <Text>{value.coin} xu</Text>}
                {value.point > 0 && <Text>{value.point} điểm</Text>}
              </Space>
            );
          }}
        />
        <Table.Column<ISeoSite>
          title="Hành động"
          dataIndex="actions"
          render={(value, record, index) => {
            return (
              <Space>
                <EditButton recordItemId={record._id} size="small" hideText />
                <DeleteButton
                  recordItemId={record._id}
                  size="small"
                  hideText
                  dataProviderName="defaultV2"
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
