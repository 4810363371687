import React from "react";
import { useOne } from "@refinedev/core";
import { Avatar, Space, Typography } from "antd";
import { IUser } from "../interfaces";

const { Text } = Typography;

type Props = {
  userId: string;
};

export const UserInformation: React.FC<Props> = ({ userId }) => {
  const { data } = useOne<IUser>({
    id: userId,
    resource: "user",
  });

  const record = data?.data;

  if (!record) return <div></div>;

  return (
    <Space>
      <Avatar src={record.avatarUrl} />
      <Text>{record.displayName}</Text>
    </Space>
  );
};
