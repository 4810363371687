import React from "react";
import { Avatar, Space } from "antd";
import { TextField } from "@refinedev/antd";
import { useOne } from "@refinedev/core";
import { IContributor } from "../../interfaces";

type ContributorInfoProps = {
  id: string;
};

export const ContributorInfo: React.FC<ContributorInfoProps> = ({ id }) => {
  const { data } = useOne<IContributor>({
    resource: `contributors`,
    dataProviderName: "defaultV2",
    id: id,
  });
  const record = data?.data;

  return (
    <Space>
      <Avatar size="small" src={record?.avatar} />
      <TextField value={record?.name} />
    </Space>
  );
};
