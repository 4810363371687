import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, InputNumber, Select } from "antd";
import { bookTypes, suggestSexOptions } from "../../constants";

export const CollectionEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    dataProviderName: "defaultV2",
  });

  return (
    <Edit title="Chỉnh sửa" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mô tả"
          name="description"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mô tả",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Thứ tự"
          name="order"
          initialValue={0}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập thứ tự",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="Danh sách"
          name="suggestSex"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập loại danh sách",
            },
          ]}
        >
          <Select options={suggestSexOptions} />
        </Form.Item>
        <Form.Item
          label="Loại truyện"
          name="bookType"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập loại truyện",
            },
          ]}
        >
          <Select options={bookTypes} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
