import React from "react";
import { useOne } from "@refinedev/core";
import { IChapter } from "../interfaces";
import { Popover, Typography } from "antd";
import { BookRow } from "./BookRow";

const { Paragraph } = Typography;

type Props = {
  chapterId: string;
};

export const ChapterInformation: React.FC<Props> = ({ chapterId }) => {
  const { data } = useOne<IChapter>({
    resource: "chapter",
    id: chapterId,
  });

  const record = data?.data;

  return (
    <div
      style={{
        maxWidth: 240,
      }}
    >
      <Popover content={<BookRow bookId={record?.bookId ?? ""} />}>
        <Paragraph
          ellipsis={true}
          color="#1890ff"
          style={{
            color: "#1890ff",
            cursor: "pointer",
            margin: 0,
          }}
        >
          {record?.name ?? ""}
        </Paragraph>
      </Popover>
    </div>
  );
};
