import React, { FC } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Breadcrumb, Create, useForm } from "@refinedev/antd";
import { TreeSelect, Typography } from "antd";
import { IBook } from "../../interfaces";
import { BookCreateForm } from "../../components/books/BookCreateForm";

export const BookCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IBook>({});

  return (
    <Create
      title="Thêm truyện"
      saveButtonProps={saveButtonProps}
      headerProps={{ breadcrumb: <Breadcrumb hideIcons /> }}
    >
      <BookCreateForm type={"novel"} formProps={formProps} />
    </Create>
  );
};
