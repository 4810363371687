import {
  DeleteOutlined,
  DownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useDelete } from "@refinedev/core";
import { Button, Dropdown, MenuProps, Space } from "antd";
import React from "react";
import { BsSkipForward } from "react-icons/bs";
import { TiTick } from "react-icons/ti";

export const EditJobActionButton: React.FC<{
  id: string;
  onRefresh: () => void;
}> = ({ id, onRefresh }) => {
  const { mutate } = useDelete();

  function handleMenuClick(key: string) {
    switch (key) {
      case "3":
        mutate(
          {
            dataProviderName: "defaultV2",
            resource: "editor-jobs",
            id: id,
            successNotification(data, values, resource) {
              return {
                message: "Xoá thành công",
                description: `Đã xoá thành công`,
                type: "success",
              };
            },
          },
          {
            onSettled(data, error, variables, context) {
              onRefresh();
            },
          }
        );
        break;
    }
  }

  const items: MenuProps["items"] = [
    {
      label: "Thông tin & chỉnh sửa",
      key: "0",
      icon: <InfoCircleOutlined />,
    },
    // {
    //   label: "Duyệt chương",
    //   key: "1",
    //   icon: <TiTick />,
    // },
    {
      label: "Giục",
      key: "2",
      icon: <BsSkipForward />,
    },
    // {
    //   label: "Lương",
    //   key: "2",
    //   icon: <DollarOutlined />,
    // },
    // {
    //   label: "Reset mật khẩu",
    //   key: "3",
    //   icon: <RedoOutlined />,
    // },
    // {
    //   label: "Gửi thư",
    //   key: "4",
    //   icon: <MailOutlined />,
    // },
    // {
    //   label: "Điều chỉnh quyền",
    //   key: "5",
    //   icon: <TrophyOutlined />,
    // },
    {
      label: "Xoá",
      key: "3",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const menuProps: MenuProps = {
    items,
    onClick: ({ key }) => handleMenuClick(key),
  };

  return (
    <>
      <Space>
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Hành động
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Space>
    </>
  );
};
