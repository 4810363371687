import React, { FC, useRef } from "react";
import { useList } from "@refinedev/core";
import { getValueFromEvent, useSelect } from "@refinedev/antd";

import {
  Button,
  Divider,
  Form,
  FormProps,
  Input,
  Select,
  Space,
  Switch,
  TreeSelect,
  Typography,
  Upload,
} from "antd";

import { useTreeCategoriesSelect } from "../../hooks/treeHook";
import type { InputRef } from "antd";
import { IAuthor, IBook } from "interfaces";
import { PlusOutlined } from "@ant-design/icons";
import { bookTypes } from "data/book";

const { Text } = Typography;
const { SHOW_PARENT } = TreeSelect;

type Props = {
  type: string;
  formProps: FormProps;
  queryResult: any;
};

export const BookEditForm: FC<Props> = ({ type, queryResult, formProps }) => {
  const authorNameRef = useRef<InputRef>(null);

  const { data } = useList({
    resource: "category/tree-select",

    queryOptions: {
      enabled: queryResult?.data?.data != null,
    },

    filters: [
      {
        field: "bookId",
        operator: "eq",
        value: queryResult?.data?.data?._id,
      },
      {
        field: "bookTypes",
        operator: "eq",
        value: type,
      },
    ]
  });

  const { treeData, onLoadData } = useTreeCategoriesSelect({
    editMode: false,
    initCategories: data?.data,
    bookTypes: type,
  });

  const { selectProps: tagsSelectProps } = useSelect({
    resource: "tag",
    fetchSize: 10,
    optionLabel: "value",
    optionValue: "_id",

    queryOptions: {
      enabled: queryResult?.data != null,
      onSuccess: (data) => {
        const tags = queryResult?.data?.data.tags;
        data.data.push.apply(tags);
      },
    },

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: authorSelectProps } = useSelect<IAuthor>({
    resource: "authors",
    dataProviderName: "defaultV2",
    optionLabel: "name",
    optionValue: "_id",
    pagination: {
      pageSize: 20,
    },
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
    defaultValue: queryResult?.data?.data?.author?._id,
    defaultValueQueryOptions: {
      enabled: queryResult?.data?.data != null,
    },
  });

  const { selectProps: novelSelectBook } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 20,

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "novel",
      },
    ],

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: comicSelectBook } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 20,

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "comic",
      },
    ],

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: audioSelectBook } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 20,

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "audio",
      },
    ],

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  const book = queryResult?.data?.data;
  const bookType = book?.type ?? "novel";

  const getBookSelectProps = (type: "novel" | "comic" | "audio") => {
    switch (type) {
      case "novel":
        return novelSelectBook;

      case "comic":
        return comicSelectBook;

      case "audio":
        return audioSelectBook;

      default:
        return novelSelectBook;
    }
  };

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Phát hành" name="published" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item
        label="Ảnh bìa"
        name="cover"
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Upload
          name="images"
          listType="picture-card"
          action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
          maxCount={1}
          multiple={false}
          accept=".png,.jpeg,.jpg"
        >
          <Text>Drag & drop image to upload</Text>{" "}
        </Upload>
      </Form.Item>
      <Form.Item
        label="Tên"
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Mô tả ngắn"
        name="shortDescription"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea maxLength={150} showCount />
      </Form.Item>
      <Form.Item
        label="Mô tả"
        name="description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="Tác giả"
        name="author"
        getValueProps={(value) => {
          if (!value) return { value: [] };

          if (value.length === 0) return { value: [] };

          if (typeof value[0] === "string") return { value: value };

          return {
            value: value._id,
          };
        }}
      >
        <Select
          placeholder="Chọn tác giả"
          {...authorSelectProps}
          allowClear
          dropdownRender={(menu) => (
            <>
              {menu}
              <>
                <Divider style={{ margin: "8px 0" }} />
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column nowrap",
                  }}
                >
                  <Text
                    style={{
                      padding: "0 8px 4px",
                    }}
                  >
                    Không tìm thấy tác giả?
                  </Text>
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input placeholder="Nhập tên tác giả" ref={authorNameRef} />
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {}}
                    >
                      Thêm
                    </Button>
                  </Space>
                </div>
              </>
            </>
          )}
        />
      </Form.Item>
      <Form.Item
        label="Danh mục"
        name="categories"
        getValueProps={(value) => {
          if (!value) return { value: [] };

          if (value.length === 0) return { value: [] };

          if (typeof value[0] === "string") return { value: value };

          return {
            value: value.map((i: any) => i._id),
          };
        }}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TreeSelect
          placeholder="Chọn danh mục cha"
          multiple={true}
          treeDataSimpleMode
          treeData={treeData}
          loadData={async (treeNode) => onLoadData(treeNode.id)}
          treeCheckable
          showCheckedStrategy={SHOW_PARENT}
        />
      </Form.Item>
      <Form.Item
        label="Thẻ"
        name="tags"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select placeholder="Thêm tags" {...tagsSelectProps} mode="tags" />
      </Form.Item>
      <Form.Item
        label="Trạng thái"
        name="status"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          options={[
            { name: "UPDATING", value: "UPDATING" },
            {
              name: "COMPLETED",
              value: "COMPLETED",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        initialValue={type}
        hidden={true}
        name="type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        {/*<Select placeholder="Thêm tags" {...tagsSelectProps} mode="tags" />*/}
      </Form.Item>

      {/* {bookTypes.map((type, index) => (
        <Form.Item
          key={index}
          label={`Variant ${type}`}
          name={["variants", type]}
          hidden={bookType === type}
        >
          <Select {...getBookSelectProps((type as any) ?? "novel")} />
        </Form.Item>
      ))} */}
    </Form>
  );
};
