import React from "react";
import { IAnalyticsEditorJob } from "../../interfaces";
import { Card, Space, Statistic } from "antd";
import { Area } from "@ant-design/plots";
import { currencyFormatter } from "../../utils";

type MoneyDashboardCardProps = {
  props: IAnalyticsEditorJob[];
};

export const MoneyDashboardCard: React.FC<MoneyDashboardCardProps> = ({
  props,
}) => {
  const data = props.map((item) => {
    return {
      ...item,
      partner: item.editorId.name,
    };
  });
  const money = props.reduce((acc, cur) => {
    return acc + cur.earn;
  }, 0);

  const config = {
    data: data,
    xField: "date",
    yField: "earn",
    seriesField: "partner",
    xAxis: {
      type: "time",
      //   tickCount: 5,
      mask: "DD/MM/YYYY",
    },
    yAxis: {
      label: {
        formatter: function formatter(v: any) {
          const value = ""
            .concat(v)
            .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return "".concat(s, ",");
            });

          return "".concat(value, " đ");
        },
      },
    },
    tooltip: {
      formatter: function formatter(datum: any) {
        return {
          name: "Số tiền",
          value: currencyFormatter(datum.earn),
        };
      },
    },
  };

  return (
    <Card>
      <Space
        direction="vertical"
        style={{
          display: "flex",
        }}
        size="large"
      >
        <Space>
          <Statistic title="Số tiền (đ)" value={money} />
        </Space>

        <Area {...config} />
      </Space>
    </Card>
  );
};
