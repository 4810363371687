import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton } from "antd";

export const InfinityScroller: React.FC<{
  children: React.ReactNode;
  itemLength: number;
  fetch: () => void;
  hasMore: boolean;
}> = ({ children, itemLength, fetch, hasMore }) => {
  return (
    <div
      id="scrollableDiv"
      style={{
        height: 640,
        overflow: "auto",
        padding: "0 16px",
        border: "1px solid rgba(140, 140, 140, 0.35)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <InfiniteScroll
        dataLength={itemLength}
        next={() => {
          console.log("next");
          fetch();
        }}
        style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
        inverse={true}
        hasMore={hasMore}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        initialScrollY={400}
        scrollableTarget="scrollableDiv"
        onScroll={() => {
          console.log("scroll");
        }}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};
