import { CheckOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  DateField,
  DeleteButton,
  FilterDropdown,
  List,
  TagField,
  useModalForm,
  useTable,
  useSelect,
} from "@refinedev/antd";
import {
  Button,
  Card,
  Form,
  Grid,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
} from "antd";
import {
  IResourceComponentsProps,
  useCreate,
  useNavigation,
} from "@refinedev/core";
import { IAudioTask, IBook } from "interfaces";
import { genAudioStatus, getAudioStatus } from "utils";
import React from "react";
import { MdAutoFixHigh } from "react-icons/md";
import { ModalAudioTaskCreateRange } from "../../components/modal/ModalAudioTaskCreateRange";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { ModalAudioTaskPublish } from "components/modal/ModalAudioTaskPublish";

export const AudioTaskList: React.FC<IResourceComponentsProps> = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { tableProps, tableQueryResult, searchFormProps } =
    useTable<IAudioTask>({
      dataProviderName: "defaultV2",
      onSearch: (params: any) => {
        const filters: any[] = [];
        const { bookId } = params;

        filters.push({
          field: "bookId",
          operator: "eq",
          value: bookId,
        });

        return filters;
      },
    });

  const { mutate: mutatePublishing, isLoading: isPublishing } = useCreate({});
  const nav = useNavigation();
  const {
    modalProps: modalCreateRangeProps,
    formProps: formCreateRangeProps,
    show: showModalCreateRange,
  } = useModalForm({
    action: "create",
    resource: "audio-tasks/create-range",
    dataProviderName: "defaultV2",
    redirect: false,
    onMutationSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  const {
    modalProps: modalPublishProps,
    formProps: formPublishProps,
    show: showPublishModal,
  } = useModalForm({
    action: "create",
    resource: "audio-tasks/bulk-publish",
    dataProviderName: "defaultV2",
    redirect: false,
    onMutationSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "audio",
      },
    ],
    onSearch: (q) => {
      const filters: any[] = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const breakpoint = Grid.useBreakpoint();

  const handlePublish = (id: string) => {
    mutatePublishing(
      {
        dataProviderName: "defaultV2",
        resource: `audio-tasks/${id}/publish`,
        values: {},
        successNotification: false,
      },
      {
        onSuccess: () => {
          messageApi.open({
            type: "success",
            content: "Phát hành thành công",
          });
          tableQueryResult.refetch();
        },
      }
    );
  };

  return (
    <List
      headerButtons={[
        <Button
          icon={<IoCheckmarkDoneSharp />}
          type="primary"
          onClick={() => showPublishModal()}
        >
          Xuất bản
        </Button>,
        <Button
          icon={<MdAutoFixHigh />}
          type="primary"
          onClick={() => showModalCreateRange()}
        >
          Tự động
        </Button>,
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={() => nav.create("audio-tasks")}
        >
          Tạo mới
        </Button>,
      ]}
    >
      {contextHolder}
      <Card
        style={{
          marginBottom: 16,
        }}
        title="Lọc"
      >
        <Form {...searchFormProps} layout="vertical">
          <Form.Item label="Truyện" name="bookId">
            <Select {...bookSelectProps} allowClear />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" type="primary">
              Lọc
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Table
        {...tableProps}
        rowKey="_id"
        expandable={{
          expandedRowRender: !breakpoint.xs ? expandedRowRender : undefined,
        }}
      >
        <Table.Column dataIndex={["book", "name"]} title="Truyện" />
        <Table.Column dataIndex="rawNumber" title="STT Chữ" />
        <Table.Column dataIndex="chapterName" title="Tên chương" />
        <Table.Column
          dataIndex="createdAt"
          title="Ngày tạo"
          render={(value) => {
            return <DateField value={value} format="HH:mm - DD/MM/YYYY" />;
          }}
        />
        <Table.Column<IAudioTask>
          dataIndex="progress"
          title="Tiến trình"
          render={(value, record) => {
            return (
              <div>
                {record.files.length}/{record.totalParts}
              </div>
            );
          }}
        />
        {/* <Table.Column
          dataIndex="files"
          title="Audio"
          render={(value) => {
            // if (value.length === 0) return <div>....</div>;

            return (
              <audio
                src={
                  "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3"
                }
                controls
              ></audio>
            );
          }}
        /> */}
        <Table.Column
          dataIndex="status"
          title="Trạng thái"
          render={(value) => {
            const status = getAudioStatus(value);
            return <TagField value={status.label} color={status.color} />;
          }}
          filterDropdown={(props) => {
            return (
              <FilterDropdown
                {...props}
                mapValue={(selectedKeys) => selectedKeys.map(String)}
              >
                <Select
                  options={genAudioStatus}
                  mode="multiple"
                  style={{ minWidth: 200 }}
                />
              </FilterDropdown>
            );
          }}
        />
        <Table.Column
          dataIndex="actions"
          title="Hành động"
          render={(value: any, record: any) => {
            return (
              <Space>
                {record.status === "done" && (
                  <Button
                    color="success"
                    size="small"
                    icon={<CheckOutlined />}
                    onClick={() => handlePublish(record._id)}
                  >
                    Phát hành
                  </Button>
                )}
                {record.status !== "publish-queue" && (
                  <DeleteButton
                    size="small"
                    hideText
                    recordItemId={record._id}
                    dataProviderName="defaultV2"
                  />
                )}
              </Space>
            );
          }}
        />
      </Table>

      <ModalAudioTaskCreateRange
        modalProps={modalCreateRangeProps}
        formProps={formCreateRangeProps}
      />
      <ModalAudioTaskPublish
        modalProps={modalPublishProps}
        formProps={formPublishProps}
      />
    </List>
  );
};

const AudioTaskExpandedRow: React.FC<{ record: IAudioTask }> = ({ record }) => {
  if (record.composeFile == null) return <div>Chưa có Audio</div>;

  return (
    <div>
      <audio src={record.composeFile.url} controls></audio>
    </div>
  );
};

const expandedRowRender = (record: IAudioTask) => {
  return <AudioTaskExpandedRow record={record} />;
};
