import React, { FC } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { List, useEditableTable } from "@refinedev/antd";
import { Tabs } from "antd";
import { IBanner } from "../../interfaces";
import BannerTable from "../../components/banners/BannerTable";

export const BannerList: FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    isEditing,
    setId,
  } = useEditableTable<IBanner>({});

  return (
    <List>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane key="1" tab="Truyện chữ">
          <BannerTable type="novel" />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Truyện Tranh">
          <BannerTable type="comic" />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="Truyện Audio">
          <BannerTable type="audio" />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab="Truyện ngắn">
          <BannerTable type="short-novel" />
        </Tabs.TabPane>
      </Tabs>
    </List>
  );
};
