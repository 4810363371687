import { Form, FormProps, Input, InputNumber, Modal, ModalProps } from "antd";
import { useEffect } from "react";

export const ModalLockMultipleChapters: React.FC<{
  isLock: boolean;
  modalProps: ModalProps;
  formProps: FormProps;
  bookId: string;
}> = ({ modalProps, formProps, isLock, bookId }) => {
  const renderTitle = () => {
    if (isLock) {
      return "Khoá nhiều chapter";
    }
    return "Mở khoá nhiều chapter";
  };

  useEffect(() => {
    formProps.form?.setFieldsValue({
      bookId,
    });
  }, [bookId, formProps.form]);

  return (
    <Modal
      {...modalProps}
      centered
      width={480}
      title={renderTitle()}
      destroyOnClose
    >
      <Form {...formProps} layout="vertical">
        <Form.Item name="bookId" hidden>
          <Input />
        </Form.Item>
        <Form.Item hidden name="isLock" initialValue={isLock} />
        <Form.Item
          label="Từ số"
          name="from"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập không để trống",
            },
          ]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Đến số"
          name="to"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập không để trống",
            },
          ]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          tooltip="Để trống nếu dùng giá mặc định"
          label="Giá"
          initialValue={0}
          name="price"
          hidden={!isLock}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
