import {
  DateField,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  ShowButton,
  TagField,
  TextField,
  useModal,
  useTable,
} from "@refinedev/antd";

import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
} from "antd";
import { CrudFilters, IResourceComponentsProps } from "@refinedev/core";
import { IReceipt } from "interfaces";
import React, { FC } from "react";
import { formatVND } from "utils";
import UserTableRow from "components/user/UserTableRow";
import IAPProductField from "components/fileds/IAPProductField";
import {
  EditOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import ModalCreatePaymentReceipt from "components/modal/ModalCreatePaymentReceipt";

const processStatusTag = (status: string) => {
  switch (status) {
    case "PENDING":
      return {
        label: "Đang chờ xử lý",
        color: "orange",
      };

    case "SUCCESS":
      return {
        label: "Thành công",
        color: "green",
      };

    case "FAILED":
      return {
        label: "Thất bại",
        color: "red",
      };

    default:
      return {
        label: "Không xác định",
        color: "default",
      };
  }
};

export const ReceiptList: FC<IResourceComponentsProps> = () => {
  const { show, modalProps } = useModal();

  const {
    tableProps,
    sorters: sorter,
    searchFormProps,
  } = useTable<IReceipt>({
    resource: "receipts",
    dataProviderName: "defaultV2",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];

      const { status, qReceipt } = params;

      filters.push(
        {
          field: "status",
          operator: "in",
          value: status,
        },
        {
          field: "qReceipt",
          operator: "eq",
          value: qReceipt,
        }

        // {
        //   field: "createdAt",
        //   operator: "gte",
        //   value: createdAt ? createdAt[0].toISOString() : undefined,
        // },
        // {
        //   field: "createdAt",
        //   operator: "lte",
        //   value: createdAt ? createdAt[1].toISOString() : undefined,
        // },
        // {
        //   field: "feature",
        //   operator: "eq",
        //   value: feature,
        // },
        // {
        //   field: "category",
        //   operator: "in",
        //   value: category,
        // }
      );

      return filters;
    },

    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
  });

  return (
    <List
      title="Hoá đơn giao dịch"
      headerButtons={[
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={() => show()}
        >
          Tạo hoá đơn
        </Button>,
      ]}
    >
      <Card title="Lọc" style={{ marginBottom: "16px" }}>
        <Form {...searchFormProps} layout="vertical">
          <Form.Item label="Mã giao dịch" name="qReceipt">
            <Input placeholder="Nhập mã giao dịch để tìm kiếm" />
          </Form.Item>
          <Form.Item label="Trạng thái" valuePropName="checked" name="status">
            <Checkbox.Group
              options={[
                {
                  label: "Đang chờ xử lý",
                  value: "PENDING",
                },
                {
                  label: "Thành công",
                  value: "SUCCESS",
                },
                {
                  label: "Thất bại",
                  value: "FAILED",
                },
              ]}
              defaultValue={["Apple"]}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Lọc
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Table {...tableProps}>
        <Table.Column dataIndex="receiptId" title="Mã giao dịch" />
        <Table.Column
          dataIndex="userId"
          title="Tài khoản"
          render={(value) => {
            return <UserTableRow id={value} />;
          }}
        />
        <Table.Column
          dataIndex="productId"
          title="Sản phẩm"
          sorter
          // defaultSortOrder={getDefaultSortOrder("product.amount", sorter)}
          render={(value) => {
            return <IAPProductField id={value} />;
          }}
        />
        <Table.Column
          dataIndex="amount"
          title="Số tiền"
          sorter
          defaultSortOrder={getDefaultSortOrder("amount", sorter)}
          render={(value) => {
            return <TextField value={`${formatVND(value)}đ`} />;
          }}
        />
        <Table.Column
          dataIndex="status"
          title="Trạng thái"
          render={(value) => {
            const tag = processStatusTag(value);
            return <TagField value={tag.label} color={tag.color} />;
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Tạo lúc"
          sorter
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          render={(value) => {
            return <DateField value={value} format="HH:mm, DD/MM/YYYY" />;
          }}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Cập nhật lần cuối"
          sorter
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          render={(value) => {
            return <DateField value={value} format="HH:mm, DD/MM/YYYY" />;
          }}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Ngày thanh toán"
          sorter
          defaultSortOrder={getDefaultSortOrder("payAt", sorter)}
          render={(value) => {
            if (!value) {
              return <TextField value="Chưa thanh toán" />;
            }

            return <DateField value={value} format="HH:mm, DD/MM/YYYY" />;
          }}
        />
        <Table.Column<IReceipt>
          dataIndex="actions"
          title="Hành động"
          render={(value, record) => {
            return (
              <Space>
                <ShowButton
                  recordItemId={record.receiptId}
                  hideText
                  size="small"
                />
                <DeleteButton
                  recordItemId={record.receiptId}
                  hideText
                  size="small"
                />
              </Space>
            );
          }}
        />
      </Table>
      <ModalCreatePaymentReceipt modalProps={modalProps} />
    </List>
  );
};
