import React from "react";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  Tree,
} from "antd";

import { useTreeList } from "../../hooks/treeHook";

const { RangePicker } = DatePicker;

export const BookFiltering: React.FC<{
  searchFormProps: FormProps;
  bookTypes: string;
}> = ({ searchFormProps, bookTypes }) => {
  //*** Tree Categories Hook */
  const { treeData, loadChild } = useTreeList({ bookTypes: bookTypes });

  return (
    <Card style={{ marginBottom: 16 }}>
      <Form layout="vertical" {...searchFormProps}>
        <Row gutter={24} wrap>
          <Col></Col>
          <Col>
            <Form.Item label="Tìm kiếm" name="q">
              <Input
                placeholder="ID, Tên truyện, Mô tả, ...."
                prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Ngày tạo" name="createdAt">
              <RangePicker />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Đề cử" name="feature" valuePropName="checked">
              <Checkbox>Truyện đề cử</Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Vip"
              name="hasLockChapters"
              valuePropName="checked"
            >
              <Checkbox>Vip</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} style={{ paddingLeft: "24px", paddingRight: "24px" }}>
          <Col>
            <Collapse style={{ marginBottom: "24px", minWidth: "720px" }}>
              <Collapse.Panel header="Lọc theo thể loại" key="1">
                <Form.Item label="Thể loại" name="category">
                  <Tree
                    checkable
                    treeData={treeData}
                    loadData={async ({ key, children }: any) => {
                      loadChild(key);
                    }}
                    onCheck={(checkedKeys, e) => {
                      console.log(e);

                      searchFormProps.form?.setFieldsValue({
                        category: checkedKeys,
                      });
                    }}
                  />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            Tìm
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
