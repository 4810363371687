import React from "react";
import { IResourceComponentsProps, useList, useShow } from "@refinedev/core";
import { DateField, Show, useTable } from "@refinedev/antd";
import { Avatar, Space, Table, Typography } from "antd";
import { IGiftCode } from "../../interfaces";
import { getItemDetail } from "../../utils";

const { Text, Title } = Typography;

export const GiftCodeShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IGiftCode>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { tableProps: useByTableProps, tableQueryResult } = useTable({
    resource: "gift-code/use-by",

    queryOptions: {
      enabled: record != null,
    },

    filters: {
      permanent: [
        {
          field: "id",
          operator: "eq",
          value: record?._id,
        },
      ]
    }
  });

  const { data: userDataList } = useList({
    resource: "user",

    queryOptions: {
      enabled: tableQueryResult?.data?.data != null,
    },

    filters: [
      {
        field: "ids",
        operator: "in",
        value: tableQueryResult?.data?.data?.map((item) => item.id),
      },
    ]
  });

  return (
    <Show isLoading={isLoading} title="Chi tiết">
      <Title level={3}>{record?.code}</Title>

      <Title level={5}>Ngày tạo</Title>
      <Text>
        <DateField value={record?.createdAt} format="DD/MM/YYYY - HH:mm" />
      </Text>

      <Title level={5}>Ngày hết hạn</Title>
      <Text>
        <DateField value={record?.expiredAt} format="DD/MM/YYYY - HH:mm" />
      </Text>

      <Title level={5}>Tổng lượt dùng</Title>
      <Text>{record?.maxUse}</Text>

      <Title level={5}>Phần thưởng</Title>
      <Table dataSource={record?.rewards} rowKey="type">
        <Table.Column<any>
          dataIndex="type"
          title="Nhóm"
          render={(value, record, index) => {
            if (value === "ticket") {
              return (
                <div>
                  Vé {record.itemData.id} - {record.itemData.lifeTime} (Giờ)
                </div>
              );
            }

            return <div>{getItemDetail(value).name}</div>;
          }}
        />
        <Table.Column dataIndex="amount" title="Số lượng" />
      </Table>

      <Title level={5}>Người dùng</Title>
      <Table {...useByTableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title="Thông tin"
          render={(value) => {
            const user = userDataList?.data?.find((item) => item._id === value);

            return (
              <Space direction="horizontal" align="baseline">
                <Avatar src={user?.avatarUrl} />
                <p>{user?.displayName}</p>
              </Space>
            );
          }}
        />
        <Table.Column
          dataIndex="at"
          title="Redeem At"
          render={(value) => {
            return <DateField value={value} format="DD/MM/YYYY - HH:mm" />;
          }}
        />
      </Table>
    </Show>
  );
};
