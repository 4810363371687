import { Create, useForm, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, InputNumber, Select, Typography, Upload } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { ticketsFuncs } from "../../constants";
import { ITicket } from "interfaces";
import React from "react";

const { Title, Text } = Typography;

export const TicketCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ITicket>({});

  return (
    <Create title="Tạo vé" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Hình ảnh"
          name="icon"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
        >
          <Upload
            name="images"
            listType="picture-card"
            action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
            maxCount={5}
            multiple
            accept=".png,.jpeg,.jpg,.webp"
          >
            <Text>Drag & drop image to upload</Text>{" "}
          </Upload>
        </Form.Item>
        <Form.Item name="func" label="Loại vé" rules={[{ required: true }]}>
          <Select options={ticketsFuncs} />
        </Form.Item>
        <Form.Item name="name" label="Tên" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="desc" label="Mô tả" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="coinPrice"
          label="Giá coin"
          rules={[{ required: true }]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name="pointPrice"
          label="Giá điểm"
          rules={[{ required: true }]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name="lifeTimeDays"
          label="Số ngày tồn tại"
          rules={[{ required: true }]}
          initialValue={90}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Title level={5}>Tuỳ chọn</Title>
        <Form.Item
          name={["additionData", "funcMinute"]}
          label="Thời gian tác dụng (Giờ)"
          tooltip="Hạn của vé sau khi người dùng sử dụng (Để trống nếu không có)"
        >
          <InputNumber min={0} />
        </Form.Item>
      </Form>
    </Create>
  );
};
