import React from "react";
import { useModalForm } from "@refinedev/antd";
import { Button, Form, Modal, Select, Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  blackListReasonOptions,
  timeBlackListOptions,
} from "../../../data/chat";

export const ButtonBanChat: React.FC<{
  userId: string;
  onSuccess: Function;
}> = ({ userId, onSuccess }) => {
  const { show, modalProps, formProps } = useModalForm({
    resource: "chat-blacklist",
    action: "create",
    // defaultFormValues: {
    //   userId,
    //   reasons: "SPAM",
    //   time: 0,
    // },
    redirect: false,
    onMutationSuccess: () => {
      onSuccess();
    },
    successNotification: () => {
      return {
        message: "Thằng này đã bị khoá mõm",
        description: "Khoá mõm thành công",
        type: "success",
      };
    },
  });

  return (
    <>
      <Tooltip title="Khoá mõm">
        <Button
          size="small"
          type="text"
          icon={<CloseCircleOutlined />}
          onClick={() => show()}
        />
      </Tooltip>
      <Modal
        {...modalProps}
        centered
        width={480}
        title="Khoá mõm thằng này"
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <Form {...formProps} layout="vertical">
          <Form.Item name="userId" hidden initialValue={userId} />
          <Form.Item
            name="reason"
            label="Lý do"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn lý do khoá mõm",
              },
            ]}
          >
            <Select options={blackListReasonOptions} />
          </Form.Item>
          <Form.Item
            name="time"
            label="Thời gian"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn thời gian khoá mõm",
              },
            ]}
          >
            <Select options={timeBlackListOptions} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
