import React from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { TiTick } from "react-icons/ti";
import { RiCloseLine } from "react-icons/ri";
import { useCreate, useDelete } from "@refinedev/core";
import { BiTrash } from "react-icons/bi";

type ReviewingTableActionsProps = {
  id: string;
  status: string;
  onRefreshTable: () => void;
};

export const ReviewingTableActions: React.FC<ReviewingTableActionsProps> = ({
  id,
  status,
  onRefreshTable,
}) => {
  const { mutate } = useCreate();
  const { mutate: mutateDelete } = useDelete();

  const items: MenuProps["items"] = [];

  if (status === "PENDING") {
    items.push({
      label: "Duyệt",
      key: "approve",
      icon: <TiTick />,
    });

    items.push({
      label: "Từ chối",
      key: "reject",
      danger: true,
      icon: <RiCloseLine />,
    });
  }

  if (status === "APPROVED") {
    items.push({
      label: "Huỷ phát hành",
      key: "reject",
      danger: true,
      icon: <RiCloseLine />,
    });
  }

  if (status === "REJECTED") {
    items.push({
      label: "Phát hành",
      key: "approve",
      icon: <TiTick />,
    });

    items.push({
      label: "Xoá",
      key: "remove",
      danger: true,
      icon: <BiTrash />,
    });
  }

  const handleMenuClick: MenuProps["onClick"] = async (e) => {
    if (e.key === "approve") {
      mutate(
        {
          resource: `reviewing-books/${id}/approve`,
          values: {},
          dataProviderName: "defaultV2",
          successNotification: (data) => {
            return {
              message: "Duyệt thành công",
              description: "Truyện đã được duyệt thành công",
              type: "success",
            };
          },
        },
        {
          onSuccess: () => {
            onRefreshTable();
          },
        }
      );
    } else if (e.key === "reject") {
      mutate(
        {
          resource: `reviewing-books/${id}/reject`,
          values: {},
          dataProviderName: "defaultV2",
          successNotification: (data) => {
            return {
              message: "Từ chối thành công",
              description: "Truyện đã được từ chối thành công",
              type: "success",
            };
          },
        },
        {
          onSuccess: () => {
            onRefreshTable();
          },
        }
      );
    } else if (e.key === "remove") {
      mutateDelete(
        {
          dataProviderName: "defaultV2",
          resource: `reviewing-books`,
          id,
          successNotification: (data) => {
            return {
              message: "Xoá thành công",
              description: "Truyện đã được xoá thành công",
              type: "success",
            };
          },
        },
        {
          onSuccess: () => {
            onRefreshTable();
          },
        }
      );
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      {items.length > 0 && (
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Hành động
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )}
    </>
  );
};
