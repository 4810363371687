import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { EditButton, List, SaveButton, TagField, TextField, useEditableTable } from "@refinedev/antd";
import { Button, Col, Form, InputNumber, Row, Space, Table } from "antd";
import { ICheckin } from "../../../interfaces";

export const CheckinList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    isEditing,
  } = useEditableTable<ICheckin>({
    resource: "activity/checkin",
  });

  return (
    <List title="Checkin List">
      <Form {...formProps}>
        <Table {...tableProps} rowKey="_id">
          <Table.Column<ICheckin>
            dataIndex="day"
            title="Ngày"
            key="day"
            render={(value, record) => {
              return <TextField value={value} />;
            }}
          />
          <Table.Column<ICheckin>
            dataIndex="rewards"
            title="Phần thưởng"
            key="rewards"
            render={(value, record) => {
              if (isEditing(record.day.toString())) {
                return (
                  <Row>
                    <Form.Item
                      name={["rewards", "coin"]}
                      label="Coin"
                      style={{ marginBottom: 8 }}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                      name={["rewards", "exp"]}
                      label="Exp"
                      style={{ margin: 0 }}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                  </Row>
                );
              }

              return (
                <Col>
                  <Row style={{ marginBottom: "8px" }}>
                    <Col span={12}>
                      <TextField value={"Coin"} style={{ marginRight: 10 }} />
                      <TagField value={value["coin"] ?? 0} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <TextField value={"Exp"} style={{ marginRight: 10 }} />
                      <TagField value={value["exp"] ?? 0} />
                    </Col>
                  </Row>
                </Col>
              );
            }}
          />
          <Table.Column<ICheckin>
            dataIndex="actions"
            title="Hành động"
            render={(value, record) => {
              if (isEditing(record.day.toString())) {
                return (
                  <Space>
                    <SaveButton {...saveButtonProps} size="small" />
                    <Button {...cancelButtonProps} size="small">
                      Cancel
                    </Button>
                  </Space>
                );
              }

              return (
                <Space>
                  <EditButton
                    {...editButtonProps(record.day.toString())}
                    size="small"
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </List>
  );
};
