import { DeleteButton, EditButton } from "@refinedev/antd";
import { Popover, Space, Spin } from "antd";
import { useOne } from "@refinedev/core";
import { ICategory } from "interfaces";
import React, { FC, ReactNode } from "react";

const CategoryTreeNodeTitle: FC<{
  slugId: string;
  children: ReactNode;
  onDeleteSuccess: Function;
}> = ({ slugId, children, onDeleteSuccess }) => {
  const { data, isLoading } = useOne<ICategory>({
    resource: "category",
    id: slugId,
  });

  const record = data?.data;

  return (
    <Popover
      content={
        isLoading ? (
          <Spin />
        ) : (
          <Space
            direction="vertical"
            style={{
              display: "flex",
            }}
          >
            <p>{record?.description}</p>
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "24px",
              }}
            >
              <EditButton size="small" hideText recordItemId={record?.slugId} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={record?.slugId}
                onSuccess={() => onDeleteSuccess()}
              />
            </Space>
          </Space>
        )
      }
      title={record?.name}
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default CategoryTreeNodeTitle;
