import React, { FC, useRef } from "react";
import { getValueFromEvent, useSelect } from "@refinedev/antd";

import {
  Button,
  Divider,
  Form,
  FormProps,
  Input,
  Select,
  Space,
  TreeSelect,
  Typography,
  Upload,
} from "antd";

import { useTreeCategoriesSelect } from "../../hooks/treeHook";
import { IAuthor } from "interfaces";
import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { useCreate } from "@refinedev/core";

const { Text } = Typography;
const { SHOW_PARENT } = TreeSelect;

export const BookCreateForm: FC<{
  type: string;
  formProps: FormProps;
}> = ({ type, formProps }) => {
  const authorNameRef = useRef<InputRef>(null);

  const { treeData, onLoadData } = useTreeCategoriesSelect({
    editMode: false,
    bookTypes: type,
  });

  const { selectProps: tagsSelectProps } = useSelect({
    resource: "tag",
    fetchSize: 10,
    optionLabel: "value",
    optionValue: "_id",

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: authorSelectProps } = useSelect<IAuthor>({
    resource: "authors",
    dataProviderName: "defaultV2",
    optionLabel: "name",
    optionValue: "_id",
    pagination: {
      pageSize: 20,
    },
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  });

  const { mutate: mutateCreateAuthor } = useCreate<IAuthor>({});

  const handleCreateAuthor = () => {
    const name = authorNameRef.current?.input?.value;

    if (!name) return;

    mutateCreateAuthor({
      resource: "authors",
      dataProviderName: "defaultV2",
      values: {
        name: authorNameRef.current?.input?.value,
      },
    });

    // reset input value
    authorNameRef.current!.input!.value = "";
  };

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Ảnh bìa"
        name="cover"
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Upload
          name="images"
          listType="picture-card"
          action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
          maxCount={1}
          multiple={false}
          accept=".png,.jpeg,.jpg"
        >
          <Text>Drag & drop image to upload</Text>{" "}
        </Upload>
      </Form.Item>
      <Form.Item
        label="Tên"
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Mô tả ngắn"
        name="shortDescription"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea maxLength={150} showCount />
      </Form.Item>
      <Form.Item
        label="Mô tả"
        name="description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Tác giả" name="author">
        <Select
          placeholder="Chọn tác giả"
          {...authorSelectProps}
          allowClear
          dropdownRender={(menu) => (
            <>
              {menu}
              <>
                <Divider style={{ margin: "8px 0" }} />
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column nowrap",
                  }}
                >
                  <Text
                    style={{
                      padding: "0 8px 4px",
                    }}
                  >
                    Không tìm thấy tác giả?
                  </Text>
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input placeholder="Nhập tên tác giả" ref={authorNameRef} />
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleCreateAuthor}
                    >
                      Thêm
                    </Button>
                  </Space>
                </div>
              </>
            </>
          )}
        />
      </Form.Item>
      <Form.Item
        label="Danh mục"
        name="categories"
        rules={[
          {
            required: true,
          },
        ]}
      >
        {/* <Select
            placeholder="Chọn danh mục"
            {...categoriesSelectProps}
            mode="multiple"
          /> */}
        <TreeSelect
          placeholder="Chọn danh mục cha"
          multiple={true}
          treeDataSimpleMode
          treeData={treeData}
          loadData={async (treeNode) => onLoadData(treeNode.id)}
          treeCheckable
          showCheckedStrategy={SHOW_PARENT}
        />
      </Form.Item>
      <Form.Item
        label="Thẻ"
        name="tags"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select placeholder="Thêm tags" {...tagsSelectProps} mode="tags" />
      </Form.Item>
      <Form.Item
        initialValue={type}
        hidden={true}
        name="type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        {/*<Select placeholder="Thêm tags" {...tagsSelectProps} mode="tags" />*/}
      </Form.Item>
    </Form>
  );
};
