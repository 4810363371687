import { useSelect } from "@refinedev/antd";
import { useCreate } from "@refinedev/core";
import {
  Col,
  Form,
  Modal,
  ModalProps,
  Row,
  Select,
  Image,
  Statistic,
  Space,
} from "antd";
import React from "react";

type Props = {
  modalProps: ModalProps;
};

export default function ModalCreatePaymentReceipt({ modalProps }: Props) {
  const [form] = Form.useForm();
  const [receipt, setReceipt] = React.useState<any>(null);

  const { selectProps: userSelectProps } = useSelect({
    resource: "user",
    optionLabel: "displayName",
    optionValue: "_id",
    onSearch: (value) => {
      return [
        {
          field: "search",
          operator: "eq",
          value,
        },
      ];
    },
  });

  const { selectProps: iapProductsSelectProps } = useSelect({
    resource: "iap-products",
    optionLabel: "productId",
    optionValue: "_id",
    dataProviderName: "defaultV2",
    pagination: {
      pageSize: 100,
    },
  });

  const { mutateAsync, isLoading } = useCreate({});

  const handleOk = async (values: any) => {
    const { data } = await mutateAsync({
      resource: "receipts",
      values,
      dataProviderName: "defaultV2",
    });

    setReceipt(data?.data);
  };

  return (
    <Modal
      {...modalProps}
      title="Tạo hoá đơn"
      width={640}
      centered
      cancelText="Huỷ"
      okText="Tạo"
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{
        disabled: receipt !== null,
      }}
    >
      <Form layout="vertical" form={form} onFinish={handleOk}>
        <Form.Item
          label="Tài khoản"
          name="userId"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn tài khoản",
            },
          ]}
        >
          <Select
            {...userSelectProps}
            placeholder="Nhập tên hoặc email để tìm kiếm..."
          />
        </Form.Item>
        <Form.Item
          label="Sản phẩm"
          name="productId"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn sản phẩm",
            },
          ]}
        >
          <Select {...iapProductsSelectProps} placeholder="Chọn sản phẩm..." />
        </Form.Item>
      </Form>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "16px",
        }}
      >
        {isLoading && <p>Đang tạo hoá đơn...</p>}
        {receipt && (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
            }}
            gutter={16}
            wrap
          >
            <Col span={8}>
              <Image src={receipt.qrImageUrl} width={160} height={160} />
            </Col>
            <Col span={12}>
              <Statistic
                title="Ngân hàng"
                value="VietinBank"
                valueRender={(value) => <div>{value}</div>}
                valueStyle={{ fontSize: "1.2rem" }}
              />
              <Statistic
                title="Số tài khoản"
                value={receipt.paymentInfo.accNumber}
                valueRender={(value) => <div>{value}</div>}
                valueStyle={{ fontSize: "1.2rem" }}
                formatter={(value) => value}
              />
              <Statistic
                title="Nội dung chuyển khoản"
                value={receipt.receiptId}
                valueRender={(value) => <div>{value}</div>}
                valueStyle={{ fontSize: "1.2rem" }}
              />
              <Statistic
                title="Số tiền"
                value={receipt.amount}
                suffix="VND"
                valueStyle={{ fontSize: "1.2rem" }}
              />
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
}
