import React from "react";
import { Button, Descriptions, message, Space, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { IBankInfo } from "../../interfaces";

const { Text } = Typography;

type Props = {
  paymentInfo: IBankInfo;
  title?: boolean;
  border?: boolean;
};

export const PaymentInfoDesc: React.FC<Props> = ({
  paymentInfo,
  title,
  border,
}) => {
  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    message.success("Đã sao chép");
  };

  return (
    <Descriptions
      bordered={border ?? true}
      title={title != null && title && "Thông tin thanh toán"}
    >
      <Descriptions.Item label="Số tài khoản">
        <Space>
          <Text>{paymentInfo?.accountNumber ?? "__"}</Text>
          <Button
            icon={<CopyOutlined />}
            size="small"
            type="text"
            onClick={() => handleCopy(paymentInfo?.accountNumber)}
          />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label="Tên tài khoản" span={12}>
        <Space>
          <Text>{paymentInfo?.accountName ?? "__"}</Text>
          <Button
            icon={<CopyOutlined />}
            size="small"
            type="text"
            onClick={() => handleCopy(paymentInfo?.accountName)}
          />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label="Ngân hàng" span={12}>
        {paymentInfo?.bankName ?? "__"}
      </Descriptions.Item>
    </Descriptions>
  );
};
