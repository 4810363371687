import { useList, useOne } from "@refinedev/core";
import { ICategory } from "interfaces";
import { useEffect, useState } from "react";

type ITreeCategoriesSelectProps = {
  editMode: boolean;
  preventId?: string;
  initCateParentId?: string;
  initCategories?: any[];
  bookTypes?: string;
};

export const useTreeCategoriesSelect = ({
  preventId,
  initCateParentId,
  editMode,
  initCategories,
  bookTypes,
}: ITreeCategoriesSelectProps) => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [parentId, setParentId] = useState<string | null>(null);

  const { data: parentCategoryData } = useOne<ICategory>({
    resource: "category",
    id: `id/${initCateParentId}`,
    queryOptions: {
      enabled: initCateParentId != null,
    },
  });

  const { refetch } = useList({
    resource: "category/tree-select",

    queryOptions: {
      onSuccess(data) {
        let _newTree = [...treeData];

        data.data.forEach((item) => {
          const isExist = _newTree.some((_item) => _item.id === item.id);

          if (!isExist) {
            _newTree.push(item);
          }
        });

        if (parentCategoryData?.data != null) {
          const parentCate = {
            title: parentCategoryData?.data?.name,
            value: parentCategoryData?.data?._id,
            key: parentCategoryData?.data?._id,
            id: parentCategoryData?.data?._id,
            pId: parentCategoryData?.data?.parentId,
          };

          // Check duplicate
          const isExist = _newTree.some((item) => item.id === parentCate.id);

          if (!isExist) {
            _newTree.push(parentCate);
          }
        }

        setTreeData(_newTree);
      },
      enabled: preventId != null && parentCategoryData != null,
    },

    filters: [
      {
        field: "parentId",
        operator: "eq",
        value: parentId,
      },
      {
        field: "preventId",
        operator: "eq",
        value: preventId,
      },
      {
        field: "bookTypes",
        operator: "eq",
        value: bookTypes ?? "novel",
      },
    ]
  });

  const { refetch: refetch2 } = useList({
    resource: "category/tree-select",

    queryOptions: {
      onSuccess(data) {
        console.log("data", data);
        console.log(initCategories);

        let _newTree = [...treeData];

        data.data.forEach((item) => {
          const isExist = _newTree.some((_item) => _item.id === item.id);

          if (!isExist) {
            _newTree.push(item);
          }
        });

        if (initCategories != null) {
          initCategories.forEach((item) => {
            const isExist = _newTree.some((_item) => _item.id === item.id);

            if (!isExist) {
              console.log("item", item);
              _newTree.push(item);
            }
          });
        }

        setTreeData(_newTree);
      },
      enabled: !editMode && preventId == null,
    },

    filters: [
      {
        field: "parentId",
        operator: "eq",
        value: parentId,
      },
      {
        field: "bookTypes",
        operator: "eq",
        value: bookTypes ?? "novel",
      },
    ]
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch2();
  }, [initCategories]);

  const onLoadData = async (parentId: string) => {
    setParentId(parentId);
  };

  return {
    treeData,
    onLoadData,
  };
};

const updateTreeNode: any = (list: any[], key: string, children: any[]) => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeNode(node.children, key, children),
      };
    }
    return node;
  });
};

type TreeListProps = {
  bookTypes: string;
};

export const useTreeList = ({ bookTypes }: TreeListProps) => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [parentId, setParentId] = useState<string | null>(null);

  const { refetch } = useList({
    resource: "category/tree-select",

    queryOptions: {
      onSuccess(data) {
        const newTreeData = data.data.map((item: any) => {
          // item.title = createNodeTitle(item, () => handleDelete());
          return item;
        });

        setTreeData(newTreeData);
      },
    },

    filters: [
      {
        field: "bookTypes",
        operator: "eq",
        value: bookTypes,
      },
    ]
  });

  const {} = useList({
    resource: "category/tree-select",

    queryOptions: {
      onSuccess(data) {
        const _newTree = updateTreeNode(treeData, parentId, data.data);
        setTreeData(_newTree);
      },
      enabled: parentId !== null,
    },

    filters: [
      {
        field: "parentId",
        operator: "eq",
        value: parentId,
      },
      {
        field: "bookTypes",
        operator: "eq",
        value: bookTypes,
      },
    ]
  });

  const loadChild = (parentId: string) => {
    setParentId(parentId);
  };

  return {
    treeData,
    loadChild,
  };
};
