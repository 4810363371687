import { Edit, getValueFromEvent, useForm } from "@refinedev/antd";
import { Form, Switch, Upload, Typography, Select, Input, InputNumber } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { IProduct } from "interfaces";
import React from "react";

const { Text } = Typography;

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IProduct>({});

  return (
    <Edit title="Chỉnh sửa" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Hoạt động" name="active" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          label="Hình ảnh"
          name="images"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
        >
          <Upload
            name="images"
            listType="picture-card"
            action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
            maxCount={5}
            multiple
            accept=".png,.jpeg,.jpg"
          >
            <Text>Drag & drop image to upload</Text>{" "}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Danh mục"
          name="category"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Chọn danh mục"
            options={[
              {
                label: "Gói xu",
                value: "coin-pack",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mô tả"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea maxLength={400} style={{ minHeight: "160px" }} />
        </Form.Item>
        <Form.Item
          label="Giá"
          name="price"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            //  min={0}
            style={{ minWidth: "240px" }}
            prefix="đ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => {
              const s = value!.replace(/\đ\s?|(,*)/g, "");
              let number = parseInt(s);

              if (number < 0) number = 0;

              return number;
            }}
          />
        </Form.Item>
        <Form.Item label="Khuyến mãi (%)" name="discount" initialValue={0}>
          <InputNumber
            min={0}
            max={100}
            prefix="%"
            style={{ minWidth: "240px" }}
          />
        </Form.Item>
        <Form.Item label="Số lượng" name="amount" initialValue={1}>
          <InputNumber min={0} style={{ minWidth: "240px" }} />
        </Form.Item>
        <Form.Item
          label="Tặng thêm (số lượng)"
          name="extraAmount"
          initialValue={0}
        >
          <InputNumber min={0} style={{ minWidth: "240px" }} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
