import React, { FC } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { List } from "@refinedev/antd";
import CategoryTreeList from "../../components/category/CategoryTreeList";

export const CategoryList: FC<IResourceComponentsProps> = () => {
  return (
    <List title="Danh sách thể loại">
      <CategoryTreeList bookTypes="novel" />
      {/*<Tree*/}
      {/*  showLine*/}
      {/*  treeData={treeData}*/}
      {/*  loadData={async ({ key, children }: any) => {*/}
      {/*    setParentId(key);*/}
      {/*  }}*/}
      {/*/>*/}
      {/* <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="_id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === "TD") {
                setId && setId(record.slugId);
              }
            },
          })}
        >
          <Table.Column<ICategory>
            dataIndex="cover"
            title="Thumbnail"
            key="cover"
            render={(value, record) => {
              if (isEditing(record.slugId)) {
                return (
                  <Form.Item
                    name="cover"
                    valuePropName="fileList"
                    getValueFromEvent={getValueFromEvent}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Upload
                      name="images"
                      listType="picture-card"
                      action={`${process.env.REACT_APP_UPLOAD_URL}/images`}
                      maxCount={1}
                      multiple={false}
                      accept=".png,.jpeg,.jpg"
                    >
                      <Text>Drag & drop image to upload</Text>{" "}
                    </Upload>
                  </Form.Item>
                );
              }

              return (
                <ImageField
                  value={
                    value
                      ? value[0].url
                      : "https://firebasestorage.googleapis.com/v0/b/truyen3k-1eff3.appspot.com/o/placeholder-images-image_large.webp?alt=media&token=87583e6b-f0e8-4375-a108-c852b393df73"
                  }
                  style={{
                    maxWidth: "80px",
                    aspectRatio: "3/4",
                    objectFit: "cover",
                  }}
                />
              );
            }}
          />
          <Table.Column<ICategory>
            dataIndex="name"
            title="Tên"
            key="name"
            render={(value, record) => {
              if (isEditing(record.slugId)) {
                return (
                  <Form.Item name="name" style={{ margin: 0 }}>
                    <Input />
                  </Form.Item>
                );
              }

              return <TextField value={value} />;
            }}
          />
          <Table.Column<ICategory>
            dataIndex="description"
            title="Mô tả"
            key="description"
            render={(value, record) => {
              if (isEditing(record.slugId)) {
                return (
                  <Form.Item name="description" style={{ margin: 0 }}>
                    <Input.TextArea maxLength={200} />
                  </Form.Item>
                );
              }

              return <TextField value={value} />;
            }}
          />
          <Table.Column<ICategory>
            dataIndex="actions"
            title="Hành động"
            render={(value, record) => {
              if (isEditing(record.slugId)) {
                return (
                  <Space>
                    <SaveButton {...saveButtonProps} size="small" />
                    <Button {...cancelButtonProps} size="small">
                      Cancel
                    </Button>
                  </Space>
                );
              }

              return (
                <Space>
                  <EditButton
                    {...editButtonProps(record.slugId)}
                    size="small"
                    hideText
                  />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.slugId}
                  />
                </Space>
              );
            }}
          />
        </Table>
      </Form> */}
    </List>
  );
};
