import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { Avatar, Space, Table } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";

import { IAuthor } from "interfaces";

export const AuthorList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IAuthor>({
    dataProviderName: "defaultV2"
  });

  return (
    <List>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="avatar"
          title="Avatar"
          render={(value) => {
            return <Avatar src={value[0].url} size="large" />;
          }}
        />
        <Table.Column dataIndex="name" title="Tên" />
        <Table.Column dataIndex="totalBooks" title="Số truyện" />
        <Table.Column<IAuthor>
          title="Hành động"
          render={(value, record) => {
            return (
              <Space>
                <EditButton size="small" recordItemId={record._id} hideText />
                <DeleteButton
                  size="small"
                  recordItemId={record._id}
                  hideText
                  dataProviderName="defaultV2"
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
