import React, { FC } from "react";
import { CrudFilters, IResourceComponentsProps } from "@refinedev/core";
import { Breadcrumb, List, useEditableTable } from "@refinedev/antd";
import { IBook } from "../../interfaces";
import { BookTable } from "../../components/books/BookTable";
import { BookFiltering } from "../../components/books/BookFiltering";

export const BookList: FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, searchFormProps } = useEditableTable<IBook>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    initialPageSize: 5,
    syncWithLocation: true,
    permanentFilter: [
      {
        field: "type",
        operator: "eq",
        value: "novel",
      },
    ],
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q, createdAt, feature, category, hasLockChapters } = params;
      console.log(params);

      filters.push(
        {
          field: "q",
          operator: "eq",
          value: q,
        },
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt ? createdAt[0].toISOString() : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt ? createdAt[1].toISOString() : undefined,
        },
        {
          field: "feature",
          operator: "eq",
          value: feature,
        },
        {
          field: "hasLockChapters",
          operator: "eq",
          value: hasLockChapters,
        },
        {
          field: "category",
          operator: "in",
          value: category,
        }
      );

      return filters;
    },
  });

  return (
    <>
      <BookFiltering searchFormProps={searchFormProps} bookTypes="novel" />
      <List
        title="Danh sách truyện"
        headerProps={{ breadcrumb: <Breadcrumb hideIcons /> }}
      >
        <BookTable tableProps={tableProps} sorter={sorter!} />
      </List>
    </>
  );
};
