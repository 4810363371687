import React, { useCallback } from "react";
import { IResourceComponentsProps, useNavigation, useOne } from "@refinedev/core";
import { Edit, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import { Button, Form, Input, InputNumber, Select, Space, Typography, Upload } from "antd";
import { IBook, IChapter } from "../../interfaces";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableUploadListItem from "../../components/DraggableUploadListItem";

const { Text } = Typography;

export const ComicChapterEdit: React.FC<IResourceComponentsProps> = () => {
  // const [fileList, setFileList] = useState<UploadFile[]>([]);
  // const [uploadedFile, setUploadedFile, uploadedFileRef] = useStateRef<
  //   UploadFile[]
  // >([]);
  // const [imgSrc, setImageSrc, imgSrcRef] = useStateRef<string>("");
  const { formProps, queryResult, saveButtonProps } = useForm<IChapter>({
    redirect: false,
    onMutationSuccess: () => {
      goBack();
    },
  });

  // useEffect(() => {
  //   setUploadedFile([]);
  // }, []);

  const { goBack } = useNavigation();

  const bookQueryResponse = useOne<IBook>({
    resource: "book",
    id: queryResult?.data?.data.bookId!,
    queryOptions: {
      enabled: queryResult != null && queryResult.data != null,
    },
  });

  const { selectProps: bookSelectProps } = useSelect<IBook>({
    resource: "book",
    optionLabel: "name",
    optionValue: "_id",
    fetchSize: 10,

    queryOptions: {
      enabled: bookQueryResponse != null && bookQueryResponse.data != null,
      onSuccess: (data) => {
        data.data.push(bookQueryResponse.data?.data!);
      },
    },

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],

    filters: [
      {
        field: "type",
        operator: "eq",
        value: "comic",
      },
    ],

    pagination: {
      mode: "server"
    }
  });

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const fileList = formProps!.form!.getFieldValue("contents");

      const dragRow = fileList[dragIndex];
      fileList.splice(dragIndex, 1);
      fileList.splice(hoverIndex, 0, dragRow);
      formProps!.form!.setFieldsValue({ contents: fileList });
    },
    [formProps]
  );

  // const handleChange = (info: any) => {
  //   const newFileList = [...info.fileList];
  //
  //   if (info.file.status === "done") {
  //     setUploadedFile([...uploadedFileRef.current, info.file]);
  //   }
  //
  //   newFileList.forEach((file) => {
  //     const uploaded = uploadedFileRef.current.find((x) => x.uid === file.uid);
  //
  //     if (uploaded) {
  //       file.response = uploaded.response;
  //       file.url = uploaded.url;
  //       file.status = "done";
  //     }
  //   });
  //
  //   setFileList(newFileList);
  // };

  return (
    <Edit
      title="Sửa chương"
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Truyện"
          name="bookId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...bookSelectProps} disabled />
        </Form.Item>
        <Form.Item
          label="Số"
          name="number"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Giá unlock"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="Tên"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <DndProvider backend={HTML5Backend}>
          <Form.Item
            label={
              <Space
                align="baseline"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                size="large"
              >
                <p>Nội dung</p>
                <Button
                  size="small"
                  danger
                  onClick={() => {
                    // setFileList([]);
                    // setUploadedFile([]);
                    formProps.form?.setFieldsValue({ content: [] });
                  }}
                >
                  Xoá hết
                </Button>
              </Space>
            }
            name="contents"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload.Dragger
              name="images"
              listType="picture"
              action={`${process.env.REACT_APP_UPLOAD_URL}/images-chapter`}
              multiple
              accept=".png,.jpeg,.jpg,.webp"
              // fileList={fileList}
              // onChange={handleChange}
              itemRender={(originNode, file, currFileList) => (
                <DraggableUploadListItem
                  originNode={originNode}
                  file={file}
                  fileList={currFileList}
                  moveRow={moveRow}
                  onClick={(_file) => {}}
                />
              )}
            >
              <Text>Drag & drop image to upload</Text>{" "}
            </Upload.Dragger>
          </Form.Item>
        </DndProvider>
        {/*<Form.Item*/}
        {/*  label={*/}
        {/*    <Space*/}
        {/*      align="baseline"*/}
        {/*      style={{*/}
        {/*        display: "flex",*/}
        {/*        justifyContent: "space-between",*/}
        {/*        width: "100%",*/}
        {/*      }}*/}
        {/*      size="large"*/}
        {/*    >*/}
        {/*      <p>Nội dung</p>*/}
        {/*      <Button*/}
        {/*        size="small"*/}
        {/*        danger*/}
        {/*        onClick={() => {*/}
        {/*          // setFileList([]);*/}
        {/*          // setUploadedFile([]);*/}
        {/*          formProps.form?.setFieldsValue({ content: [] });*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Xoá hết*/}
        {/*      </Button>*/}
        {/*    </Space>*/}
        {/*  }*/}
        {/*  name="contents"*/}
        {/*  valuePropName="fileList"*/}
        {/*  getValueFromEvent={getValueFromEvent}*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  /!*<DndProvider backend={HTML5Backend}>*!/*/}
        {/*  /!*  <Form.Item*!/*/}
        {/*  /!*    label={*!/*/}
        {/*  /!*      <Space*!/*/}
        {/*  /!*        align="baseline"*!/*/}
        {/*  /!*        style={{*!/*/}
        {/*  /!*          display: "flex",*!/*/}
        {/*  /!*          justifyContent: "space-between",*!/*/}
        {/*  /!*          width: "100%",*!/*/}
        {/*  /!*        }}*!/*/}
        {/*  /!*        size="large"*!/*/}
        {/*  /!*      >*!/*/}
        {/*  /!*        <p>Nội dung</p>*!/*/}
        {/*  /!*        <Button*!/*/}
        {/*  /!*          size="small"*!/*/}
        {/*  /!*          danger*!/*/}
        {/*  /!*          onClick={() => {*!/*/}
        {/*  /!*            // setFileList([]);*!/*/}
        {/*  /!*            // setUploadedFile([]);*!/*/}
        {/*  /!*            formProps.form?.setFieldsValue({ content: [] });*!/*/}
        {/*  /!*          }}*!/*/}
        {/*  /!*        >*!/*/}
        {/*  /!*          Xoá hết*!/*/}
        {/*  /!*        </Button>*!/*/}
        {/*  /!*      </Space>*!/*/}
        {/*  /!*    }*!/*/}
        {/*  /!*    name="content"*!/*/}
        {/*  /!*    valuePropName="fileList"*!/*/}
        {/*  /!*    getValueFromEvent={getValueFromEvent}*!/*/}
        {/*  /!*    rules={[*!/*/}
        {/*  /!*      {*!/*/}
        {/*  /!*        required: true,*!/*/}
        {/*  /!*      },*!/*/}
        {/*  /!*    ]}*!/*/}
        {/*  /!*  >*!/*/}
        {/*  /!*    <Upload.Dragger*!/*/}
        {/*  /!*      name="images"*!/*/}
        {/*  /!*      listType="picture"*!/*/}
        {/*  /!*      action={`${process.env.REACT_APP_UPLOAD_URL}/images/chapter/${*!/*/}
        {/*  /!*        bookQueryResponse.data?.data.slugId*!/*/}
        {/*  /!*      }/${queryResult?.data?.data.number!}`}*!/*/}
        {/*  /!*      multiple*!/*/}
        {/*  /!*      accept=".png,.jpeg,.jpg,.webp"*!/*/}
        {/*  /!*      // fileList={fileList}*!/*/}
        {/*  /!*      // onChange={handleChange}*!/*/}
        {/*  /!*      // itemRender={(originNode, file, currFileList) => (*!/*/}
        {/*  /!*      //   <DraggableUploadListItem*!/*/}
        {/*  /!*      //     originNode={originNode}*!/*/}
        {/*  /!*      //     file={file}*!/*/}
        {/*  /!*      //     fileList={currFileList}*!/*/}
        {/*  /!*      //     moveRow={moveRow}*!/*/}
        {/*  /!*      //     onClick={(_file) => {}}*!/*/}
        {/*  /!*      //   />*!/*/}
        {/*  /!*      // )}*!/*/}
        {/*  /!*    >*!/*/}
        {/*  /!*      <Text>Drag & drop image to upload</Text>{" "}*!/*/}
        {/*  /!*    </Upload.Dragger>*!/*/}
        {/*  /!*  </Form.Item>*!/*/}
        {/*  /!*</DndProvider>*!/*/}

        {/*  <Upload.Dragger*/}
        {/*    name="images"*/}
        {/*    listType="picture"*/}
        {/*    action={`${process.env.REACT_APP_UPLOAD_URL}/images-chapter`}*/}
        {/*    multiple*/}
        {/*    accept=".png,.jpeg,.jpg,.webp"*/}
        {/*    // fileList={fileList}*/}
        {/*    // onChange={handleChange}*/}
        {/*    // itemRender={(originNode, file, currFileList) => (*/}
        {/*    //   <DraggableUploadListItem*/}
        {/*    //     originNode={originNode}*/}
        {/*    //     file={file}*/}
        {/*    //     fileList={currFileList}*/}
        {/*    //     moveRow={moveRow}*/}
        {/*    //     onClick={(_file) => {}}*/}
        {/*    //   />*/}
        {/*    // )}*/}
        {/*  >*/}
        {/*    <Text>Drag & drop image to upload</Text>{" "}*/}
        {/*  </Upload.Dragger>*/}
        {/*</Form.Item>*/}
      </Form>
    </Edit>
  );
};
