import { DeleteButton, EditButton, ImageField, List, useTable } from "@refinedev/antd";
import { Checkbox, Space, Table } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { ISticker } from "interfaces";
import React from "react";

export const StickersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ISticker>();

  return (
    <List>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          title=""
          dataIndex="thumbnail"
          key="thumbnail"
          render={(value) => {
            return <ImageField value={value.url} width="80px" />;
          }}
        />
        <Table.Column title="Tên" dataIndex="name" key="name" />
        <Table.Column title="Giá (Xu)" dataIndex="price" key="price" />
        <Table.Column
          title="Vip"
          dataIndex="vipOnly"
          key="vipOnly"
          render={(value) => {
            return <Checkbox value={value} disabled />;
          }}
        />
        <Table.Column<ISticker>
          title="Hành động"
          key="actions"
          dataIndex="actions"
          render={(value, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record._id} />
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
