import {
  CreateButton,
  DateField,
  DeleteButton,
  List,
  useModalForm,
  useTable,
} from "@refinedev/antd";
import { useDelete } from "@refinedev/core";

import {
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
} from "antd";

export default function LuckyWheelUserCloneList() {
  const { modalProps, formProps, show } = useModalForm({
    action: "create",
    resource: "wheel-user-clones/many",
    dataProviderName: "defaultV2",
    redirect: false,
    onMutationSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  const { tableProps, tableQueryResult } = useTable({
    dataProviderName: "defaultV2",
  });

  return (
    <>
      <List
        headerButtons={[
          <CreateButton onClick={() => show()}>Thêm Clone</CreateButton>,
          <DeleteButton
            recordItemId="all"
            resource="wheel-user-clones"
            dataProviderName="defaultV2"
          >
            Xoá hết
          </DeleteButton>,
        ]}
      >
        <Table {...tableProps} rowKey="_id">
          <Table.Column dataIndex={["cloneInfo", "displayName"]} title="Tên" />
          <Table.Column
            dataIndex="createdAt"
            title="Ngày tạo"
            render={(value) => {
              return <DateField value={value} format="DD/MM/YYYY" />;
            }}
          />
          <Table.Column
            dataIndex="money"
            title="Tiền"
            render={(value) => {
              const money = value.toLocaleString("vi-VN", {});
              return <span style={{ color: "green" }}>{money} đ</span>;
            }}
          />
          <Table.Column
            dataIndex="actions"
            title="Hành động"
            render={(value, record: any) => {
              return (
                <Space>
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record._id}
                    dataProviderName="defaultV2"
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
      <Modal {...modalProps} title="Thêm" centered width="480px">
        <Form {...formProps} layout="vertical">
          <Form.Item name="total" label="Số lượng">
            <InputNumber
              style={{
                width: "100%",
              }}
              min={1}
              max={20}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
