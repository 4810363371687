import { IResourceComponentsProps } from "@refinedev/core";
import React, { FC } from "react";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { IAccount } from "../../interfaces";
import _ from "underscore";
import { accountRoleOptions } from "../../constants";

export const AccountCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IAccount>();

  return (
    <Create title="Tạo tài khoản quản trị" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Tên hiển thị"
          name="fullName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="name" />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Nhập lại mật khẩu"
          name="re-password"
          rules={[
            {
              required: true,
              validator: (rule, value, callback) => {
                const password = formProps.form?.getFieldValue("password");

                if (_.isEmpty(password) || value !== password) {
                  return Promise.reject("Nhập lại mật khẩu không đúng");
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Chức vụ"
          name="role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={accountRoleOptions} placeholder="Chọn chức vụ" />
        </Form.Item>
      </Form>
    </Create>
  );
};
